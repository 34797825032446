import Vue from 'vue';
import store from '@/store/store';
import Comments from '@/app/comments/comments';

export default new Vue({
    el        : '#comments',
    components: {Comments},
    store,
    template  : '<comments/>'
});
