export function initialGoalsState() {
    return {
        goals       : {
            goals: null
        },
        goalsArr    : null,
        selectedGoal: null,
        filter      : null,
        managerGoals: null
    };
}
