import SPINNER from '../spinner.vue';

export const Spinner = {
    show() {
        SPINNER.$children[0].start();
    },
    hide() {
        SPINNER.$children[0].stop();
    }
};
