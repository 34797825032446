import bannerActions from './banner-actions';
import bannerMutations from './banner-mutations';
import bannerGetters from './banner-getters';

export default {
    state    : {
        bannerText: '',
    },
    mutations: bannerMutations,
    actions  : bannerActions,
    getters  : bannerGetters
};
