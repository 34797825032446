import { UserDetails } from '@/common/user-details';
import { highlightVariables, replaceVariableWithName } from '@/common/utils/question-variable';
import Vue from 'vue';

Vue.prototype.isNull                  = value => value === null;
Vue.prototype.isUndefined             = value => typeof value === 'undefined';
Vue.prototype.isNullOrUndefined       = value => value === null || typeof value === 'undefined';
Vue.prototype.hasAnyRole              = roles => UserDetails.roles.some(role => roles.indexOf(role) !== -1);
Vue.prototype.cloneObj                = obj => JSON.parse(JSON.stringify(obj));
Vue.prototype.parseQuestion           = component => component.question = JSON.parse(component.question);
Vue.prototype.stringifyQuestion       = component => component.question = JSON.stringify(component.question);
Vue.prototype.replaceVariableWithName = replaceVariableWithName;
Vue.prototype.highlightVariables      = highlightVariables;