export const uiSort = {
    methods: {
        uiSort(arr, {sortColumn, sortDirection}) {
            if (arr.length) {
                if (typeof arr[0][sortColumn] === 'number') {
                    arr.sort((a, b) => a[sortColumn] - b[sortColumn]);
                } else {
                    if (sortColumn === 'fullName') {
                        arr.sort((a, b) => a.fullName.localeCompare(b.fullName));
                    } else {
                        arr.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]));
                    }
                }
                sortDirection === 'DESC' && arr.reverse();
            }
        }
    }
};