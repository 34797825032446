export const Tooltip = {
    bind: (el, binding) => {
        const TOOLTIP = document.createElement('div');
        TOOLTIP.classList.add('tooltip');
        el.tooltip = binding.value;
        el.over    = e => {
            if (el.tooltip) {
                const {width, left, top, height} = el.getBoundingClientRect();
                TOOLTIP.innerText                = el.tooltip;
                document.body.appendChild(TOOLTIP);
                const [tooltipWidth, tooltipHeight] = [TOOLTIP.clientWidth, TOOLTIP.clientHeight];
                let [offsetLeft, offsetTop]         = [e.x - tooltipWidth / 2, top - tooltipHeight - 8];
                if (Object.keys(binding.modifiers).length) {
                    if ('below' in binding.modifiers) offsetTop = top + tooltipHeight + 8; else if ('after' in binding.modifiers) [offsetLeft, offsetTop] = [left + width + 8, top + (height - tooltipHeight) / 2]; else if ('before' in binding.modifiers) [offsetLeft, offsetTop] = [left - tooltipWidth - 8, top + (height - tooltipHeight) / 2];
                }
                TOOLTIP.style.cssText = `top:${offsetTop}px;left:${offsetLeft}px;`;
            }
        };
        el.out     = () => TOOLTIP.remove();
        el.addEventListener('mouseover', el.over);
        el.addEventListener('mouseout', el.out);
    },
    componentUpdated: (el, binding) => el.tooltip = binding.value,
    unbind: el => {
        el.removeEventListener('mouseover', el.over);
        el.removeEventListener('mouseout', el.out);
        const tooltips = document.body.querySelectorAll('.tooltip');
        Array.from(tooltips).forEach(t => t.remove());
    }
};
