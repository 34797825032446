import sidebarMutations from './sidebar-mutations';
import sidebarGetters from './sidebar-getters';

export default {
    state    : {
        sidebarOpen: ''
    },
    mutations: sidebarMutations,
    getters  : sidebarGetters
};
