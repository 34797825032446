import { AuthGuard } from '@/common/auth/auth-guard';
import '@/common/directives';
import '@/common/filters';
import '@/common/mixins';
import '@/common/reusable-components';
import '@/common/utils/global-utils';
import '@/common/utils/validation-errors';
import '@/common/comments.vue';
import store from '@/store/store';
import VeeValidate from 'vee-validate';

import Vue from 'vue';
import 'vue-tour/dist/vue-tour.css';
import VueTour from 'vue-tour';
import App from './App';
import router from './router';

Vue.use(VueTour);
Vue.use(VeeValidate);
Vue.config.productionTip = false;

router.beforeEach(AuthGuard);

export const OPERF = new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
