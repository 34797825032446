import peersGetters from './peers-getters';
import peersMutations from './peers-mutations';
import peersActions from './peers-actions';
import peersState from './peers-state';

export default {
    state    : peersState,
    getters  : peersGetters,
    mutations: peersMutations,
    actions  : peersActions
};
