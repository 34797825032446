<template>
    <o-modal close-method="closeModal" max-width="600px">
        <div slot="header" class="modal-header">
            <div>Add peers</div>
            <o-button
                secondary
                @click="closeModal();openInviteExternalPeersModal()"
            >
                INVITE PEERS
            </o-button>
            <o-modal close-method="closeModal" ref="externalPeersModal">
                <div slot="header" class="modal-header">
                    <div>Invite peers</div>
                </div>
                <div slot="body" class="invite-peer-body">
                    <o-textarea
                        :name="`@Invitation field`"
                        :placeholder="placeholderInvite"
                        v-validate="{ max: 500, required: true, isStringOfEmails: true }"
                        :errors-list="errors"
                        v-model="externalPeersEmails"
                    ></o-textarea>
                </div>
                <div slot="footer">
                    <o-button
                        secondary
                        v-if="existingExternalPeersEmails.length"
                        @click="externalPeersEmails = externalPeersEmailsList.join(',')"
                        class = "remove-btn"
                    >
                        REMOVE EXISTING
                    </o-button>
                    <o-button
                        secondary
                        @click="closeModal();resetValidation('Invitation field')"
                    >
                        CANCEL
                    </o-button>
                    <o-button
                        primary
                        @click="processInvitePeers"
                        :disabled="errors.any() || !externalPeersEmailsList.length"
                    >
                        INVITE
                    </o-button>
                    <o-confirm-modal
                        @onClose="$event && sendInvitation()"
                        :button-name="'INVITE'"
                    >
                        <div>
                            The following reviewers are from outside your organisation:<br/>
                            <ul >
                                <li v-for="(email, index) in externalPeersEmailsList" :key="index">{{ email }}</li>
                            </ul>
                            <template v-if="existingExternalPeersEmails.length">
                                The following reviewers are already invited:<br/>
                                <ul >
                                    <li v-for="(email, index) in existingExternalPeersEmails" :key="index">{{ email }}</li>
                                </ul>
                            </template>
                            <br/>Are you sure you would like to invite them?
                        </div>
                    </o-confirm-modal>
                </div>
            </o-modal>
        </div>
        <div slot="body" class="potential-member-wrapper" @scroll="getMorePeers($event)">
            <o-search-input
                v-model.trim="filter.q"
                :debounce="200"
                margins="16px 0"
                :placeholder="placeholder"
                @change="filter.page = 0; getPeers()"
                :placeholder-style="true"
				:style="{overflow: 'unset'}"
            />
            <div v-if="peersModal.peers.length">
                <div class="peers-number text-14">
                    {{ peersModal.peers.length > 0 ? peersModal.peers.length: 'No' }}
                    peer<span v-if="peersModal.peers.length > 1">s</span>
                </div>
                <div
                    v-if="peersModal.peerSelected.length"
                    class="peers-number-selected text-12"
                >
                    {{ peersModal.peerSelected.length }}
                    peer<span v-if="peersModal.peerSelected.length > 1">s </span>selected
                </div>
                <div v-else class="peers-number-selected text-12">
                    Not selected yet!
                </div>
                <div v-for="peer in peersModal.peers" :key="peer.userType + peer.userId">
                    <div class="modal-item">
                        <div>
                            <o-user-image :user="peer"/>
                            <div>
                                <div>{{ peer.fullName }}</div>
                                <div>
                                    <div class="text-12">
                                        {{ getPeerBelongingTo(peer) }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <o-button icon :id="peer.userId" @click="selectPeers(peer)"
                                      :disabled="isSelectBlocked(peer)"
                                      :class="isSelectBlocked(peer) ? 'disabled' : ''"
                            >
                                <o-icon
                                    :class="peer.peerRequestStatus === PEER_STATUSES.approved ? 'approved': ''"
                                    :name="peer.peerRequestStatus === PEER_STATUSES.notSelected ? 'add' : 'done'"
                                />
                            </o-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer">
            <o-button
                secondary
                @click="closeModal();resetModalState();"
            >
                CLOSE
            </o-button>
            <o-button
                primary
                @click="sendRequests()"
            >
                SAVE
            </o-button>
        </div>
    </o-modal>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { UserDetails } from '@/common/user-details';
    import { Notify } from '@/common/services/notification';
    import { PEER_STATUSES } from '@/common/models/user/review-process/peer-statuses';
    import { PageFilter } from '../models/page-filter';

    export default {
        name: 'o-add-peers-modal',
        data() {
            return {
                placeholder        : 'Find people in your work space',
                placeholderInvite  : 'Invite peers via email separated by comma',
                userDetails        : UserDetails,
                filter             : new PageFilter(),
                externalPeersEmails: '',
                name               : false,
                filteredEmployees  : [],
                peerRequestStatus  : '',
                alreadyInvited     : [],
                isManager          : false,
                PEER_STATUSES      : PEER_STATUSES,
                approved           : [],
            };
        },

        created() {
            this.isManager = !!localStorage.getItem('selectedUserId');
        },

        mounted() {
            this.$root.$on('resetModalState', () => {
                this.resetModalState();
            });
            this.$once('hook:beforeDestroy', () => this.$root.$off('resetModalState'));
        },

        computed: {
            ...mapGetters([
                'subordinatesForApproval',
                'peersModal',
                'totalPeersLeft'
            ]),
            isSelectBlocked() {
                return peer => !this.isManager && peer.peerRequestStatus === PEER_STATUSES.approved;
            },
            managerId: vm => vm.isManager ? vm.userDetails.id : vm.userDetails.manager.id,
            externalPeersEmailsList: vm => vm.uniqueExternalPeersEmails().filter(email => vm.subordinatesForApproval.content.findIndex(s => s.fullName === email) === -1),
            existingExternalPeersEmails: vm => vm.uniqueExternalPeersEmails().filter(email => vm.subordinatesForApproval.content.findIndex(s => s.fullName === email) !== -1)
        },

        methods: {
            ...mapMutations([
                'addSelectedPeer',
                'removeSelectedPeer',
                'dropDataForAddPeersModal',
            ]),
            selectPeers(peer) {
                const requestStatus = peer.peerRequestStatus,
                      isInvited     = requestStatus === PEER_STATUSES.invited || requestStatus === PEER_STATUSES.approved,
                      approvedPeer  = requestStatus === PEER_STATUSES.approved,
                      isSelected    = isInvited || requestStatus === PEER_STATUSES.selected;
                if (approvedPeer) {
                    this.approved.push(peer);
                }
                const wasApproved = this.approved.some(approved => approved.userId === peer.userId);
                if (!isSelected) {
                    peer.peerRequestStatus = wasApproved ? PEER_STATUSES.approved : PEER_STATUSES.selected;
                    this.addSelectedPeer(peer);
                    return;
                }
                if (isInvited) {
                    this.alreadyInvited.push(peer);
                }
                const peersIndexInSelectedArray = this.peersModal.peerSelected.indexOf(peer);
                this.removeSelectedPeer(peersIndexInSelectedArray);
                peer.peerRequestStatus = PEER_STATUSES.notSelected;
            },
            disapproveRequests(requestedEmployee) {
                const payload = {
                    processId    : this.$route.params.id,
                    employeeId   : this.getEmployeeId(),
                    peerRequestId: requestedEmployee.peerRequestId,
                    userType     : requestedEmployee.userType
                };
                this.$store.dispatch('disapprovePeerRequests', payload).then(() => Notify.warning('Request disapproved!'));
            },
            openInviteExternalPeersModal() {
                setTimeout(() => this.$refs.externalPeersModal.$el.click(), 2000);
                this.resetValidation('Invitation field');
            },
            sendRequests() {
                let peersIds = this.peersModal.peerSelected
                    .filter(peer => peer.peerRequestId == null)
                    .map(peer => peer.userId);
                if (this.alreadyInvited.length) {
                    this.alreadyInvited.forEach(peerInv => {
                        if (peerInv.peerRequestStatus === PEER_STATUSES.notSelected) {
                            this.disapproveRequests(peerInv);
                        }
                    });
                    this.alreadyInvited = [];
                }
                const payload = {
                    processId : this.$route.params.id,
                    peersIds,
                    employeeId: this.getEmployeeId()
                };
                this.$store.dispatch('sendPeerRequests', payload);
                this.closeModal();
                this.resetModalState();
            },
            resetValidation(field) {
                this.$validator.reset(field);
                this.externalPeersEmails = '';
                this.resetModalState();
            },
            resetModalState() {
                this.filter.q = '';
                this.approved = [];
                this.dropDataForAddPeersModal();
            },
            getPeerBelongingTo(peer) {
                return peer.userType === 'EXTERNAL' ? 'invited' : peer.teamName;
            },
            getPeers(append = false) {
                this.filter.size = append ? 50 : this.filter.size;
                const payload    = {
                    processId : this.$route.params.id,
                    employeeId: this.getEmployeeId(),
                    filter    : this.filter,
                    managerId : this.managerId,
                    append
                };
                this.$store.dispatch('getPeersByUser', payload);
            },
            sendInvitation() {
                const emails = this.uniqueExternalPeersEmails()
                    .filter(externalEmail => this.subordinatesForApproval.content.findIndex(s => s.fullName === externalEmail) === -1);
                const payload = {
                    processId : this.$route.params.id,
                    employeeId: this.getEmployeeId(),
                    emails
                };
                this.$store.dispatch('inviteExternalPeers', payload).then(() => this.$emit('openModal'));
                this.resetModalState();
            },
            getEmployeeId() {
                return this.isManager ? localStorage.getItem('selectedUserId') : this.userDetails.id;
            },
            getMorePeers(event) {
                this.bottom = event.target.scrollTop === event.target.scrollHeight - event.target.offsetHeight;
                if (this.bottom && this.totalPeersLeft !== 0) {
                    this.filter.page++;
                    this.getPeers(true);
                }
            },
            processInvitePeers() {

                this.closeModal();
            },
            uniqueExternalPeersEmails() {
                return [...new Set(this.externalPeersEmails.split(',').map(e => e.trim()))];
            }
        }
    };
</script>

<style lang="scss" scoped>
    .modal-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $GRAY_D8;
        padding: 8px;

        > :first-child {
            display: flex;
            align-items: center;

            > :first-child {
                margin-right: 8px;
            }
        }
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
    }

    .o-input-wrapper > .before {
        position: relative !important;
        right: 230px;
    }

    .peers-number {
        font-weight: bold;
        color: $GRAY_4A;
    }

    .peers-number-selected {
        color: $GRAY_8B;
    }

    .potential-member-wrapper {
        max-height: 360px;
        overflow: auto;
    }

    .invite-peer-body {
        width: 400px;
    }

    .approved {
        color: $SUCCESS_ICON;
    }

    .disabled {
        opacity: 0.5;
    }

    .remove-btn {
        margin-right: 10px;
    }
</style>
