import { HTTP } from '@/common/utils/http';

export default {
    async getPlans({commit}) {
        const response = await HTTP.get(`/payments/plans`);
        commit('setPlansObject', response.data);
    },
    async changePlan({commit, dispatch}, data) {
        await HTTP.put(`/payments/existing-subscription`, data);
        commit('resetPlanToChangeData');
        dispatch('updateBillingData');
    },
    async getSubscriptionData({commit}) {
        const response = await HTTP.get(`/payments`);
        commit('setSubscriptionData', response.data);
    },
    updateBillingData({commit, dispatch}) {
        commit('initSubscriptionStatus');
        dispatch('getPlans');
        dispatch('getCreditCards');
        dispatch('getSubscriptionData');
        dispatch('getBillingHistory');
    },
    async cancelSubscription({commit, dispatch}) {
        await HTTP.put(`/payments`);
        commit('initSubscriptionStatus');
        dispatch('getSubscriptionData');
        dispatch('getPlans')
    },
    async buySubscription({dispatch}, data) {
        await HTTP.post(`/payments`, data);
        dispatch('updateBillingData');
    },
    async getBillingHistory({commit}, currentPage = 0) {
        const response = await HTTP.get(`/billing?page=${currentPage}`);
        commit('setInvoicesData', response.data);
    },
    async getCreditCards({commit}) {
        const response = await HTTP.get(`/users/cards`);
        commit('setCreditCards', response.data);
    },
    async changeDefaultCard({dispatch}, cardId) {
        await HTTP.put(`/users/cards?cardId=${cardId}&isDefault=true`);
        dispatch('getCreditCards');
    },
    async addPaymentCardData({commit}, cardData) {
        await HTTP.post(`/users/cards`, cardData);
        commit('resetCreditCardData');
    },
    async deletePaymentCard({dispatch}, cardId) {
        await HTTP.delete(`/users/cards?cardId=${cardId}`);
        dispatch('getCreditCards');
    }
};
