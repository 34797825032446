import { ClickOutside } from '@/common/directives/click-outside';
import { OnRendered } from '@/common/directives/on-rendered';
import { Ripple } from '@/common/directives/ripple';
import { TextOverflow } from '@/common/directives/text-overflow';
import { Tooltip } from '@/common/directives/tooltip';
import Vue from 'vue';

Vue.directive('click-outside', ClickOutside);
Vue.directive('ripple', Ripple);
Vue.directive('tooltip', Tooltip);
Vue.directive('text-overflow', TextOverflow);
Vue.directive('on-rendered', OnRendered);
