<template>
    <transition name="animate">
        <div>
            <div :class="['backdrop', {show: isOpen}]" @click.self="onClose" @wheel.self.prevent></div>
            <div :style="pos" :class="['o-menu', {open: isOpen, [menuClass]: menuClass}]" @mousedown.prevent.stop ref="dropdown">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>
<script>export default {
    name   : 'o-menu',
    props  : {
        trigger   : {default: null},
        width     : {default: null},
        menuClass : {default: ''},
        noOverflow: {default: false}
    },
    methods: {
        onClose() {
            this.isOpen = false;
            this.$emit('onClose', false);
        }
    },
    data() {
        return {
            isOpen      : false,
            pos         : {
                left    : '',
                top     : '',
                maxWidth: this.width || '',
                overflow: this.noOverflow ? 'hidden' : ''
            },
            menuItems   : [],
            openListener: e => {
                const {left, bottom, top, right} = e.target.getBoundingClientRect(),
                      {innerHeight, innerWidth}  = window,
                      maxScreenWidth             = innerWidth - 24,
                      {width, height}            = this.$el.querySelector('.o-menu').getBoundingClientRect();
                let maxScreenHeight              = innerHeight;
                if (maxScreenHeight > maxScreenWidth) {
                    maxScreenHeight = innerHeight + 80;
                }
                this.pos.top  = (bottom + height >= maxScreenHeight ? top - height : bottom) + 'px';
                this.pos.left = (left + width >= maxScreenWidth ? right - width : left) + 'px';
                !this.isOpen && (this.isOpen = true);
            },
            handleScroll: e => this.$refs.dropdown.scrollTop += e.deltaY
        };
    },
    mounted() {
        this.$slots.default && (this.menuItems = this.$slots.default.map(vnode => vnode.elm)
            .filter(el => el.attributes && el.attributes['menu-item'] !== undefined));
        this.menuItems.length && this.menuItems.forEach(el => el.addEventListener('click', this.onClose));
        const target = this.$el.previousElementSibling;
        target && target.addEventListener('click', this.openListener);
        window.addEventListener('resize', this.onClose);
        document.body.appendChild(this.$el);
    },
    beforeDestroy() {
        const target = this.$el.previousElementSibling;
        target && target.removeEventListener('click', this.openListener);
        window.removeEventListener('resize', this.onClose);
        this.menuItems.length && this.menuItems.forEach(el => el.removeEventListener('click', this.onClose));
        this.$el.remove();
    }
};</script>
<style lang="scss" scoped>.o-menu {
    overflow-y: auto;
    position: fixed;
    background-color: $WHITE;
    z-index: 13;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border-radius: 2px;
    min-height: 36px;
    min-width: 112px;
    max-width: 280px;
    max-height: calc(100vh - 70px);
    outline: 0;
    transform: translate3d(0, 40px, 0);
    opacity: 0;
    pointer-events: none;
    transition: transform .25s, opacity .25s;
    will-change: transform, opacity;

    &.open {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        pointer-events: all;
    }
}</style>
