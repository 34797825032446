import { TeamsService } from '@/common/services/teams';

export default {
    async checkTeamName({commit}, {teamName, teamId}) {
        const response = await TeamsService.isTeamNameUnique(teamName, teamId);
        commit('setIsNameUnique', response);
    },
    async updateTeam({commit}, {teamId, payload}) {
        await TeamsService.updateTeam(teamId, payload);
    },
    async getTeam({commit}, teamId) {
        const response = await TeamsService.getTeam(teamId);
        commit('setTeam', response.data);
        commit('setTitle', response.data.name, {root: true});
    },
    async addTeam({commit}, team) {
        await TeamsService.addTeam(team);
    },
    async deleteTeam({dispatch}, {id, filterEmployee}) {
        await TeamsService.deleteTeam(id);
        dispatch('getUsersToPage', {filter: filterEmployee}, {root: true});
    },
    async getTeams({commit}, filter) {
        const response = await TeamsService.getTeams(filter);
        commit('setTeamsPageData', response.data);
    }
};
