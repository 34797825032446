import tourGetters from './tour-getters.js';
import tourMutations from './tour-mutations.js';

export default {
    state    : {
        isStartTeamTour    : false,
        isStartAddTeamsTour: false,
        isStartAddAdminTour: false,
        plansInfo          : null
    },
    getters  : tourGetters,
    mutations: tourMutations
};
