export const questionVariable = {
    $employee: '$employee',
    $manager : '$manager'
};

export const questionVariableRegex = new RegExp(Object.keys(questionVariable).map(str => '\\' + str).join('|'), 'g');

export const highlightVariables = str => str.replace(questionVariableRegex, '<mark>$&</mark>');

export const replaceVariableWithName = (str, employee, manager) => str.replace(questionVariableRegex, match => {
    let result = match;
    switch (match) {
        case questionVariable.$employee:
            employee && (result = employee.fullName);
            break;
        case questionVariable.$manager:
            manager && (result = manager.fullName);
            break;
    }
    return result;
});