<template>
    <div class="o-stepper">
        <div class="o-stepper__header">
            <template v-for="(label, i) in labels">
                <button v-ripple="true" :disabled="isStepDisabled(label, i)" :class="['o-stepper__header__label', isStepActive(i)]"
                        @click="onLabelClick(i)" :key="label"><span class="o-stepper__header__label__number">{{i + 1}}</span><span
                    class="o-stepper__header__label__title">{{ label }}</span></button>
                <div class="o-stepper__header__line" v-if="i !== labels.length - 1" :key="i"></div>
            </template>
        </div>
        <div class="o-stepper__wrapper" ref="container" :id="'container' + _uid">
            <div class="o-stepper__wrapper__body" ref="steps" :style="bodyTranslate">
                <div v-for="(label, i) in labels" :class="localStepIndex === i ? 'visible' : 'hidden'" :key="label" :style="stepStyle">
                    <slot :name="label"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script> export default {
    name    : 'o-stepper',
    props   : {
        labels           : {
            type: Array,
            default() {return [];}
        },
        disables         : {
            type: Array,
            default() {return [];}
        },
        selectedStepIndex: {
            type   : Number,
            default: 0
        }
    },
    mounted() {
        this.getContainerWidth();
        window.addEventListener('resize', this.getContainerWidth);
    },
    model   : {
        prop : 'selectedStepIndex',
        event: 'stepIndexChange'
    },
    watch   : {
        selectedStepIndex(val) {
            this.localStepIndex = val;
            this.$nextTick(() => this.getContainerWidth());
            this.$refs.steps.classList.add('animated');
            setTimeout(() => this.$refs.steps.classList.remove('animated'), 500);
        }
    },
    data() {
        return {
            localStepIndex: this.selectedStepIndex,
            containerWidth: 0
        };
    },
    methods : {
        getContainerWidth() {this.containerWidth = this.$refs.container.getBoundingClientRect().width;},
        onLabelClick(i) {
            this.localStepIndex = i;
            this.$emit('stepIndexChange', i);
        },
        isStepDisabled(label, i) {return !!this.$slots[label][0].data.attrs.disabled && i > this.localStepIndex;},
        isStepActive(i) {return {active: this.localStepIndex === i};}
    },
    computed: {
        bodyTranslate: vm => ({
            width    : vm.containerWidth * vm.labels.length + 'px',
            transform: `translateX(-${vm.containerWidth * vm.localStepIndex + 'px'})`
        }),
        stepStyle    : vm => ({maxWidth: vm.containerWidth + 'px'})
    },
    beforeDestroy() {window.removeEventListener('resize', this.getContainerWidth);}
};</script>
<style lang="scss" scoped> .o-stepper {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    &__header {
        position: relative;
        height: 72px;
        min-height: 72px;
        max-height: 72px;
        display: flex;

        &__label {
            min-width: 100px;
            padding: 0 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: none;
            background: transparent;
            position: relative;
            font-weight: 400;
            font-size: 14px;
            color: $BLACK_87;
            transition: font-weight linear .1s;
            will-change: font-weight;
            transform: translate3d(0, 0, 0);

            &__number {
                pointer-events: none;
                font-size: 16px;
                background-color: $BLACK_38;
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $WHITE;
                font-family: Roboto, Helvetica, sans-serif;
                margin-right: 8px;
                transition: background-color linear .1s;
            }

            &__title {
                pointer-events: none;
            }

            &.active {
                font-weight: bold;
            }

            &.active &__number {
                background-color: $PRIMARY;
            }

            &:hover {
                cursor: pointer;
                background-color: $HOVER;
            }

            &, &:active {
                outline: none;
            }
        }

        &__line {
            min-width: 10px;
            border-top: 1px solid $BLACK_15;
            flex: auto;
            height: 0;
            margin: 0 -16px;
            align-self: center;
        }
    }

    &__wrapper {
        display: flex;
        overflow: visible;
        margin: 16px;

        &__body {
            white-space: nowrap;
            width: 100%;
            max-width: 100%;
            display: initial;

            &.animated {
                transition: transform .5s cubic-bezier(.35, 0, .25, 1);
            }

            & > * {
                vertical-align: top;
                display: inline-block;
                width: 100%;
                overflow-x: hidden;
                opacity: 0;
                pointer-events: none;
                transition: opacity .5s cubic-bezier(.35, 0, .25, 1);
            }

            & > .visible {
                opacity: 1;
                pointer-events: all;
            }

            & > .hidden {
                height: 1 e-9px;

                > * {
                    display: none;
                }
            }
        }
    }
}</style>
