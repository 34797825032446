<template>
    <div :class="['o-accordion', {open: state, 'auto-height': autoHeight && state}]">
        <div class="o-accordion__header" @click.prevent="toggle" ref="header">
            <div class="o-accordion__header__title">
                <slot name="title"></slot>
            </div>
            <div class="o-accordion__header__description">
                <slot name="description"></slot>
            </div>
            <div class="o-accordion__header__arrow"></div>
        </div>
        <div class="o-accordion__body" ref="body">
            <slot></slot>
        </div>
    </div>
</template>
<script> export default {
    name : 'o-accordion',
    data() {
        return {
            state    : false,
            maxHeight: this.heightTitle,
            toggle   : () => {
                this.state = !this.state;
                this.state ? setTimeout(this.update) : this.update();
            },
            update   : () => {
                this.maxHeight = this.state ? this.$refs.body.clientHeight + this.$refs.header.clientHeight + 'px' : this.heightTitle;
            }
        };
    },
    props: {
        heightTitle      : {
            type   : String,
            default: '48px'
        },
        autoHeight: {
            type: Boolean,
            default: false,
        }
    },
    updated() {
        this.$emit('updated', () => setTimeout(this.update));
    },
    mounted() {
        this.$root.$on('collapse', () => {
            this.state = false;
            this.update();
        });
        this.$emit('mounted', () => setTimeout(this.update));
        this.$once('hook:beforeDestroy', () => this.$root.$off('collapse'));
    }
};
</script>
<style lang="scss" scoped>
    .o-accordion {
        box-shadow: 0 0 0 1px $GRAY_F0;
        width: 100%;
		height: auto;
        min-height: 48px;
        overflow: hidden;
        background-color: $WHITE;
        margin: 8px 0;
        transition: margin 225ms cubic-bezier(.4, 0, .2, 1);
        will-change: max-height, margin;
        transform: translate3d(0, 0, 0);

        &.auto-height {
            max-height: unset !important;
        }

        &:not(.open) > .o-accordion__body {
			opacity: 0;
            display: none;
        }

        &.open {
            margin: 16px 0;
        }

        &.open &__header {
            min-height: 48px;

            &__arrow {
                transform: rotate(180deg);
                margin-top: 4px;
            }
        }

        &__header {
            padding: 10px 15px;
            min-height: 48px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            will-change: height;

            &:hover {
                cursor: pointer;
            }

            &__title {
                flex: 1;

				&>div{
					display: flex;
				}
            }

            > *:not(:last-child) {
                margin-right: 16px;
                overflow: hidden;

                * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &__arrow {
                transform: rotate(0);
                transition: transform .3s cubic-bezier(.35, 0, .25, 1), margin-top .3s cubic-bezier(.35, 0, .25, 1);
                will-change: transform, margin-top;
                display: flex;

                &::after {
                    border-style: solid;
                    border-width: 0 2px 2px 0;
                    content: '';
                    padding: 3px;
                    transform: rotate(45deg);
                    vertical-align: middle;
                    color: $BLACK_54;
                }
            }
        }

        &__body {
            background-color: $WHITE;
            padding: 0 24px 16px;
            overflow: hidden;
			display: block;
        }
    }
</style>
