import { Notify } from '@/common/services/notification';
import { UserDetails } from '@/common/user-details';
import { EVENT_BUS } from '@/common/utils/event-bus';
import { prependSubdomainToEnvVarWS } from '@/common/utils/helpers';
import { HTTP } from '@/common/utils/http';
import store from '@/store/store';
import { Stomp } from '@stomp/stompjs';
import { Subdomain } from '../auth/subdomain';
import { CLEAR_STORAGE_DATA } from '@/common/models/local-storage-data-for-clear';

const commit = store.commit;
const socketUrl = prependSubdomainToEnvVarWS('VUE_APP_SOCKET_URL');
const client = Stomp.client(socketUrl);

export const LobbyService = {
    ws: null,

    isCompanyNameExist(companyName) {
        return HTTP.get(`/public/companies/${companyName}/exists`).then(response => response.data);
    },

    isSubdomainExist(subdomain) {
        return HTTP.get(`/public/companies/subdomain/${subdomain}/exists`).then(response => response.data);
    },

    signIn(userCredentials) {
        UserDetails.resetUser();
        const headers = {
            'subdomain': Subdomain.getSubdomain()
        };
        return HTTP.post(`/public/token`, userCredentials, {headers})
            .then(() => {
                this.fetchUserDetails();
                commit('initSubscriptionStatus');
            });
    },

    initSocketConnection() {
        client.connect(
            {},
            () => {
                client.subscribe('/user/topic/notifications', (data) => {
                    const jsonData = JSON.parse(data.body);
                    if (jsonData.notificationType === 'BANNER') {
                        store.commit('openBannerWithInfo', jsonData.body);
                    } else {
                        Notify.ok(jsonData.body);
                        Notify.add(jsonData);
                    }
                });
            }
        );
    },

    deleteNotifications(notificationIds) {
        return HTTP.delete('/notifications', {data: {notificationIds}})
            .then(() => Notify.removeByIds(notificationIds));
    },

    toggleNotifications(isNotificationsEnabled) {
        return HTTP.put('/notifications/enabled/' + isNotificationsEnabled)
            .then(({data: value}) => {
                UserDetails.setProperty('isNotificationsEnabled', value);

                value
                    ? LobbyService.initSocketConnection()
                    : LobbyService.disconnectSocketConnection();
            });
    },

    getNotifications() {
        return HTTP.get('/notifications')
            .then(({data}) => Notify.notifications = data);
    },

    disconnectSocketConnection() {
        if (client != null) {
            client.disconnect();
            console.log('Disconnected');
        }
    },

    fetchUserDetails() {
        return HTTP.get('/users/current')
            .then(({data}) => {
                UserDetails.setUser(data);
                return UserDetails.getUser();
            });
    },

    verifyEmail(token) {
        return HTTP.get(`/public/verification/${token}`);
    },

    generateMagicLink(email) {
        const headers = {
            'subdomain': Subdomain.getSubdomain()
        };
        return HTTP.put('/public/magic-link', {email}, {headers});
    },

    signInViaMagicLink(token) {
        return HTTP.post(`/public/sign-in/magic-link?token=${token}`)
            .then(res => {
                commit('initSubscriptionStatus');
                return res;
            })
            .then(() => this.fetchUserDetails());
    },

    sendResetPasswordEmail(payload) {
        return HTTP.post(`/public/users/send-reset-password-token`, payload);
    },

    resetPassword(userData) {
        return HTTP.put(`/public/users/reset-password`, userData)
            .then(() => {
                Notify.ok('Password added.');
                setTimeout(() => window.location.href = process.env.VUE_APP_WEBSITE_UI_URL + '/#sign-in', 2000);
            });
    },

    setUserPassword(userData) {
        return HTTP.put(`/public/set-password/token`, userData)
            .then(() => {
                Notify.ok('Password added.');
                window.location.href = process.env.VUE_APP_WEBSITE_UI_URL + '/#sign-in';
            });
    },

    unsubscribeEmailNotifications(token) {
        return HTTP.put(`/public/notifications/email/unsubscribe/${token}`, {});
    },

    resetStore() {
        let newState = {};
        Object.keys(store.state).forEach(key => {
            newState = {
                ...newState,
                [key]: store.state[key].initialState || store.state[key]
            };
        });
        store.replaceState(Object.assign({}, newState));
    },

    signOut() {
        HTTP.post('/public/token/logout')
            .then(() => {
                this.disconnectSocketConnection();
                CLEAR_STORAGE_DATA.forEach(item => {
                    localStorage.removeItem(item);
                });
                EVENT_BUS.resetAll();
                window.location.href = process.env.VUE_APP_WEBSITE_UI_URL + '/#sign-in';
                setTimeout(() => this.resetStore(), 500);
            });
    }
};
