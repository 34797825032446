<template>
    <div :class="['o-slider', {'show-min-max': showMinMax, disabled, low, mid, high}]" :aria-min="min" :aria-max="max"><input
        ref="slider" type="range" :min="min" :step="step" :max="max" v-model="localValue" @input="onInput" class="o-slider__input">
        <div class="o-slider__filled" :style="{width: filledWidth + 'px'}"></div>
    </div>
</template>
<script> export default {
    name    : 'o-slider',
    props   : {
        min       : {default: 0},
        max       : {default: 0},
        value     : {default: 0},
        step      : {default: 1},
        disabled  : {default: false},
        showMinMax: {default: false},
        debounce  : {
            type   : Number,
            default: 0
        }
    },
    data() {
        return {
            localValue    : this.value,
            filledWidth   : 0,
            debounceId    : null,
            setFilledWidth: () => this.filledWidth = this.value ? this.$refs.slider.getBoundingClientRect().width / 100 * ((parseFloat(this.value) - this.min) / (this.max - this.min) * 100) : 0
        };
    },
    methods : {
        onInput() {
            this.$emit('change', this.localValue);
            clearTimeout(this.debounceId);
            this.debounceId = setTimeout(() => {this.$emit('debouncedChange', this.localValue);}, this.debounce);
        }
    },
    watch   : {
        value: {
            immediate: true,
            handler(val) {
                this.localValue = val || 0;
                this.$refs.slider ? this.setFilledWidth(val) : setTimeout(this.setFilledWidth);
            }
        }
    },
    model   : {
        prop : 'value',
        event: 'change'
    },
    computed: {
        low : vm => 100 / vm.max * vm.localValue <= 60,
        mid : vm => 100 / vm.max * vm.localValue > 60 && 100 / vm.max * vm.localValue <= 80,
        high: vm => 100 / vm.max * vm.localValue > 80
    }
};</script>
<style lang="scss" scoped> .o-slider {
    position: relative;
    display: flex;
    align-items: center;

    &.disabled {
        pointer-events: none;
        opacity: .5;
    }

    &.show-min-max {
        &::before {
            content: attr(aria-min);
            position: absolute;
            top: -24px;
            left: 4px;
        }

        &::after {
            content: attr(aria-max);
            position: absolute;
            top: -24px;
            right: 4px;
        }
    }

    &__filled {
        position: absolute;
        left: 0;
        background-color: $PRIMARY;
        border-radius: 5px;
        height: 3px;
        pointer-events: none;
        transition: background-color .5s cubic-bezier(.35, 0, .25, 1);
        will-change: background-color;
        transform: translate3d(0, 0, 0);
    }

    &__input {
        -webkit-appearance: none;
        width: 100%;
        height: 3px;
        border-radius: 5px;
        background: $GRAY_B9;
        outline: none;
        transition: opacity .2s;
        will-change: opacity;
        margin: 2px 0;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: $PRIMARY;
            transform: scale(1);
            cursor: pointer;
            transition: transform .2s, background-color .5s cubic-bezier(.35, 0, .25, 1);
            will-change: background-color, transform;
        }

        &::-moz-range-thumb {
            appearance: none;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background: $PRIMARY;
            transform: scale(1);
            overflow: hidden;
            cursor: pointer;
            transition: transform .2s, background-color .5s cubic-bezier(.35, 0, .25, 1);
            will-change: background-color, transform;
        }
    }

    &.low &__filled, &.low &__input::-webkit-slider-thumb {
        background-color: $NPS_LOW;
    }

    &.low &__filled, &.low &__input::-moz-range-thumb {
        background-color: $NPS_LOW;
    }

    &.mid &__filled, &.mid &__input::-webkit-slider-thumb {
        background-color: $NPS_MID;
    }

    &.mid &__filled, &.mid &__input::-moz-range-thumb {
        background-color: $NPS_MID;
    }

    &.high &__filled, &.high &__input::-webkit-slider-thumb {
        background-color: $NPS_HIGH;
    }

    &.high &__filled, &.high &__input::-moz-range-thumb {
        background-color: $NPS_HIGH;
    }
}</style>
