import { LobbyService } from '@/common/services/lobby';
import { UserDetails } from '@/common/user-details';
import { buildRoutePattern } from '@/common/utils/helpers';
import store from '@/store/store';

export const ROUTES = {
    // PUBLIC ROUTES
    SIGN_IN                  : '/sign-in',
    MAGIC_LINK               : '/sign-in/magic-link',
    UNSUBSCRIBE              : '/account/unsubscribe',
    FORGOT_PASSWORD          : '/forgot-password',
    RESET_PASSWORD           : '/reset-password',
    SET_PASSWORD             : '/set-password',
    UPDATE_PASSWORD_CONFIRMED: '/account/update-password',
    SET_PASSWORD_CONFIRMED   : '/account/set-password',
    EMAIL_CONFIRMED          : '/account/email-confirmed',
    MAGIC_LINK_CONFIRMED     : '/account/magic-link-confirmed',
    EXTERNAL_REVIEW           : '/external-review'
};

const getSubscriptionStatus = () => store.getters.isSubscriptionPurchased;

const PUBLIC_ROUTES = Object.keys(ROUTES).map(key => ROUTES[key]);

const isPathInRoutes = (routes, path) => routes.some(route => buildRoutePattern(route).test(path));

const isPublicRoute = to => isPathInRoutes(PUBLIC_ROUTES, to.path);

const handleSubscriptionStatus = function (to, next) {
    if (!getSubscriptionStatus()) {
        to.path === '/billing' ? next() : next('/billing');
    } else {
        next();
    }
};

function processUserDetailsAndRoles(to, next) {
    if (UserDetails.roles && UserDetails.roles.length) {
        handleSubscriptionStatus(to, next);
    } else {
        LobbyService.fetchUserDetails()
            .then(() => handleSubscriptionStatus(to, next));
    }
}

export const AuthGuard = (to, from, next) => {
    if (getSubscriptionStatus()) {
        processUserDetailsAndRoles(to, next);
    } else if (isPublicRoute(to)) {
        next();
    } else {
        processUserDetailsAndRoles(to, next);
    }
};
