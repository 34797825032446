<template>
    <div class="o-tabs">
        <div class="o-tabs__header" ref="headerButtons">
            <button v-for="(label, i) in labels"
                    v-ripple="true"
                    class="o-tabs__header__label"
                    :key="label"
                    :class="[{active: localTabIndex === i}, labels[i]]"
                    @click="onLabelClick(i)">
                {{ label }}
            </button>
            <span :style="underlineStyle" class="o-tabs__header__underline"></span>
        </div>

        <div class="o-tabs__wrapper" ref="container">
            <div class="o-tabs__wrapper__body" ref="tabs">
                <div v-for="(label, i) in labels" :class="localTabIndex === i ? 'visible' : 'hidden'" :key="label">
                    <slot :name="label"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script> export default {
    name    : 'o-tabs',
    data() {
        return {
            localTabIndex: this.selectedTabIndex,
            buttonsWidths: []
        };
    },
    props   : {
        labels          : {
            type: Array,
            default() {return [];}
        },
        selectedTabIndex: {
            type   : Number,
            default: 0
        }
    },
    mounted() {
        this.$nextTick(this.getButtonsWidths);
    },
    model   : {
        prop : 'selectedTabIndex',
        event: 'tabIndexChange'
    },
    watch   : {
        selectedTabIndex(val) {
            this.localTabIndex = val;
            this.$refs.tabs.classList.add('animated');
            setTimeout(() => this.$refs.tabs.classList.remove('animated'), 500);
        },
        labels() {setTimeout(this.getButtonsWidths);}
    },
    methods : {
        getButtonsWidths() {
            this.buttonsWidths = this.$refs.headerButtons && Array.from(this.$refs.headerButtons.querySelectorAll('button'))
                .map(el => el.clientWidth);
        },
        onLabelClick(i) {
            this.localTabIndex = i;
            this.$emit('tabIndexChange', i);
            this.getButtonsWidths();
        }
    },
    computed: {
        underlineStyle: vm => ({
            left : vm.buttonsWidths.slice(0, vm.localTabIndex).reduce((a, b) => a + b, 0) + 'px',
            width: vm.buttonsWidths[vm.localTabIndex] + 'px'
        })
    }
};
</script>
<style lang="scss" scoped>
    .o-tabs {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;

        &__header {
            position: relative;
            height: 48px;
            min-height: 48px;
            max-height: 48px;
            display: flex;
            border-bottom: 1px solid $BLACK_15;

            &__underline {
                position: absolute;
                height: 2px;
                background-color: $PRIMARY;
                bottom: -1px;
                transition: .5s cubic-bezier(.35, 0, .25, 1);
                will-change: auto;
            }

            &__label {
                width: 160px;
                flex: 0 1 160px;
                padding: 0 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border: none;
                background: transparent;
                position: relative;

                &:hover {
                    cursor: pointer;
                    background-color: $HOVER;
                }

                &, &:active {
                    outline: none;
                }
            }
        }

        &__wrapper {
            display: flex;
            overflow-x: hidden;
            overflow-y: auto;
            margin: 16px;

            &__body {
                white-space: nowrap;
                width: 100%;
                display: initial;
                will-change: transform;

                &.animated {
                    transition: transform .5s cubic-bezier(.35, 0, .25, 1);
                }

                & > * {
                    vertical-align: top;
                    display: inline-block;
                    width: 100%;
                    overflow-x: hidden;
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity .5s cubic-bezier(.35, 0, .25, 1);
                    will-change: opacity;
                }

                & > .visible {
                    opacity: 1;
                    pointer-events: all;
                }

                & > .hidden {
                    height: 0;
                    width: 0;
                    display: none;
                }
            }
        }
    }
</style>
