<template>
    <div :class="['comments', {open : selectedGoal}]" :style="this.commentStyle">
        <div class="comments__header">
            <span>Comments</span>

            <o-button icon @click="closeComments">
                <o-icon name="clear"/>
            </o-button>
        </div>

        <div class="comments__body" v-if="selectedGoal && selectedGoal.comments.length">
            <div
                v-for="(comment, commentIndex) in selectedGoal.comments"
                :key="commentIndex"
                class="comment"
            >
                <div class="comment__header">
                    <div>
                        <div>
                            <o-user-image :size="32" :user="comment"/>
                        </div>

                        <div>
                            {{ comment.firstName }} {{ comment.lastName }}
                        </div>
                    </div>

                    <div>
                        {{ comment.createdAt }}
                    </div>
                </div>

                <div class="comment__body">
                    {{ comment.message }}
                </div>
            </div>
        </div>

        <div class="comments__input">
            <o-input
                placeholder="Add Comment"
                v-model.trim="comment.message"
                @enter="addComment"
            >
                <o-icon
                    name="send"
                    slot="avSuffix"
                    class="pointer"
                    @click="addComment"
                />
            </o-input>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'comments',

        data() {
            return {
                comment: {
                    message: ''
                }
            };
        },

        computed: {
            ...mapGetters([
                'selectedGoal',
                'commentStyle'
            ])
        },

        methods: {
            ...mapMutations([
                'setSelectedGoal',
                'setCommentStyle'
            ]),
            closeComments() {
                this.setSelectedGoal(null);
                this.setCommentStyle(null);
            },
            addComment() {
                if (this.comment.message) {
                    this.$store.dispatch(
                        'addComment',
                        {
                            goalId : this.selectedGoal.id,
                            message: this.comment
                        }
                        )
                        .then(() => {
                            this.comment.message = '';
                        });
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .comments {
        position: fixed;
        right: 0;
        width: 0;
        transition: width .4s cubic-bezier(.35, 0, .25, 1);
        will-change: width;
        color: $GRAY_4A;
        z-index: 2;
        background-color: $WHITE;

        &.open {
            padding: 14px;
            min-width: 270px;
            width: 20%;
            border: .5px solid $GRAY_F0;
            display: flex;
            flex-direction: column;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > :first-child {
                font-size: 20px;
                font-weight: 500;
            }
        }

        &__body {
            overflow: auto;
            height: 90%;
            overflow-x: hidden;
            padding: 0 8px;

            .comment {
                margin-top: 20px;
                color: $BLACK_87;

                &__header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > :first-child {
                        display: flex;
                        align-items: center;

                        > :first-child {
                            margin-right: 10px;
                            font-weight: 500;
                        }
                    }

                    > :last-child {
                        font-size: 11px;
                    }
                }

                &__body {
                    font-size: 14px;
                    margin-top: 10px;
                    border-bottom: 1px solid $GRAY_E3;
                    padding: 0 0 15px;
                }
            }
        }

        &__input {
            margin-top: auto;
            margin-bottom: 25px;
        }
    }

    @media only screen and (max-width: 812px) {
        .comments {
            &.open {
                height: 100% !important;
                top: 0 !important;
            }
        }
    }

</style>
