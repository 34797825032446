import { initialTeamState } from './initial-team-state';

export default {
    setIsNameUnique(state, isUnique) {
        state.isNameUnique = isUnique;
    },
    setTeam(state, team) {
        team.members.sort((a, b) => (a.fullName > b.fullName) ? 1 : (b.fullName > a.fullName) ? -1 : 0);
        state.team            = team;
        state.initialTeamName = team.name;
    },
    toggleTeamNameEdit(state) {
        state.isTeamNameEdit = !state.isTeamNameEdit;
    },
    setTeamNameNotEdit(state) {
        state.isTeamNameEdit = false;
    },
    setInitialTeamName(state, name) {
        state.initialTeamName = name;
    },
    nextStepHandler(state, data) {
        state.team.teamName = data;
        state.step++;
    },
    backStepHandler(state) {
        state.step--;
    },
    addToTeam(state, user) {
        state.addedToTeam.findIndex(m => m.id === user.id) === -1 && state.addedToTeam.push(user);
    },
    setTeamsPageData(state, data) {
        state.teamsPage     = data;
        state.teamPageState = data.totalElements ? 'TEAMS' : 'PLACEHOLDER';
    },
    removeFromTeam(state, memberIndex) {
        state.addedToTeam.splice(memberIndex, 1);
    },
    resetTeamState(state) {
        const s = initialTeamState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
