export const sortBy = {
    methods: {
        sortBy(prop, filter) {
            if (filter.sortColumn === prop) {
                filter.sortDirection = filter.sortDirection === 'ASC' ? 'DESC' : 'ASC';
            } else {
                filter.sortColumn    = prop;
                filter.sortDirection = 'ASC';
            }
        },
    }
};
