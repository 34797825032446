<template>
    <button class="button" @click="onClick" :type="isSubmit ? 'submit' : 'button'" :disabled="disabled"
            :class="{'primary-button': isPrimary, 'icon-button': isIcon, 'secondary-button': isSecondary}" v-ripple="useRipple || isIcon">
        <slot></slot>
    </button>
</template>
<script>export default {
    name    : 'o-button',
    props   : {
        icon        : undefined,
        ripple      : undefined,
        primary     : undefined,
        secondary   : undefined,
        submitButton: undefined,
        disabled    : {
            type   : Boolean,
            default: false
        }
    },
    computed: {
        useRipple  : vm => vm.ripple !== undefined,
        isPrimary  : vm => vm.primary !== undefined,
        isSecondary: vm => vm.secondary !== undefined,
        isSubmit   : vm => vm.submitButton !== undefined,
        isIcon     : vm => vm.icon !== undefined
    },
    data() {
        return {
            available: true,
            debounce : undefined
        };
    },
    methods : {
        onClick(e) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => this.available = true, 300);
            if (this.available) {
                this.$emit('click', e);
                this.available = false;
            }
        }
    }
};</script>
