import { initialPeopleState } from './initial-people-state';

export default {
    setUsersPageData(state, employees) {
        state.usersPage = employees;
    },
    setUsersLeft(state, users) {
        state.totalUsersLeft = users;
    },
    setEmployeePage(state, employees) {
        state.employeePageData = employees;
    },
    setUsersWithPermissions(state, payload) {
        state.usersWithPermissions = payload.data;
        state.adminPageState       = payload.data.totalElements ? 'ADMINS' : 'PLACEHOLDER';
    },
    setUserOptions(state, options) {
        state.userOptions = options;
    },
    setCompanyState(state, companyState) {
        state.companyState = companyState ? 'ORGANIZATION' : 'PLACEHOLDER';
    },
    setPeopleFromFile(state, people) {
        state.people = people;
    },
    isPeopleInFileValid(state) {
        state.isPeopleInFileValid = state.people.every(u => u.status === 'SUCCESS');
    },
    setManagerToUser(state, manager) {
        state.manager = manager;
    },
    setUsersForSettingPage(state, users) {
        state.usersForManagerSettingPage     = users;
        state.usersForSubordinateSettingPage = users;
    },
    setUserData(state, userData) {
        state.user = userData;
    },
    setUsersToGetSelected(state, users){
      state.usersToGetSelected = users;
    },
    resetUploadFilePageState(state) {
        state.people              = [];
        state.isPeopleInFileValid = false;
    },
    resetPeopleState(state) {
        const s = initialPeopleState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
