<template>
    <div class="spinner" :style="{opacity: counter ? '1' : '0'}">
        <div class="spinner-part-1"></div>

        <div class="spinner-part-2"></div>
    </div>
</template>

<script>
    export default {
        name   : 'spinner',
        data() {
            return {
                counter: 0
            };
        },
        methods: {
            start() {
                this.counter++;
            },
            stop() {
                this.counter--;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .spinner {
        background-color: $WHITE_FA;
        height: 6px;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        pointer-events: none;
        user-select: none;
        display: flex;

        .spinner-part-1, .spinner-part-2 {
            height: 100%;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            background-color: $PRIMARY;
            backface-visibility: hidden;
            position: absolute;
            transform: translate3d(0, 0, 0);
        }

        .spinner-part-1 {
            transform: scaleX(.5);
            animation: spinner1 4s -2.5s infinite ease-in-out;
            left: -130%;
        }

        .spinner-part-2 {
            animation: spinner1 4s infinite cubic-bezier(0.66, 0.23, 0.68, 0.96);
            left: -100%;
        }
    }

    @keyframes spinner1 {
        from {
            transform: translate3d(0, 0, 0);
        }
        to {
            transform: translate3d(260.61106%, 0, 0);
        }
    }

</style>