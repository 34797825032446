<template>
    <div class="o-autocomplete-wrapper" :aria-placeholder="placeholder" :style="{margin: margins}"
         :class="{'focused': focused, 'remove-underline':removeUnderline, 'floating': focused || inputValue, disabled, 'av-error': error}">
        <input type="text" @input="onInput" @focus="focus" @blur="blur" @click.prevent.stop="focus" @keypress.enter="onEnterPress"
               :style="{paddingRight: showX && inputValue ? '48px' : '24px'}" :id="_uid" :show-x="true" v-model="inputValue"
               autocomplete="off" class="autocomplete-input" v-tooltip="inputValue"/>
        <o-button v-if="showX && inputValue" @click.prevent="inputValue = ''; $emit('search', '')" icon class="clear">
            <o-icon name="clear"/>
        </o-button>
        <o-icon name="arrow_drop_down" class="arrow"></o-icon>
        <div ref="target">
            <div @wheel.self.prevent class="backdrop" :style="{pointerEvents: focused ? 'all' : 'none'}" @click.self="blur"></div>
            <transition name="animate">
                <div class="av-dropdown" :for="_uid" v-if="focused && options && options.length" @mousedown.prevent.stop ref="dropdown"
                     @wheel.prevent="handleScroll">
                    <div @mouseenter="isOverflowing" v-for="(option, oi) in options" :key="oi" class="dropdown-item" @mouseleave="isOverflowed = false"
                         :class="{disabled: selfDisabled(option) || disabledBySelectedList.length && disabledBySelectedList.indexOf(getValue(option)) !== -1, dashed: isLatestCustomGoal(option)}"
                         @click="select(option, oi)" v-tooltip.before="isOverflowed ? labelProp === undefined ? option : getLabelByProp(option) : ``"><span>{{labelProp === undefined ? option : getLabelByProp(option)}}</span></div>
                </div>
            </transition>
        </div>
        <transition name="fade"><span class="input-error" v-if="error">{{error}}</span></transition>
    </div>
</template>
<script> import { EVENT_BUS } from '@/common/utils/event-bus';

export default {
    name    : 'o-autocomplete',
    props   : {
        errorsList            : {default: null},
        name                  : {default: ''},
        margins               : {default: '16px'},
        removeUnderline       : {default: false},
        disableSelf           : {default: null},
        placeholder           : {type: String},
        options               : {type: Array},
        valueProp             : {type: String},
        labelProp             : {type: String},
        value                 : {default: null},
        showX                 : {type: Boolean},
        disabledBySelectedList: {default: () => []},
        disabled              : {
            type   : Boolean,
            default: false
        },
        directError           : {type: String},
        directErrorIf         : {type: Boolean},
        debounce              : {
            type   : Number,
            default: 500
        },
        distinct              : {
            type   : Boolean,
            default: true
        },
        isGoalSelect          : {
            type   : Boolean,
            default: false
        }
    },
    mounted() {
        this.selectEl = document.getElementById(this._uid);
        EVENT_BUS.on('MOUSEDOWN_EVENT', this.blur);
    },
    data() {
        return {
            focused       : false,
            inputValue    : '',
            selectedValue : this.value,
			dropdown      : null,
			isOverflowed: false,
            scrollListener: e => {
                const {scrollTop, scrollHeight} = e.target;
                const {height}                  = e.target.getBoundingClientRect();
                scrollTop + height === scrollHeight && this.$emit('loadMore');
            },
            selectEl      : null,
            handleScroll  : e => this.$refs.dropdown.scrollTop += e.deltaY
        };
    },
    watch   : {
        value: {
            immediate: true,
            handler(val) {
                this.inputValue = val ? this.getLabelByProp(val) || val : '';
            }
        },
        focused(isFocused) {
            this.onFocus(isFocused);
        },
        options() {
            this.onFocus(true);
        }
    },
    methods : {
        onFocus(isFocused) {
            setTimeout(() => {
                this.dropdown = document.querySelector(`[for="${this._uid}"]`);
                if (isFocused && this.options && this.options.length && this.dropdown) {
                    document.body.appendChild(this.$refs.target);
                    const {left, bottom, top, width, height} = this.selectEl.getBoundingClientRect();
                    const {innerHeight}                      = window;
                    const {height: targetHeight}             = this.dropdown.getBoundingClientRect();
                    this.dropdown.style.left                 = left + 'px';
                    this.dropdown.style.width                = width + 'px';
                    this.dropdown.style.top                  = (bottom + 4 + targetHeight >= innerHeight - height ? top - targetHeight - 4 : bottom + 4) + 'px';
                    this.dropdown.addEventListener('scroll', this.scrollListener);
                } else {
                    this.dropdown && this.dropdown.removeEventListener('scroll', this.scrollListener);
                }
            });
        },
        onInput({target: {value}}) {
            this.inputValue = value;
            this.focused    = true;
            clearTimeout(this.debounceId);
            if (this.distinct && value !== this.value || !this.distinct) {
                this.debounceId = setTimeout(() => {
                    this.$emit('search', value);
                }, this.debounce);
            }
        },
        onEnterPress(e) {
            this.$emit('enter', e.target.value);
        },
        selfDisabled(option) {
            return this.disableSelf && this.getValue(option) === this.getValue(this.disableSelf);
        },
        getValue(option) {
            return this.valueProp === undefined ? option : this.getValueByProp(option);
        },
        focus() {
            this.focused = true;
            this.$emit('focus', this.$el);
        },
        blur() {
            if (this.focused) {
                this.focused = false;
                this.$emit('blur', this.inputValue);
            }
        },
        select(val, i) {
            const value     = this.getValue(val);
            this.focused    = false;
            this.inputValue = this.getLabelByProp(val);
            this.$emit('change', value, i);
        },
        getLabelByProp(option) {
            const props = this.labelPropToUse;
            if (typeof props === 'string') {
                return option[props];
            } else if (typeof props === 'object') {
                let target = option;
                props.forEach(prop => target = target[prop]);
                return target;
            }
        },
        getValueByProp(option) {
            const props = this.valuePropToUse;
            if (typeof props === 'string') {
                return option[props];
            } else if (typeof props === 'object') {
                let target = option;
                props.forEach(prop => target = target[prop]);
                return target;
            }
        },
        isLatestCustomGoal(option) {
            if (this.isGoalSelect) {
                const customGoals = this.options.filter(option => option.id);
                return Math.max(...customGoals.map(goal => goal.id)) === option.id;
            }
		},
		isOverflowing({target}) { 
			let margins = 0;
			let parentStyles = getComputedStyle(target)
			let spanStyles = getComputedStyle(target.lastChild)
			margins = parseInt(parentStyles.paddingLeft) + parseInt(parentStyles.paddingRight) + parseInt(spanStyles.marginLeft);
			this.isOverflowed = target.clientWidth - target.firstChild.clientWidth - margins === 0 ? true : false;
		}
    },
    computed: {
        error         : vm => {
            let error = vm.directErrorIf && vm.directError || '';
            vm.$emit('error', !!error);
            return error;
        },
        labelPropToUse: vm => {
            if (vm.labelProp === undefined) {
                return vm.labelProp;
            } else {
                const pureProp = vm.labelProp.trim();
                return pureProp.indexOf('.') === -1 ? pureProp : pureProp.split('.').map(s => s.trim()).filter(s => !!s);
            }
        },
        valuePropToUse: vm => {
            if (vm.valueProp === undefined) {
                return vm.valueProp;
            } else {
                const pureProp = vm.valueProp.trim();
                return pureProp.indexOf('.') === -1 ? pureProp : pureProp.split('.').map(s => s.trim()).filter(s => !!s);
            }
        }
    },
    beforeDestroy() {
        this.$refs.target && this.$refs.target.remove();
        EVENT_BUS.off('MOUSEDOWN_EVENT', this.blur);
    }
};</script>
<style lang="scss" scoped> .autocomplete-input {
    outline: 0;
    border: 0;
    font-size: 16px;
    color: $GRAY_4A;
    user-select: none;
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    overflow: auto;
    text-overflow: ellipsis;
    width: 50px;

    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.o-autocomplete-wrapper {
    min-width: 160px;
    position: relative;
    display: flex;
    height: 35px;
    border-bottom: 1px solid $GRAY_F0;
    box-sizing: content-box;

    &::before {
        transition-duration: .25s;
        transition-property: all;
        content: attr(aria-placeholder);
        font-size: 16px;
        color: $GRAY_97;
        position: absolute;
        width: 100%;
        bottom: 50%;
        transform: translateY(calc(50% + 1px));
        pointer-events: none;
        font-weight: 400;
    }

    &.floating::before {
        font-size: 12px;
        top: -12px;
        bottom: unset;
        transform: none;
    }

    &.focused::after {
        opacity: 1;
        transform: scaleX(1);
    }

    &.focused::before {
        color: $PRIMARY;
    }

    &::after {
        transition: transform .25s;
        will-change: transform;
        transform: scaleX(.1);
        border-bottom: 2px solid $PRIMARY;
        content: '';
        position: absolute;
        width: 100%;
        bottom: -1px;
        opacity: 0;
        z-index: 1;
    }

    &.remove-underline {
        border-bottom: 0;

        &::after {
            visibility: hidden;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: .5;
    }
}

.disabled {
    pointer-events: none;
    opacity: .5;
}

.arrow {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.clear {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px;
    color: $GRAY_8B;
}

.av-dropdown {
    overflow-y: auto;
    position: fixed;
    background-color: $WHITE;
    z-index: 13;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border-radius: 2px;
    max-height: 280px;
    min-height: 48px;

    > * {
        height: 40px;
        padding: 0 24px;
        font-size: 16px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow-x: hidden;
        color: $BLACK_54;

        &:hover {
            cursor: pointer;
            color: $WHITE;
            background-color: $PRIMARY;
        }

        > * {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.av-option {
    color: $PRIMARY;
    background-color: $WHITE;
}

.animate-enter-active, .animate-leave-active {
    transition: all .25s;
}

.animate-enter, .animate-leave-to {
    transform: translateY(40px);
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.input-error {
    color: $WARN;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: -18px;
    height: 12px;
}

.av-error {
    &::before, &:not(.focused) .arrow {
        color: $WARN;
    }

    & {
        border-color: $WARN;
    }
}

.dashed {
    border-bottom: 1px dashed $BLACK_54;
}</style>
