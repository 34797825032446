export const CookieManager = {
    getParsedCookies() {
        const cookieObject = {};
        document.cookie.split(';').map(s => s.trim()).forEach(keyValueString => {
            const [key, value] = keyValueString.split('=');
            cookieObject[key]  = value;
        });
        return cookieObject;
    },

    /**
     * might return 'undefined'
     * @param key
     * @returns {*}
     */
    getCookie(key) {
        const cookies = this.getParsedCookies();
        return cookies[key];
    }
};
