import goalsMutations from './goals-mutations';
import goalsActions from './goals-actions';
import goalsGetters from './goals-getters';
import { initialGoalsState } from './initial-goals-state';

export default {
    state    : {
        ...initialGoalsState(),
        initialState: initialGoalsState()
    },
    mutations: goalsMutations,
    actions  : goalsActions,
    getters  : goalsGetters
};
