<template>
    <div :class="['o-toggle', {disabled}]" @click="toggle"><input class="o-toggle__trigger" type="checkbox" v-model="value">
        <div class="o-toggle__bar">
            <div class="o-toggle__bar__circle"></div>
        </div>
        <span :style="labelPos" class="o-toggle__label" v-text-overflow><slot></slot></span></div>
</template>
<script> export default {
    name    : 'o-toggle',
    props   : {
        labelPosition: {default: 'after'},
        checked      : {default: false},
        disabled     : {default: false}
    },
    data() {return {value: this.checked};},
    methods : {
        toggle() {
            this.value = !this.value;
            this.$emit('change', this.value);
        }
    },
    model   : {
        prop : 'checked',
        event: 'change'
    },
    watch   : {checked(value) {this.value = value;}},
    computed: {
        labelPos: vm => vm.labelPosition === 'before' ? {
            order      : -1,
            marginRight: '8px'
        } : {
            order     : 1,
            marginLeft: '8px'
        }
    }
};</script>
<style lang="scss" scoped> input[type="checkbox"] {
    display: none;
}

.disabled {
    pointer-events: none;
    opacity: .5;
}

.o-toggle {
    display: inline-flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    &__bar {
        position: relative;
        width: 36px;
        height: 14px;
        flex-shrink: 0;
        border-radius: 8px;

        &__circle {
            position: absolute;
            width: 20px;
            height: 20px;
            top: -4px;
            left: 0;
            transition: all 80ms linear;
            will-change: auto;
            transition-property: transform;
            border-radius: 50%;
            box-shadow: 0 2px 1px -1px $BLACK_15, 0 1px 1px 0 $BLACK_15, 0 1px 3px 0 $BLACK_15;

            &::before {
                border-radius: 50%;
                opacity: 0;
                content: "";
                height: 100%;
                width: 100%;
                position: absolute;
            }
        }
    }

    &__trigger:not(:checked) + &__bar {
        background-color: $BLACK_38;
    }

    &__trigger:not(:checked) + &__bar > &__bar__circle {
        transform: translate3d(0, 0, 0);
        background-color: $WHITE_FA;

        &::before {
            background-color: $BLACK_38;
            animation: .5s linear 0s uncheck;
        }
    }

    &__trigger:checked + &__bar {
        background-color: transparentize($PRIMARY, .5);
    }

    &__trigger:checked + &__bar > &__bar__circle {
        transform: translate3d(16px, 0, 0);
        background-color: $PRIMARY;

        &::before {
            background-color: transparentize($PRIMARY, .5);
            animation: .5s linear 0s check;
        }
    }

    &__label {
        color: $BLACK_87;

        &:hover {
            cursor: default;
        }
    }
}

@keyframes uncheck {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    50% {
        opacity: .35;
        transform: scale(2);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}

@keyframes check {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    50% {
        opacity: .35;
        transform: scale(2);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}</style>
