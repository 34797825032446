<template>
    <div class="modal-container">
        <div ref="backdrop" @wheel.self.prevent class="backdrop"
             :style="{backgroundColor:backdrop?'rgba(0,0,0,.5)':'transparent', zIndex: z}" :class="{show: isOpen}"
             @click.self="onBackDropClick">
            <div :style="{maxWidth}" :class="['o-modal', {[className]: className}]">
                <div class="o-modal__header">
                    <slot name="header"></slot>
                </div>
                <div class="o-modal__body">
                    <slot name="body"></slot>
                </div>
                <div class="o-modal__footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script> import Vue from 'vue';

export default {
    name   : 'o-modal',
    props  : {
        z          : {default: 0},
        backdrop   : {
            type   : Boolean,
            default: true
        },
        closeMethod: {
            type   : String,
            default: null
        },
        maxWidth   : {
            type   : String,
            default: '500px'
        },
        className  : {default: ''}
    },
    data() {
        return {
            isOpen      : false,
            target      : null,
            openListener: () => {
                this.isOpen = true;
                this.closeMethod && (Vue.prototype[this.closeMethod] = () => this.onClose());
                this.$emit('onOpen', true);
            }
        };
    },
    methods: {
        onClose() {
            this.isOpen = false;
            this.closeMethod && delete Vue.prototype[this.closeMethod];
            this.$emit('onClose', false);
        },
        onBackDropClick() {
            this.onClose();
            this.$root.$emit('resetModalState');
        }
    },
    mounted() {
        this.target = this.$el.previousElementSibling;
        this.target && this.target.addEventListener('click', this.openListener);
        document.body.appendChild(this.$refs.backdrop);
    },
    beforeDestroy() {
        this.closeMethod && delete Vue.prototype[this.closeMethod];
        this.$refs.backdrop.remove();
        this.target && this.target.removeEventListener('click', this.openListener);
    }
};</script>
<style scoped lang="scss"> .modal-container {
    display: none;
}

.o-modal {
    width: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
    min-height: 160px;
    min-width: 320px;
    max-height: 700px;
    background-color: $WHITE;
    position: fixed;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &__header {
        padding: 24px 32px;
        font-weight: 500;
        font-size: 20px;
        color: $GRAY_4A;
    }

    &__body {
        padding: 0 32px;
        flex: 1 0;
    }

    &__footer {
        padding: 24px 32px;
        text-align: right;
    }
}</style>
