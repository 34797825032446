import notificationsGetters from './notifications-getters.js';
import notificationsMutations from './notifications-mutations.js';

export default {
    state    : {
        hasSeenAllMessages    : false
    },
    getters  : notificationsGetters,
    mutations: notificationsMutations
};
