<template><i class="o-icon" v-html="icons[name]" :key="name" @click="$emit('click', $event)" @mousedown="$emit('mousedown', $event)"></i></template>
<script> import icons from '@/common/utils/icons';

export default {
    name : 'o-icon',
    props: ['name'],
    data() {return {icons};}
};</script>
<style lang="scss" scoped> .o-icon {
    height: 1em;
    width: 1em;
    line-height: 1em;
    font-size: 24px;
    display: inline-block;

    ::v-deep svg {
        pointer-events: none;
        fill: currentColor;
        height: 1em;
        width: 1em;
    }
}</style>
