import { parentChildRelation } from '@/common/mixins/parent-child-relation';
import { reviewAnswer } from '@/common/mixins/review-answer';
import { sortBy } from '@/common/mixins/sort-by';
import { uiSort } from '@/common/mixins/ui-sort';
import Vue from 'vue';

Vue.mixin(uiSort);
Vue.mixin(parentChildRelation);
Vue.mixin(reviewAnswer);
Vue.mixin(sortBy);
