import paymentsActions from './payments-actions';
import paymentsMutations from './payments-mutations';
import paymentsGetters from './payments-getters';
import paymentsState from './payments-state';

export default {
    state    : paymentsState,
    mutations: paymentsMutations,
    actions  : paymentsActions,
    getters  : paymentsGetters
};
