const dayInMillis = 24 * 60 * 60 * 1000;

export const StageDateHint = (stages, targetStage) => {
    const stage = stages.find(s => s.stageType === targetStage);
    let hint    = '';

    if (stage) {
        const now        = new Date(),
              date       = stage.endAt,
              nowMillis  = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime(),
              dateMillis = new Date(date).getTime();

        if (date) {
            if (nowMillis + dayInMillis === dateMillis) {
                hint = 'Due tomorrow';
            } else if (dateMillis <= nowMillis) {
                hint = 'Closed: ' + date;
            } else {
                hint = 'Due ' + date;
            }
        }
    }
    return hint;
};