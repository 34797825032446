import { HTTP } from '@/common/utils/http';
import { STATUSES_TO_DB } from '@/common/models/user/review-process/peer-statuses';
import { PeopleService } from '@/common/services/people'
import Vue from 'vue';

export default {
    async getSelectedPeers({commit}, {processId, employeeId}) {
        const response = await HTTP.get(`/peer-requests/${processId}/peers/${employeeId}?peerRequestStatuses=${STATUSES_TO_DB.alreadyInvited}`);
        commit('setSubordinatesForApproval', response.data);
    },
    async getPeersByUser({state, commit}, {processId, employeeId, filter, managerId, append}) {
        const response = await HTTP.get(`/peer-requests/${processId}/peers/${employeeId}?filter=${filter.q}&page=${filter.page}&size=${filter.size}`);
        if (append) {
            response.data.content = response.data.content.length && [...state.peersModal.peers, ...response.data.content];
        }
        commit('setTotalPeersLeft', response.data.totalElements);
        if (response.data.content.length) {
             PeopleService.getUser(employeeId).then(res => {
                 let c = response.data.content.findIndex(item => item.userId === res.data.manager.id);
                 if (c > -1){
                    response.data.content.splice(c, 1);
                 }
                 commit('setModalData', response.data.content);
                });
        }
    },
    async approvePeerRequest({dispatch}, {processId, employeeId, peerRequestItem}) {
        await HTTP.post(`/peer-requests/${processId}/approve`, peerRequestItem);
        dispatch('getSelectedPeers', {
            processId,
            employeeId
        });
    },
    async inviteExternalPeers({dispatch}, {processId, emails, employeeId}) {
        const payload = {
            emails: emails,
            employeeId
        };
        await HTTP.post(`/peer-requests/${processId}/external`, payload);
        dispatch(
            'getSelectedPeers',
            {
                processId,
                employeeId
            }
        );
    },
    async disapprovePeerRequests({dispatch}, {processId, employeeId, peerRequestId, userType}) {
        const payload = {
            peerRequestId,
            userType
        };
        await HTTP.put(`/peer-requests/${processId}/dismiss/${employeeId}`, [payload]);
        dispatch(
            'getSelectedPeers',
            {
                processId,
                employeeId
            }
        );
    },
    async sendPeerRequests({commit, dispatch}, {processId, peersIds, employeeId}) {
        await HTTP.post(`/peer-requests/${processId}/?employeeId=${employeeId}`, peersIds);
        dispatch(
            'getSelectedPeers',
            {
                processId,
                employeeId
            }
        );
        commit('dropDataForAddPeersModal');
    },
    async getTemplateForExternalPeer({commit}, token) {
        const response = await HTTP.get(`/public/external-reviews/${token}`);
        response.data.groups.forEach(group => group.components.forEach(component => Vue.prototype.parseQuestion(component)));
        commit('setExternalPeerTemplate', response.data);
    },
    async saveTemplateForExternalPeer({dispatch}, {token, reviewForm}) {
        await HTTP.post(`/public/external-reviews/${token}`, reviewForm);
    },
    async getRequestersByPeer({commit, dispatch, rootState}, {processId, filter, template}) {
        await HTTP.get(`/peer-requests/${processId}/requesters/?page=${filter.page}&size=${filter.size}&sortDirection=${filter.sortDirection}&sortColumn=${filter.q}`)
            .then(({data: requesters}) => {
                requesters.content.forEach(requester => {
                    requester.reviewTemplateForm &&
                    requester.reviewTemplateForm.groups
                        .forEach(group => group.components
                            .forEach(component => {
                                Vue.prototype.parseQuestion(component);
                            }));
                    if (!requester.reviewTemplateForm) {
                        requester.reviewTemplateForm = Vue.prototype.cloneObj(template);
                    }
                });
                commit('setRequesters', requesters);
            });
    }
};
