<template>
    <div v-if="totalPages" class="pagination-container"><span>{{number * size + 1}}-{{number * size + numberOfElements}} of {{totalElements}}</span>
        <o-button icon @click="previousPage" :disabled="number< 1">
            <o-icon name="navigate_before"/>
        </o-button>
        <o-button icon @click="nextPage" :disabled="number + 1 >= totalPages">
            <o-icon name="navigate_next"/>
        </o-button>
    </div>
</template>
<script> export default {
    name   : 'o-pagination',
    props  : {
        totalPages      : {
            type   : Number,
            default: 0
        },
        number          : {
            type   : Number,
            default: 0
        },
        size            : {
            type   : Number,
            default: 10
        },
        totalElements   : {
            type   : Number,
            default: 0
        },
        numberOfElements: {
            type   : Number,
            default: 0
        }
    },
    methods: {
        previousPage() { this.$emit('onPageChange', this.number - 1); },
        nextPage() {this.$emit('onPageChange', this.number + 1);}
    },
};
</script>
<style lang="scss" scoped>
    .pagination-container {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        height: 64px;

        .o-icon {
            transition: transform .15s;
            will-change: transform;
        }
    }
</style>
