import { render, staticRenderFns } from "./o-textarea.vue?vue&type=template&id=56fbb673&scoped=true&"
import script from "./o-textarea.vue?vue&type=script&lang=js&"
export * from "./o-textarea.vue?vue&type=script&lang=js&"
import style0 from "./o-textarea.vue?vue&type=style&index=0&id=56fbb673&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56fbb673",
  null
  
)

export default component.exports