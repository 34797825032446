import { HTTP } from '@/common/utils/http';

export const AccountService = {

    updateUser(id, userData) {
        return HTTP.put('/users/' + id, userData)
            .then(res => res.data);
    },

    setUserToStorage(user) {
        localStorage.setItem('user.details', JSON.stringify(user));
    },

    setImage(picture) {
        return HTTP.post(`/users/images`, picture);
    },

    updatePassword(userId, payload) {
        return HTTP.put(`/users/${userId}/password`, payload);
    },

    updateTimezone(zoneId) {
        return HTTP.put(`/companies/time-zone`, zoneId);
    },
    updateEmailNotifications(settings) {
        return HTTP.put(`/notifications/email/enabled?isEmailNotificationsEnabled=${settings}`, {});
    }
};
