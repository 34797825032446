import axios from 'axios';
import { OPERF } from '@/main';
import { Notify } from '@/common/services/notification';
import { Spinner } from '@/common/services/spinner';
import { prependSubdomainToEnvVar } from '@/common/utils/helpers';

export const CANCELERS = {
    requests: [],
    cancel() {
        this.requests.forEach(c => c());
        this.requests = [];
    }
};

export const errorHandler = ({response}) => {
    const {error_description, message, details} = response.data;
    if (error_description) {
        Notify.error(error_description);
        return Promise.reject([]);
    } else if (message && !details) {
        Notify.error(message);
        return Promise.reject([]);
    } else if (message && details) {
        let msg = 'Something went wrong';
        if (typeof details === 'string') {
            msg = details;
        } else if (typeof details === 'object') {
            let firstKey = Object.keys(details)[0];
            msg          = details[firstKey][0];
        }
        Notify.error(msg);
        return Promise.reject([]);
    } else {
        return Promise.reject(Object.keys(details)
            .map(field => ({
                field,
                message: details[field][0]
            })));
    }
};

/**
 * don't use axios
 * use this instance HTTP instead by importing it wherever you need
 * @type {AxiosInstance}
 */
export const HTTP = axios.create({
    headers: {'Content-Type': 'application/json'}
});

HTTP.interceptors.request.use(request => {
    Spinner.show();

    const baseUrl = prependSubdomainToEnvVar('VUE_APP_PLATFORM_API_URL');

    if (request.url.indexOf(baseUrl) === -1) request.url = baseUrl + request.url;

    return request;
});

HTTP.interceptors.response.use(
    response => {
        Spinner.hide();
        return response;
    },
    error => {
        Spinner.hide();
        const {response: {status}} = error;

        if (status === 401) {
            OPERF.$store.commit('initSubscriptionStatus');
            window.location.href = process.env.VUE_APP_WEBSITE_UI_URL + '/#sign-in'
        }
        return errorHandler(error);
    }
);
