`
<template>
    <div :style="{height: height + 'px'}" class="o-progress"><span>{{text}}</span>
        <div :class="['progress', valueClass]" :style="{width: value + '%', height: height + 'px'}" :value="value" max="100"></div>
    </div>
</template>
<script> export default {
    name    : 'o-progress',
    props   : {
        value : {
            type   : Number,
            default: 0
        },
        text  : {
            type   : String,
            default: ''
        },
        height: {
            type   : Number,
            default: 16
        }
    },
    computed: {valueClass: vm => vm.value <= 30 ? 'low' : vm.value > 30 && vm.value <= 70 ? 'mid' : 'high'}
};</script>
<style lang="scss" scoped> .o-progress {
    min-width: 100px;
    border-radius: 10px;
    background-color: $GRAY_D8;
    display: flex;
    position: relative;

    > :first-child {
        justify-self: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
    }

    .progress {
        height: 100%;
        width: 0;
        border-radius: 10px;
        background-color: $PRIMARY;

        &.low {
            background-color: $LOW;
        }

        &.mid {
            background-color: $MID;
        }

        &.high {
            background-color: $HIGH;
        }
    }
}</style>`
