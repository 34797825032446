<template>
  <div :class="['radio-group', {disabled}]">
    <div
      v-for="(o, i) in options"
      :style="{order: i}"
      :key="i"
      :class="['o-radio', {checked: checked === o.value}]"
      @click="!disabled && (checked = o.value)"
    >
      <div class="o-radio__circle">
        <div class="o-radio__circle__inner-circle"></div>
      </div>
      <div class="text-16 o-radio__label long-text" :style="labelPos">{{o.label}}</div>
    </div>
    <transition name="fade">
      <span class="input-error" v-if="error">{{error}}</span>
    </transition>
    <slot></slot>
  </div>
</template><script>
export default {
  name: "o-radio-buttons",
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    errorsFor: { type: String },
    directError: { type: String },
    directErrorIf: { type: Boolean },
    labelPosition: { default: "after" },
    disabled: { default: false },
    value: { default: null }
  },
  data() {
    return { checked: this.value };
  },
  watch: {
    checked(val) {
      if (val) {
        this.$emit("change", val);
      }
    },
    value(val) {
      this.checked = val;
    }
  },
  model: { prop: "value", event: "change" },
  computed: {
    labelPos: vm =>
      vm.labelPosition === "before"
        ? { order: -1, marginRight: "8px" }
        : { order: 1, marginLeft: "8px" },
    error: vm => vm.directErrorIf && vm.directError
  }
};
</script><style lang="scss" scoped>
.radio-group {
  position: relative;
}
.o-radio {
  display: inline-flex;
  align-items: center;
  margin: 4px;
  &:hover {
    cursor: default;
  }
  &__circle {
    height: 20px;
    transition: border-color ease 280ms;
    width: 20px;
    border: 2px solid $BLACK_54;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:hover {
      cursor: pointer;
    }
    &__inner-circle {
      background-color: $PRIMARY;
      transform: scale(0);
      border-radius: 50%;
      transition: transform ease 280ms, background-color ease 280ms;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }
    &::after {
      border-radius: 50%;
      background-color: $PRIMARY;
      opacity: 0;
      content: "";
      height: 150%;
      width: 150%;
      position: absolute;
      animation: 0.5s linear 0s cb-blink;
    }
  }
  &.checked &__circle &__circle__inner-circle {
    transform: scale(0.5);
  }
  &.checked &__circle {
    border-color: $PRIMARY;
  }
  &:active &__circle::after {
    animation: none;
  }
}
.disabled {
  pointer-events: none;
  .o-radio .o-radio__circle {
    border-color: $DISABLED;
  }
  .o-radio.checked .o-radio__circle {
    border-color: transparentize($PRIMARY, 0.5);
    .o-radio__circle__inner-circle {
      background-color: transparentize($PRIMARY, 0.5);
    }
  }
  .o-radio__label {
    color: $DISABLED;
  }
}
.input-error {
  color: $WARN;
  font-size: 12px;
  position: absolute;
  left: 0;
  bottom: -18px;
  height: 12px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
@keyframes cb-blink {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
</style>
