<template>
    <div :class="['rating', {numeric, disabled}]">
        <ul class="list" @mouseout="starHover(value)" :style="{'--c': color}">
            <li v-for="(star,index) in stars" :key="index" :class="{'active': star <= value && !isNull(value)}" @mouseover="starHover(star)"
                @click="change(star)" class="star">
                <o-button icon v-if="numeric" class="numeric-rating">{{star}}</o-button>
                <o-button icon v-else class="star-rating">
                    <o-icon name="star"/>
                </o-button>
            </li>
        </ul>
    </div>
</template>
<script> export default {
    name: 'o-rating',
    props: {maxStars: {type: Number, default: 10}, value: {default: null}, disabled: {default: false}, numeric: {default: false}},
    data() {
        return {localValue: this.value, color: ''};
    },
    methods: {
        starHover(star) {
            this.color = star / this.stars <= 0.6 ? '#FF9800' : star / this.stars <= 0.8 ? '#9CCC65' : '#4CAF50';
        }, change(star) {
            this.localValue = star;
            this.starHover(star);
            this.$emit('change', star);
        }
    },
    watch: {
        value: {
            immediate: true, handler(star) {
                this.starHover(star);
            }
        },
    },
    model: {event: 'change', prop: 'value'},
    computed: {stars: vm => vm.numeric ? 11 : vm.maxStars}
};</script>
<style lang="scss" scoped> .rating {
    color: $GRAY_9B;

    &.disabled {
        opacity: .6;
        pointer-events: none;
    }
}

.list {
    .numeric-rating {
        border-right: 1px solid $GRAY_F0;
    }

    > :first-child > .numeric-rating {
        border-left: 1px solid $GRAY_F0;
    }
}

.rating .active, .list:hover > .star {
    .numeric-rating {
        background-color: var(--c);
    }
}

.rating .active, .list:hover > .star {
    .star-rating {
        color: var(--c);
    }
}

.list {
    margin: 0 0 5px 0;
    padding: 0;
    list-style-type: none;
    display: flex;
}

.rating:not(.numeric) .list:hover .star {
    color: var(--c);
}

.rating.numeric .list:hover .star {
    color: $WHITE;
}

.star {
    display: inline-block;
    cursor: pointer;

    button {
        color: inherit;
        padding: 1px;
    }

    &:hover ~ .star:not(.active) {
        color: inherit !important;

        > .numeric-rating {
            background-color: inherit;
        }

        > .star-rating {
            color: inherit;
        }
    }
}

.rating.numeric .active {
    color: $WHITE;
}

.rating:not(.numeric) .active {
    color: var(--c);
}

.numeric-rating {
    height: 29px;
    line-height: 24px;
    box-sizing: border-box;
    width: 29px;
    border-radius: 0;
    border-top: 1px solid $GRAY_F0;
    border-bottom: 1px solid $GRAY_F0;
}</style>
