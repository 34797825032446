<template>
    <div class="o-select-wrapper"  :aria-placeholder="placeholder" :style="{margin: margins, height: itemsHeight}"
         :class="{'focused': focused, 'floating': focused || inputVal, 'av-error': error, capitalize}"><select :id="id"
                                                                                                               class="o-select"
                                                                                                               v-model="label"
                                                                                                               @focus="focus"
                                                                                                               @blur="blur($event)">
        <option :value="label" :hidden="true">{{label}}</option>
    </select>
        <div class="select-replacer" style="width: 1e-9px;" :style="{paddingRight: showX ? '40px' : '24px', height:  itemsHeight}"
             @click="focusSelect" @mouseenter="isOverflowing" v-tooltip.after="isUserSelect && label && isOverflowed ? label : ``" @mouseleave="isOverflowed = false">
            <o-user-image :size="50" :user="user" v-if="isUserSelect"/>
            <span v-if="isUserSelect" :style="isUserSelect ? {marginLeft: '20px'} : null">{{user.fullName}}</span>
            <span v-else>{{label}}</span>
        </div>
        <o-button v-if="showX && inputVal" @click.prevent="inputVal = null" icon class="clear">
            <o-icon name="clear"/>
        </o-button>
        <o-icon name="arrow_drop_down" class="arrow"/>
        <div ref="target">
            <div class="backdrop" :style="{pointerEvents: focused ? 'all' : 'none'}" @click.self="blur($event)" @wheel.self.prevent></div>
            <transition name="animate">
                <div :style="isUserSelect ? {maxHeight: '300px', overflowY: 'auto'}: ''" :class="['av-dropdown', {capitalize}]" :for="id"
                     v-if="focused" @mousedown.prevent.stop ref="dropdown"
                     @wheel.prevent="handleScroll" @scroll="onScroll($event)">
                    <slot></slot>
                    <div @mouseenter="isOverflowing" v-for="(option, oi) in options" :key="oi" class="select-options" @mouseleave="isOverflowed = false"
                         :class="{disabled: selfDisabled(option), 'disabled':disabled}"
                         @click="select(option, oi)"
                         :style="{height: itemsHeight}" 
						 v-tooltip.after="isOverflowed ? option.fullName : ``">
                        <o-user-image :size="30" :user="option" v-if="isUserSelect"/>
                        <span :style="isUserSelect ? {marginLeft: '20px'} : null">{{labelProp === undefined ? option : option[getLabelProp(option)]}}</span>
                    </div>
                    <div :disabled="true" v-if="!options || !options.length">No options found</div>
                </div>
            </transition>
        </div>
        <transition name="fade"><span class="input-error" v-if="error">{{error}}</span></transition>
    </div>
</template>
<script> import { EVENT_BUS } from '@/common/utils/event-bus';

export default {
    name    : 'o-select',
    props   : {
        margins      : {default: '16px'},
        errorsList   : {default: null},
        name         : {default: ''},
        disableSelf  : {default: null},
        placeholder  : {type: String},
        options      : {type: Array},
        valueProp    : {type: String},
        labelProp    : {type: String},
        value        : {type: [String, Number, Object]},
        showX        : {type: Boolean},
        capitalize   : {
            type   : Boolean,
            default: true
        },
        autoFocus    : {
            type   : Boolean,
            default: () => false
        },
        disabled     : {
            type   : Boolean,
            default: false
        },
        directError  : {type: String},
        directErrorIf: {type: Boolean},
        dropdownWidth: {default: null},
        isUserSelect : {type: Boolean},
        itemsHeight  : {
            type   : String,
            default: ''
        },
		user         : {},
    },
    mounted() {
        this.selectEl = document.getElementById(this.id);
        EVENT_BUS.on('MOUSEDOWN_EVENT', this.blur);
    },
    data() {
        return {
            id          : new Date().getTime(),
            focused     : false,
            inputVal    : this.value,
            dropdown    : null,
			selectEl    : null,
			isOverflowed: false,
            handleScroll: e => this.$refs.dropdown.scrollTop += e.deltaY
        };
    },
    watch   : {
        inputVal(val) {
            this.$emit('input', val);
        },
        value(val) {
			this.inputVal = val;
        },
        focused(val) {
            if (val) {
                setTimeout(() => {
                    this.dropdown = document.querySelector(`[for="${this.id}"]`);
                    document.body.appendChild(this.$refs.target);
                    const {left, bottom, top, width, height} = this.selectEl.getBoundingClientRect();
                    const {innerHeight}                      = window;
                    const {height: targetHeight}             = this.dropdown.getBoundingClientRect();
                    this.dropdown.style.left                 = left + 'px';
                    this.dropdown.style.width                = this.dropdownWidth || width + 'px';
                    this.dropdown.style.top                  = (bottom + 4 + targetHeight >= innerHeight - height ? top - targetHeight - 4 : bottom + 4) + 'px';
                });
            }
        }
	},
    methods : {
        focusSelect() {
		
            this.selectEl.focus();
        },
        selfDisabled(option) {
            return this.disableSelf && this.getValue(option) === this.getValue(this.disableSelf);
        },
        getValue(val) {
            return this.valueProp === undefined ? val : val[this.valueProp];
        },
        focus() {
            this.focused = true;
            try {
                this.$validator.fields.items[0].validator.validate();
            } catch (e) {
            }
        },
        blur(event) {
		const target = event.relatedTarget;
            if (!target) {
                this.focused = false;
                this.$emit('blur', this.inputVal);
            } else if (target.isInternal) {
				this.focused = true;
            }
        },
        select(val, i) {
            this.focused = false;
            const value  = this.getValue(val);
            this.$emit('change', value, i);
        },
        getLabelProp(option) {
            const props = this.labelPropToUse;
            if (typeof props === 'string') {
                return props;
            } else if (typeof props === 'object') {
                return props.find(p => p in option && (typeof option[p] === 'string' ? !!option[p].trim() : !!option[p]));
            }
        },
        onScroll(event) {
            this.$emit('getMoreEmployee', event);
		},
		
		isOverflowing({target}) { 
			let margins = 0;
			let parentStyles = getComputedStyle(target)
			let spanStyles = getComputedStyle(target.lastChild)
			margins = parseInt(parentStyles.paddingLeft) + parseInt(parentStyles.paddingRight) + parseInt(spanStyles.marginLeft);
			this.isOverflowed = target.clientWidth - target.firstChild.clientWidth - target.lastChild.clientWidth - margins === 0 ? true : false;
		}
    },
    model   : {
        prop : 'value',
        event: 'change'
    },
    computed: {
        error         : vm => vm.directErrorIf && vm.directError || (vm.name && vm.errorsList && vm.errorsList.has(vm.name) && vm.errorsList.first(
            vm.name)),
        label         : vm => {
            const target = vm.options && vm.options.find(o => vm.valueProp === undefined ? o === vm.inputVal : o[vm.valueProp] === vm.inputVal);
            return target && (vm.labelProp === undefined ? target : target[vm.getLabelProp(target)]);
        },
        labelPropToUse: vm => {
            if (vm.labelProp === undefined) {
                return vm.labelProp;
            } else {
                const pureProp = vm.labelProp.trim();
                return pureProp.indexOf('||') === -1 ? pureProp : pureProp.split('||').map(s => s.trim()).filter(s => !!s);
            }
        }
    },
    beforeDestroy() {
        this.$refs.target && this.$refs.target.remove();
        EVENT_BUS.off('MOUSEDOWN_EVENT', this.blur);
    }
};</script>
<style lang="scss" scoped> .select-replacer {
    overflow: hidden;
    user-select: none;
    flex: 1 1 100%;
    display: flex;
    align-items: center;

    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.o-select-wrapper.capitalize .select-replacer {
    > span {
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase !important;
        }
    }
}

.o-select-wrapper {
    min-width: 160px;
    position: relative;
    display: flex;
    height: 35px;
    border-bottom: 1px solid $GRAY_F0;
    box-sizing: content-box;

    &::before {
        transition-duration: .25s;
        transition-property: all;
        content: attr(aria-placeholder);
        font-size: 16px;
        color: $GRAY_97;
        position: absolute;
        width: 100%;
        bottom: 50%;
        transform: translateY(calc(50% + 1px));
        pointer-events: none;
        font-weight: 400;
    }

    &.floating::before {
        font-size: 12px;
        top: -12px;
        bottom: unset;
        transform: none;
    }

    &.focused::after {
        opacity: 1;
        transform: scaleX(1);
    }

    &.focused::before {
        color: $PRIMARY;
    }

    &::after {
        transition: transform .25s;
        will-change: transform;
        transform: scaleX(.1);
        border-bottom: 2px solid $PRIMARY;
        content: '';
        position: absolute;
        width: 100%;
        bottom: -1px;
        opacity: 0;
        z-index: 1;
    }

    &.composition-statuses {
        margin: 0 auto;
        width: 40%;

        .o-select, .av-dropdown > * {
            font-size: 12px;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: .5;
    }
}

.o-select {
    position: absolute;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    border: none !important;
    outline: none !important;
    width: 100%;
    background-color: transparent;
    appearance: none;
    font-size: 16px;
    color: currentColor;
}

.arrow {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.clear {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    padding: 4px;
    color: $GRAY_8B;
}

.av-dropdown {
    overflow-y: auto;
    position: fixed;
    background-color: $WHITE;
    z-index: 100;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border-radius: 2px;
    max-height: 280px;
    min-height: 48px;

    > * {
        height: 40px;
        padding: 0 24px;
        font-size: 16px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow-x: hidden;
        color: $BLACK_54;

        &:not(.no-hover):hover {
            cursor: pointer;
            color: $WHITE;
            background-color: $PRIMARY;
        }

        > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .disabled {
        pointer-events: auto;
        opacity: .5;
        cursor: auto;

        &:hover {
            color: $BLACK_54;
            background: $WHITE;
        }
    }

}

.av-dropdown.capitalize > * > * {
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase !important;
    }
}

.av-option {
    color: $PRIMARY;
    background-color: $WHITE;
}

.animate-enter-active, .animate-leave-active {
    transition: all .25s;
}

.animate-enter, .animate-leave-to {
    transform: translateY(40px);
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.input-error {
    color: $WARN;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: -18px;
    height: 12px;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: $GRAY_BF;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: $GRAY_9B;
}

.av-error {
    &::before, &:not(.focused) .arrow {
        color: $WARN;
    }

    & {
        border-color: $WARN;
    }
}
</style>
