<template>
    <div @click="disabled ? false : focus()" class="o-input-wrapper" :aria-placeholder="placeholderAsterisk" :style="{margin: margins}"
         :class="{'focused': focused, 'floating': focused || localValue || doesInputHaveValue(), disabled,'remove-underline':removeUnderline, 'av-error': error}">
        <label :for="'input'+_uid" class="av-prefix">
            <slot name="avPrefix"></slot>
        </label>
        <div contenteditable="true" :id="'input'+_uid" ref="inputElement" class="o-input contenteditable"
             @paste.prevent.stop="$event.target.textContent = $event.clipboardData.getData('Text'); onInput($event)"
             :style="{backgroundColor: transparent ? 'transparent' : '#FFF'}"
             :class="{numberRight: type === 'number', stringLeft: type === 'text'}" :type="type" :step="step" @focus="focus" @blur="blur"
             @keydown.esc="clear" @keydown.enter="$event.preventDefault()" :disabled="disabled" @keydown="handleKeydownEvent"
             @keyup="killTyping" @input="onInput" @keypress="killTyping" :name="autoFillBy">{{localValue}}
        </div>
        <div :class="{numberRight: type === 'number', stringLeft: type === 'text'}" :disabled="disabled" :name="autoFillBy" ref="mask"
             class="o-input mask" v-html="maskHtml"></div>
        <span class="av-suffix"><o-button v-if="showX && localValue" @click.prevent.stop="clear" icon><o-icon name="clear"/></o-button><slot
            name="avSuffix"></slot></span><span :class="['input-error', {'visible': error}]"><span class="error-text" v-if="isLongError"
                                                                                                      v-tooltip.below="error">{{error}}</span><span
        class="error-text" v-else>{{error}}</span></span></div>
</template>
<script> import { highlightVariables } from '@/common/utils/question-variable';

export default {
    name    : 'o-masked-input',
    props   : {
        autoFillBy     : {default: ''},
        errorsList     : {default: null},
        name           : {default: ''},
        removeUnderline: {default: false},
        margins        : {
            type   : String,
            default: '16px'
        },
        placeholder    : {type: String},
        directError    : {type: String},
        directErrorIf  : {type: Boolean},
        value          : {
            type   : [String, Number],
            default: ''
        },
        disabled       : {
            type   : Boolean,
            default: false
        },
        type           : {
            type   : String,
            default: 'text'
        },
        step           : {
            type   : String,
            default: '1'
        },
        transparent    : {
            type   : Boolean,
            default: false
        },
        autoFocus      : {
            type   : Boolean,
            default: false
        },
        showX          : {
            type   : Boolean,
            default: false
        },
        preventTyping  : {
            type   : Boolean,
            default: false
        }
    },
    data() {
        return {
            focused            : false,
            placeholderAsterisk: this.placeholder,
            localValue         : this.value,
            isLongError        : false
        };
    },
    watch   : {
        localValue(val) {this.$emit('input', val);},
        value(val) {this.localValue = val;},
        error() {
            setTimeout(() => this.isLongError = this.error && this.$el.querySelector('.input-error').scrollWidth > this.$el.querySelector(
                '.input-error').clientWidth);
        }
    },
    methods : {
        updateDivs() {
            this.$refs.inputElement && (this.$refs.inputElement.innerHTML = this.localValue);
            this.$refs.mask && (this.$refs.mask.innerHTML = this.maskHtml);
        },
        onInput(e) {
            let value       = e.target.textContent;
            this.localValue = value;
            this.focused    = true;
            this.$emit('input', value);
        },
        handleKeydownEvent(e) {
            this.killTyping(e);
            setTimeout(() => {
                if (this.$refs.mask) {
                    this.$refs.mask.scrollLeft = this.$refs.inputElement.scrollLeft;
                }
            });
            this.$emit('keydown', e);
        },
        killTyping(e) {
            if (this.preventTyping) {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        focus(e) {
            this.focused = true;
            this.$emit('focus', e);
        },
        clear() {this.showX && (this.localValue = '');},
        blur(e) {
            this.focused = false;
            this.$emit('blur', e, this.type === 'number' ? Number(this.localValue) : this.localValue);
            this.$emit('change', this.localValue);
        },
        doesInputHaveValue() {return this.$refs.inputElement && this.$refs.inputElement.value;}
    },
    model   : {
        prop : 'value',
        event: 'input'
    },
    computed: {
        error   : vm => vm.directErrorIf && vm.directError || (vm.name && vm.errorsList && vm.errorsList.has(vm.name) && vm.errorsList.first(
            vm.name)) || '',
        maskHtml: vm => highlightVariables(vm.localValue)
    },
    mounted() {
        this.updateDivs();
        this.autoFocus && this.$refs.inputElement.focus();
        this.$vnode.data.directives && this.$vnode.data.directives.some(d => d.name === 'validate' && (typeof d.value === 'string' ? d.value.indexOf(
            'required') !== -1 : 'required' in d.value)) && (this.placeholderAsterisk = this.placeholder + ' *');
    }
};</script>
<style lang="scss" scoped> %wrapper {
    display: flex;
    height: 35px;
    position: relative;
    border-bottom: 1px solid $GRAY_F0;
    box-sizing: content-box;

    &.disabled {
        pointer-events: none;
        opacity: .5;
    }

    .o-input {
        position: absolute;
        align-self: center;
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        outline: none;
        border: none;
        font-size: 14px;
        padding: 0;

        &.contenteditable {
            color: transparent;
            caret-color: $PRIMARY;
            height: 16.5px;
        }

        &.mask {
            pointer-events: none;
            height: 16.5px;
        }
    }

    &.floating::before {
        font-size: 12px;
        top: -12px;
        bottom: unset;
        transform: none;
    }

    &.focused::after {
        opacity: 1;
        transform: scaleX(1);
    }

    &::after {
        transition: transform .25s;
        will-change: transform;
        transform: scaleX(.1);
        border-bottom: 2px solid $PRIMARY;
        content: '';
        position: absolute;
        width: 100%;
        bottom: -1px;
        opacity: 0;
        z-index: 1;
    }

    &.new-composition-columns {
        margin: 0;
        height: 30px;
        width: 60%;
        float: left;

        &.right {
            float: right;
        }
    }

    .numberRight {
        text-align: end;
    }

    .stringLeft {
        text-align: start;
    }
}

.o-input-wrapper {
    @extend %wrapper;

    &::before {
        transition-duration: .25s;
        transition-property: all;
        content: attr(aria-placeholder);
        font-size: 16px;
        color: $GRAY_97;
        position: absolute;
        width: 100%;
        bottom: 50%;
        transform: translateY(calc(50% + 1px));
        pointer-events: none;
        font-weight: 400;
        text-align: start;
        z-index: 1;
    }

    &.focused::before {
        font-size: 13px;
        color: $PRIMARY;
    }

    .input-error {
        color: $WARN;
        font-size: 12px;
        position: absolute;
        left: 0;
        bottom: -18px;
        height: 14px;
        opacity: 0;
        pointer-events: none;
        white-space: nowrap;
        transform: translate3d(0, 0, 0);
        transition: opacity .5s;
        will-change: opacity;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        &.visible {
            pointer-events: all;
            opacity: 1;
        }
    }

    &.av-error, &.av-error.focused::after {
        border-bottom-color: $WARN;
    }

    &.av-error::before, &.av-error.focused::before {
        color: $WARN;
    }

    &.remove-underline {
        border-bottom: 0;

        &::after {
            visibility: hidden;
        }
    }
}

.av-prefix, .av-suffix {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $GRAY_8B;
}

.av-suffix button.icon-button {
    padding: 4px;
    color: $GRAY_8B;
}</style>
