import { DataForPeersModal } from '@/common/models/user/review-process/data-for-peers-modal';
import { PEER_STATUSES } from '@/common/models/user/review-process/peer-statuses';

export default {
    setModalData(state, modalData) {
        const peers        = modalData,
              peerSelected = peers.filter(peer => peer.peerRequestStatus !== PEER_STATUSES.notSelected);
        state.peersModal   = {
            peers,
            peerSelected
        }
    },
    addSelectedPeer(state, peerSelected) {
        state.peersModal.peerSelected.push(peerSelected);
    },
    removeSelectedPeer(state, index) {
        state.peersModal.peerSelected.splice(index, 1);
    },
    dropDataForAddPeersModal(state) {
        state.peersModal = new DataForPeersModal();
    },
    setSubordinatesForApproval(state, subordinatesForApproval) {
        state.subordinatesForApproval = {...subordinatesForApproval};
    },
    setPassedRequesterId(state, requesterId) {
        state.passedRequesterId = requesterId;
    },
    setExternalPeerTemplate(state, template) {
        state.externalPeerTemplate = template;
    },
    setRequesters(state, requesters) {
        state.requesters = {...requesters};
    },
    setTotalPeersLeft(state, totalPeers) {
        state.totalPeersLeft = totalPeers;
    },
    setIsCurrentEmployeeManager(state, isManager){
        state.isCurrentEmployeeManager = isManager;
    }
};
