import { TOUR_DATA } from '@/common/models/tour-data';

export const CLEAR_STORAGE_DATA = [
    'user.details',
    'tab',
    'coverState',
    'bannerState',
    'tab',
    'isEmployeeMode',
    'isAdminMode',
    'selectedUserId',
    TOUR_DATA.addTeamStep.name,
    TOUR_DATA.addAdminStep.name,
    TOUR_DATA.assignManagerStep.name
];
