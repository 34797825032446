export default {
    isNameUnique   : state => state.isNameUnique,
    team           : state => state.team,
    initialTeamName: state => state.initialTeamName,
    isTeamNameEdit : state => state.isTeamNameEdit,
    step           : state => state.step,
    addedToTeam    : state => state.addedToTeam,
    teamPageState  : state => state.teamPageState,
    teamsPage      : state => state.teamsPage
};
