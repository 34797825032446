import { Subdomain } from '@/common/auth/subdomain';

const KEY_CODES = {
    ARROW_UP  : 38,
    ARROW_DOWN: 40
};

export const buildRoutePattern = route => new RegExp('^' + route.replace(/:.+/, '.+').replace(/\//, '/') + '$');

export const prependSubdomainToEnvVar   = variable => process.env[variable].replace(
    process.env.VUE_APP_HOST_NAME,
    Subdomain.getSubdomain() + '.$&'
);
export const prependSubdomainToEnvVarWS = variable => `${process.env[variable].slice(0, 5)}${Subdomain.getSubdomain()}.${
    process.env[variable].slice(5)}`;
export const removeDuplicatesByProp     = (array, prop) => {
    return array.filter((item, index, arr) => arr.findIndex(innerItem => innerItem[prop] === item[prop]) === index);
};

export const isFormValid = (vm, formName) => {
    const fieldNames = Object.keys(vm.fields),
          form       = formName ? fieldNames.filter(f => f.indexOf('$' + formName) !== -1) : fieldNames;
    return form.every(f => (vm.fields[f].validated || vm.fields[f].valid) && vm.fields[f].valid);
};

const generateCssText = (...selectors) => {
    let cssText    = '';
    const computed = getComputedStyle(...selectors);
    const l        = computed.length;
    for (let i = 0; i < l; i++) {
        cssText += `${computed[i]}: ${computed[computed[i]]};`;
    }
    return cssText;
};

function toDataURL(src, callback, outputFormat) {
    let img         = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload      = function () {
        let canvas    = document.createElement('canvas'),
            ctx       = canvas.getContext('2d'),
            dataURL;
        canvas.height = this.naturalHeight;
        canvas.width  = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
    };
    img.src         = src;
}

const processPageCloneStyles = (originals, clones, classNamesToIgnore = []) => {
    originals.filter(el => !classNamesToIgnore.some(name => el.classList.contains(name))).forEach((el, i) => {
        clones[i].setAttribute('style', generateCssText(el));
        if (el.localName === 'img') {
            if (el.src.indexOf('user-avatar') !== -1) {
                clones[i].removeAttribute('src');
            }
            toDataURL(
                clones[i].src,
                function (dataUrl) {
                    clones[i].src = dataUrl;
                }
            );
        }
        if (el.children) {
            processPageCloneStyles(Array.from(el.children), Array.from(clones[i].children), classNamesToIgnore);
        }
        if (getComputedStyle(el, '::before').content !== 'none') {
            const before = document.createElement('div');
            before.setAttribute('style', generateCssText(el, '::before'));
            if (clones[i].children) {
                clones[i].insertBefore(before, clones[i].firstElementChild);
            } else {
                clones[i].appendChild(before);
            }
            before.innerText = getComputedStyle(el, '::before').content.slice(1, -1);
        }
        if (getComputedStyle(el, '::after').content !== 'none') {
            const after = document.createElement('div');
            after.setAttribute('style', generateCssText(el, '::after'));
            clones[i].appendChild(after);
            after.innerText = getComputedStyle(el, '::after').content.slice(1, -1);
        }
        if (el.value) {
            const input = document.createElement('div');
            input.setAttribute('style', clones[i].getAttribute('style'));
            input.innerText = el.value;
            clones[i].replaceWith(input);
        }
    });
};

export const takePageScreenshot = (handlerFn, classNamesToIgnore = []) => {
    const app             = document.body,
          appClone        = app.cloneNode(true),
          {height, width} = app.getBoundingClientRect();

    processPageCloneStyles([app], [appClone], classNamesToIgnore);
    appClone.style.backgroundColor = '#FFF';

    const resultCanvas  = document.createElement('canvas'),
          resultCtx     = resultCanvas.getContext('2d');
    resultCanvas.width  = width;
    resultCanvas.height = height;

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', `${width}`);
    svg.setAttribute('height', `${height}`);

    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
    foreignObject.setAttribute('width', '100%');
    foreignObject.setAttribute('height', '100%');

    svg.appendChild(foreignObject);
    foreignObject.appendChild(appClone);

    setTimeout(() => {
        const img  = new Image();
        img.onload = () => {
            resultCtx.drawImage(img, 0, 0);
            handlerFn(resultCanvas.toDataURL('image/png', 1));
        };
        img.src    = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svg))));
    }, 200);
};

export const handleSlider = (e, {min, max, step}) => {
    if (e.key.length === 1 && (e.target.value.length >= String(max).length || parseInt(e.target.value + e.key) > max || !/^[0-9]$/.test(e.key))) {
        e.preventDefault();
        e.stopPropagation();
    } else if (e.target.value) {
        let direction     = 0;
        const parsedValue = parseInt(e.target.value);
        e.which === KEY_CODES.ARROW_UP && (direction = parsedValue + step <= max ? step : max - parsedValue);
        e.which === KEY_CODES.ARROW_DOWN && (direction = -1 * (parsedValue - step >= min ? step : parsedValue - min));
        e.target.value = parsedValue + direction;
        e.target.dispatchEvent(new Event('input'));
    }
};

export const handleSliderBlur = (e, {min}) => {
    const parsedValue = parseInt(e.target.value);
    parsedValue < min && (e.target.value = min);
    e.target.dispatchEvent(new Event('input'));
};

export const handlePaste = (e, {min, max}) => {
    e.preventDefault();
    e.stopPropagation();
    try {
        let clipboardValue = e.clipboardData.getData('Text'),
            numberValue    = parseInt(clipboardValue);
        if (!isNaN(numberValue)) {
            if (numberValue > max) {
                e.target.value = max;
            } else if (numberValue < min) {
                e.target.value = min;
            } else {
                e.target.value = numberValue;
            }
            e.target.dispatchEvent(new Event('input'));
        }
    } catch (e) {
        console.error(e);
    }
};

export const isNotNumberKey = e => e.key.length === 1 && !/\d/.test(e.key);

export const getForbiddenEmailProviders = () => process.env.VUE_APP_FORBIDDEN_EMAIL_PROVIDERS
    .split(',')
    .filter(function (p) { return p;})
    .map(function (p) { return p.trim();});

export const getNullableRequestParams = obj => Object.keys(obj)
    .map(prop => `${prop}=${obj[prop] || ''}`)
    .reduce((params, current, index) => params + (index ? `&` : '?') + current, '');

export function setTourToLocalStorage(tour) {
    localStorage.setItem(tour, `${true}`);
}

export function getTourFromLocalStorage(tour) {
    return localStorage.getItem(tour);
}

export function changeCommentStyle(store) {
    let top = (document.querySelector('.banner') && !(localStorage.getItem('bannerState') === 'closed')) ?
        '100px' : '60px';
    store.commit('setCommentStyle', `top: ${top}; height: calc(100% - ${top})`);
}

export function setStylesStepToLocalStorage(selector, itemName) {
    setTimeout(() => {
        const tour          = document.querySelector(selector),
              tourTransform = tour.style.transform;
        localStorage.setItem(itemName, tourTransform);
    }, 1000);
}

export function observeStyleChanges(target) {
    const targetEl = document.querySelector(target.selector),
          styles   = localStorage.getItem(target.name),
          config   = {
              attributes: true
          },
          callback = function (mutationsList) {
              for (let mutation of mutationsList) {
                  if (mutation.type === 'attributes') {
                      targetEl.style.transform = styles;
                  }
              }
          };
    const observer = new MutationObserver(callback);
    observer.observe(targetEl, config);
}

export function getMode() {
    const getItem    = itemKey => JSON.parse(localStorage.getItem(itemKey));
    const isEmployee = getItem('isEmployeeMode');
    const isAdmin    = getItem('isAdminMode');
    const isOwner    = getItem('user.details').roles.indexOf('ROLE_COMPANY_OWNER') !== -1;
    const modes      = {
        employee: 'EMPLOYEE',
        admin   : 'ADMIN'
    };
    if (isOwner) {
        return isEmployee ? modes.employee : modes.admin;
    }
    return isAdmin ? modes.admin : modes.employee;
}


export function setHasSeenAllMessages (hasSeenAllMessages) {
    localStorage.setItem('hasSeenAllMessages', hasSeenAllMessages);
}

export function getHasSeenAllMessages () {
    return localStorage.getItem('hasSeenAllMessages') === 'true';
}
