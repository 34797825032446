export default {
    plans                  : paymentState => paymentState.plans,
    invoices               : paymentState => paymentState.invoices,
    creditCards            : paymentState => paymentState.creditCards,
    subscription           : paymentState => paymentState.subscription,
    creditCardData         : paymentState => paymentState.creditCardData,
    planToChange           : paymentState => paymentState.planToChange,
    isPricingPage          : paymentState => paymentState.isPricingPage,
    isYearlyPriceToggled   : paymentState => paymentState.isYearlyPriceToggled,
    quantityOfUsers        : paymentState => paymentState.plans.quantityOfUsers,
    tabIndex               : paymentState => paymentState.tabIndex,
    isSubscriptionPurchased: paymentState => paymentState.isSubscriptionPurchased
};
