export const TOUR_DATA = {
    addTeamStep      : {
        selector     : '#v-step-46f34ed6',
        name         : 'styleTeam',
        arrowPosition: 'left',
        value        : '84'
    },
    addAdminStep     : {
        selector     : '#v-step-46f34ed4',
        name         : 'styleAdmin',
        arrowPosition: 'left',
        value        : '108'
    },
    assignManagerStep: {
        selector     : '#v-step-599c7bb3',
        name         : 'styleAssignManager',
        arrowPosition: 'top',
        value        : '32'
    }
};

export const ORGANIZATION_TOURS = {
    isStartTeamTour    : 'isStartTeamTour',
    isStartAddTeamsTour: 'isStartAddTeamsTour',
    isStartAddAdminTour: 'isStartAddAdminTour'
};
