export const parentChildRelation = {
    methods: {
        emitToParent(prop, value) {
            this.$emit(
                'change',
                {
                    prop,
                    value
                }
            );
        },
        onChildChange(targetObject, {prop, value}) {
            this.$set(targetObject, prop, value);
        }
    }
};
