export const ReviewTemplate = function (name, reviewType) {
    this.reviewType  = reviewType;
    this.name        = name;
    this.id          = null;
    this.lastUpdated = '';
    this.groups      = [];
};

export const Group = function (groupIndex, groupName, components = [], isVisible = true, id = null, mandatory = false) {
    this.groupIndex = groupIndex;
    this.groupName  = groupName;
    this.components = components;
    this.isVisible  = isVisible;
    this.mandatory  = mandatory;
    this.id         = id;
};

export const List = function (index, templateControl, settings) {
    this.index           = index;
    this.id              = null;
    this.componentType   = 'LIST';
    this.possibleValues  = Array(2).fill('');
    this.question        = {
        isMultiQuestion: false,
        MANAGER_REVIEW : '',
        PEER_REVIEW    : '',
        SELF_REVIEW    : ''
    };
    this.required        = true;
    this.templateControl = templateControl;
    this.settings        = settings || {
        min : 0,
        max : 10,
        step: 1
    };
    this.values          = [];
};

export const SingleValue = function (index, templateControl, possibleValues, settings) {
    this.index           = index;
    this.id              = null;
    this.componentType   = 'SINGLE_VALUE';
    this.possibleValues  = possibleValues || [];
    this.question        = {
        isMultiQuestion: false,
        MANAGER_REVIEW : '',
        PEER_REVIEW    : '',
        SELF_REVIEW    : ''
    };
    this.required        = true;
    this.templateControl = templateControl;
    this.settings        = settings || {
        min : 0,
        max : 10,
        step: 1
    };
    this.values          = [];
};

export const compareStrings = (str1, str2) => {
    return str1.trim().toLowerCase() === str2.trim().toLowerCase();
};

export const ControlType = {
    CHECKBOX    : 'CHECKBOX',
    RADIOBUTTON : 'RADIOBUTTON',
    INPUT       : 'INPUT',
    SELECT      : 'SELECT',
    EXPECTATIONS: 'EXPECTATIONS',
    RATING      : 'RATING',
    NPS         : 'NPS',
    SLIDER      : 'SLIDER',

    isCheckbox    : type => type === ControlType.CHECKBOX,
    isRadioButton : type => type === ControlType.RADIOBUTTON,
    isInput       : type => type === ControlType.INPUT,
    isSelect      : type => type === ControlType.SELECT,
    isExpectations: type => type === ControlType.EXPECTATIONS,
    isRating      : type => type === ControlType.RATING,
    isNPS         : type => type === ControlType.NPS,
    isSlider      : type => type === ControlType.SLIDER
};

export const getIconNameByControlType = controlType => {
    let iconName = '';
    switch (controlType) {
        case ControlType.CHECKBOX:
        case ControlType.RADIOBUTTON:
        case ControlType.EXPECTATIONS:
            iconName = 'format_list_bulleted';
            break;

        case ControlType.INPUT:
        case ControlType.SELECT:
            iconName = 'text_fields';
            break;

        case ControlType.RATING:
        case ControlType.NPS:
        case ControlType.SLIDER:
            iconName = 'star';
            break;
        default:
            // do nothing
    }

    return iconName;
};
