import { CookieManager } from '@/common/auth/cookie.manager';
import { SubscriptionStatus } from '@/common/misc';
import { CreditCardData } from './payments-state';

export default {
    setPlansObject(state, plansResponse) {
        state.plans  = {...plansResponse};
        const oneDay = 24 * 60 * 60 * 1000;
        state.plans.plans.sort((p1, p2) => p1.features.length - p2.features.length);
        if (state.plans.plans.some(plan => plan.currentPlan)) {
            let currentPlan   = state.plans.plans.find(plan => plan.currentPlan);
            let trialsEndDay  = currentPlan.subscriptionEndDate;
            let currentTime   = new Date().getTime();
            let trialEndsTime = new Date(trialsEndDay).getTime();

            currentPlan.daysLeft = Math.ceil((trialEndsTime - currentTime) / oneDay);
        }
    },
    initSubscriptionStatus(state) {
        state.isSubscriptionPurchased =
            CookieManager.getCookie('subscriptionStatus') === SubscriptionStatus.ACTIVE ||
            CookieManager.getCookie('subscriptionStatus') === SubscriptionStatus.CANCELLED
        ;
    },
    setSubscriptionData(state, currentSubscription) {
        state.subscription = currentSubscription;
    },
    setInvoicesData(state, invoices) {
        state.invoices = invoices;
        state.invoices.totalPages = Math.ceil(state.invoices.totalElements / 25);
    },
    setCreditCards(state, creditCards) {
        state.creditCards = creditCards;
    },
    passCurrentPlan(state, passedPlan) {
        state.planToChange = passedPlan;
    },
    setPricingPageState(state, isOpen) {
        state.isPricingPage = isOpen;
    },
    toggleYearlyPrice(state) {
        state.isYearlyPriceToggled = !state.isYearlyPriceToggled;
    },
    setInfoForPricing(state, info) {
        state.isYearlyPriceToggled  = info.isYearlyToggled;
        state.plans.quantityOfUsers = info.quantityOfUsers;
    },
    setQuantityOfUsers(state, quantity) {
        state.plans.quantityOfUsers = quantity;
    },
    setBillingTabIndex(state, tabIndex) {
        state.tabIndex = tabIndex;
    },
    resetCreditCardData(state) {
        state.creditCardData = new CreditCardData();
    },
    resetPlanToChangeData(state){
        state.planToChange = null;
    }
};
