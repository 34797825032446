import NOTIFICATION_INSTANCE from '../notification.vue';
import store from '@/store/store';

export const Notify = {
    add(notification) {
        NOTIFICATION_INSTANCE.$children[0].notifications.unshift(notification);
        store.commit('setSeenAllMessages', false);
    },

    get count() {
        return NOTIFICATION_INSTANCE.$children[0].notifications.length;
    },

    get notifications() {
        return [...NOTIFICATION_INSTANCE.$children[0].notifications];
    },

    set notifications(list) {
        NOTIFICATION_INSTANCE.$children[0].notifications = [...list];
    },

    removeByIds(ids) {
        NOTIFICATION_INSTANCE.$children[0].notifications = NOTIFICATION_INSTANCE.$children[0].notifications.filter(n => ids.indexOf(n.id) === -1);
    },

    ok(message, duration = 5000) {
        NOTIFICATION_INSTANCE.$children[0].ok(message, duration);
    },

    error(message, duration = 5000) {
        NOTIFICATION_INSTANCE.$children[0].error(message, duration);
    },

    warning(message, duration = 5000) {
        NOTIFICATION_INSTANCE.$children[0].warning(message, duration);
    }

};
