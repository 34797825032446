import { Page } from '@/common/models/page';

export const COLORS = [
    '#7C4DFF', '#EC407A', '#1DE9B6', '#26C6DA', '#FF7043', '#CBCBCB', '#FFB5FF', '#FFC7B9', '#FFAE46',
    '#80C3FF', '#54E2F3', '#E2E04A', '#E1E1E1', '#FFB4FF', '#F5C2FF', '#FF9E87', '#7FEB72', '#FF9F37',
    '#95D7FF', '#53E0F2', '#DDDC46', '#DADADA', '#FFAAF6', '#EABAF0', '#FFD2C6', '#72DE66', '#FFA63E',
    '#62A8E9', '#46D7E9', '#DCDB45', '#DCDCDC', '#FFB3FF', '#D1A5E0', '#FF9E87', '#91EB85', '#7BBFFF',
    '#58E5F6', '#E0DF49', '#E6E6E6', '#FFA9F6', '#7BE76F', '#81C4FF', '#58E4F6', '#DEDD47', '#D6D6D6',
    '#FFB6FF', '#FFA089', '#80EC73', '#8CCEFF', '#49D9EB', '#DFDE48', '#CBCBCB', '#FFC3FF', '#FFA18A',
    '#74E068'
];

export const subordinateForApprovalInit = {
    employeeDto              : {
        email: '',
        image: ''
    },
    peerRequestsApproveStatus: null,
    requestedEmployees       : new Page()
};

export const getRandomColor = () => COLORS[(Math.floor(Math.random() * COLORS.length) + 1) - 1];

export const SubscriptionStatus = {
    ACTIVE         : 'ACTIVE',
    INACTIVE       : 'INACTIVE',
    CANCELLED      : 'CANCELLED',
    NO_SUBSCRIPTION: 'NO_SUBSCRIPTION'
};

export const userPermissions = {
    ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
    BILLING_ADMIN     : 'BILLING_ADMIN',
    REVIEW_ADMIN      : 'REVIEW_ADMIN',
    ANALYTIC_ADMIN    : 'ANALYTIC_ADMIN'
};

export const METRICS = [
    {description: 'ARR'},
    {description: 'Booked Appointments'},
    {description: 'CAC'},
    {description: 'Custom Goal'},
    {description: 'Completed Demos'},
    {description: 'Cost of Goods Sold'},
    {description: 'Critical Bugs'},
    {description: 'Gross Margin'},
    {description: 'Inbound Leads'},
    {description: 'LTV/CAC'},
    {description: 'Market Share'},
    {description: 'MRR'},
    {description: 'Net Margin'},
    {description: 'Net Profit'},
    {description: 'Net Promoter Score'},
    {description: 'New Recurring Revenue'},
    {description: 'New Revenue'},
    {description: 'NPS'},
    {description: 'Performance'},
    {description: 'Profit/Loss'},
    {description: 'Referral Rate'},
    {description: 'Retention Rate'},
    {description: 'Revenue'},
    {description: 'Sales Qualified Leads'},
    {description: 'Satisfaction Score'},
    {description: 'Sign Ups'},
    {description: 'Stock Price'},
    {description: 'Up Time'}
];

export const PERIODS = [
    'Q1', 'Q2', 'Q3', 'Q4', 'FY'
];

export const TIMEZONES = [
    'GMT+01:00',
    'GMT+02:00',
    'GMT+03:00',
    'GMT+04:00',
    'GMT+05:00',
    'GMT+06:00',
    'GMT+07:00',
    'GMT+08:00',
    'GMT+09:00',
    'GMT+10:00',
    'GMT+11:00',
    'GMT+12:00',
    'GMT-12:00',
    'GMT-11:00',
    'GMT-10:00',
    'GMT-09:00',
    'GMT-08:00',
    'GMT-07:00',
    'GMT-06:00',
    'GMT-05:00',
    'GMT-04:00',
    'GMT-03:00',
    'GMT-02:00',
    'GMT-01:00'
];

export const userHasRole = (user, role) => user.roles && user.roles.some(r => r === role);

export const isProduction = !!process.env.VUE_APP_INSTANCE;
