<template>
  <div @click="disabled ? false : focus(); $emit('click', $event)" :aria-placeholder="placeholderAsterisk"
       :style="{margin: margins}"
       :class="['o-input-wrapper', {'focused': focused, 'floating': focused || localValue || doesInputHaveValue(), disabled,'remove-underline':removeUnderline, 'av-error': error, 'overflowHide': showX}]">

    <label :for="'input'+_uid" :class="['av-prefix', isInternal ? 'pseudo' : '']" >
      <slot name="avPrefix"></slot>
    </label><input ref="inputElement" class="o-input" :style="{backgroundColor: transparent ? 'transparent' : '#FFF'}"
                   :autocomplete="autocomplete" :class="{numberRight: type === 'number', stringLeft: type === 'text'}" :type="type"
                   :step="step" :disabled="disabled" :name="autoFillBy" :id="'input'+_uid"  :maxlength="maxLength" @focus="focus" @blur="blur"
                   @keydown.esc="clear" @keydown="handleKeydownEvent" @keyup="killTyping" @keypress="killTyping"
                   @keypress.enter="onEnterPress" v-model="localValue">
    <div class="before" :style="isRelative ? {position: 'relative', right: '230px'} : ''">{{ placeholderAsterisk }}</div>
    <span class="av-suffix"><o-button v-if="showX && localValue" @click.prevent.stop="clear" icon><o-icon name="clear"/></o-button><slot
        name="avSuffix"></slot></span><span :class="['input-error', {'visible': error}]"><span class="error-text" v-if="isLongError"
                                                                                                  v-tooltip.below="error">{{ error }}</span><span
      class="error-text" v-else>{{ error }}</span></span></div>
</template>
<script> export default {
  name    : 'o-input',
  props   : {
    autocomplete   : {default: 'none'},
    autoFillBy     : {default: ''},
    errorsList     : {default: null},
    name           : {default: ''},
    removeUnderline: {default: false},
    margins        : {
      type   : String,
      default: '16px'
    },
    placeholder    : {type: String},
    directError    : {type: String},
    directErrorIf  : {type: Boolean},
    value          : {
      type   : String | Number,
      default: ''
    },
    disabled       : {
      type   : Boolean,
      default: false
    },
    type           : {
      type   : String,
      default: 'text'
    },
    isRelative     : {
      type   : Boolean,
      default: false
    },
    step           : {
      type   : String,
      default: '1'
    },
    transparent    : {
      type   : Boolean,
      default: false
    },
    autoFocus      : {
      type   : Boolean,
      default: false
    },
    showX          : {
      type   : Boolean,
      default: false
    },
    preventTyping  : {
      type   : Boolean,
      default: false
    },
    maxLength      : {
      type   : Number,
      default: null
    },
    cardData       : {
      type   : Boolean,
      default: false
	}, 
	isInternal:{
		type   : Boolean,
      default: false
	}
  },
  data() {
    return {
      focused            : false,
      placeholderAsterisk: this.placeholder,
      localValue         : this.value,
      isLongError        : false
    };
  },
  watch   : {
    localValue(val) {this.$emit('input', val);},
    value(val) {this.localValue = val;},
    error() {
      setTimeout(() => this.isLongError = this.error && this.$el.querySelector('.input-error').scrollWidth > this.$el.querySelector(
          '.input-error').clientWidth);
    }
  },
  methods : {
    handleKeydownEvent(e) {
      this.killTyping(e);
      this.$emit('keydown', e);
    },
    killTyping(e) {
      if (this.preventTyping) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    focus(e) {
      this.focused = true;
      this.$emit('focus', e);
    },
    clear() {this.showX && (this.localValue = '');},
    blur(e) {
      this.focused = false;
      this.$emit('blur', e, this.type === 'number' ? Number(this.localValue) : this.localValue);
      this.$emit('change', this.localValue);
    },
    doesInputHaveValue() {return this.$refs.inputElement && this.$refs.inputElement.value;},
    onEnterPress(e) {this.$emit('enter', e.target.value);},
    formatCardCode() {
      let cardData    = this.localValue.replace(/[^\d]/g, '').substring(0, 16);
      cardData        = cardData !== '' ? cardData.match(/.{1,4}/g).join(' ') : '';
      this.localValue = cardData;
    }
  },

  computed: {
    model: {get() {return this.localValue;}},
    error: vm => vm.directErrorIf && vm.directError || (vm.name && vm.errorsList && vm.errorsList.has(vm.name) && vm.errorsList.first(vm.name)) || ''
  },

  mounted() {
    this.autoFocus && this.$refs.inputElement.focus();
    this.$vnode.data.directives && this.$vnode.data.directives.some(d => d.name === 'validate' && (typeof d.value === 'string' ? d.value.indexOf(
        'required') !== -1 : 'required' in d.value)) && (this.placeholderAsterisk = this.placeholder + ' *');
    this.$refs.inputElement.addEventListener('paste', e => this.$emit('paste', e));
    if (this.cardData) {
      this.$refs.inputElement.addEventListener('input', this.formatCardCode);
    }
  },

  beforeDestroy() {
    this.$refs.inputElement.removeEventListener('input', this.formatCardCode);
  }
};</script>
<style lang="scss" scoped>
    $placeholder-font-size: 12px;
    .o-input-wrapper {
        display: flex;
        height: 35px;
        position: relative;
        border-bottom: 1px solid $GRAY_F0;
        box-sizing: content-box;
		
  > .before {
    transition-duration: .25s;
    transition-property: all;
    font-size: 16px;
    color: $GRAY_97;
    position: absolute;
    width: 100%;
    bottom: 50%;
    transform: translateY(calc(50% + 1px));
    pointer-events: none;
    font-weight: 400;
    text-align: start;
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  .o-input {
    outline: none;
    border: none;
    height: 35px;
    font-size: 14px;
    padding: 0;
    width: 100%;
    display: flex;
	align-items: center;
  }

  &.floating > .before {
    font-size: $placeholder-font-size;
    top: -12px;
    bottom: unset;
    transform: none;
  }

  @media (-webkit-device-pixel-ratio) {
    .o-input:-internal-autofill-selected ~ .before {
      font-size: $placeholder-font-size;
      top: -12px;
      bottom: unset;
      transform: none;
    }
  }

  &::after {
    transition: transform .25s;
    will-change: transform;
    transform: scaleX(.1);
    border-bottom: 2px solid $PRIMARY;
    content: '';
    position: absolute;
    width: 100%;
    bottom: -1px;
    opacity: 0;
    z-index: 1;
  }

  &.focused::after {
    opacity: 1;
    transform: scaleX(1);
  }

  .numberRight {
    text-align: end;
  }

  .stringLeft {
    text-align: start;
  }

  &.focused > .before {
    font-size: $placeholder-font-size;
    color: $PRIMARY;
  }

  .input-error {
    color: $WARN;
    font-size: $placeholder-font-size;
    position: absolute;
    left: 0;
    bottom: -18px;
    height: 14px;
    opacity: 0;
    pointer-events: none;
    white-space: nowrap;
    transform: translate3d(0, 0, 0);
    transition: opacity .5s;
    will-change: opacity;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    &.visible {
      pointer-events: all;
      opacity: 1;
    }
  }

  &.av-error, &.av-error.focused::after {
    border-bottom-color: $WARN;
  }

  &.av-error > .before, &.av-error.focused > .before {
    color: $WARN;
  }

  &.remove-underline {
    border-bottom: 0;

    &::after {
      visibility: hidden;
    }
  }
}

.av-prefix, .av-suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $GRAY_8B;
}

.pseudo{
	&::after{
	    content: "";
    width: 170px;
    height: 45px;
    display: block;
    position: absolute;
    top: 0;
    left: 30px;
    background: transparent;
    cursor: text;
	}
}

.overflowHide{
	overflow: hidden;
}

.av-suffix button.icon-button {
  padding: 4px;
  color: $GRAY_8B;
}</style>
