import { HTTP } from '@/common/utils/http';
import { GoalsFilter } from '@/common/models/goals-filter';

export default {
    state    : {
        commentStyle: ''
    },
    actions  : {
        async addComment({dispatch}, {goalId, message}) {
            await HTTP.post(`/goals/${goalId}`, message);
            dispatch('getGoals', new GoalsFilter());
        }
    },
    mutations: {
        setCommentStyle(state, style) {
            state.commentStyle = style;
        }
    },
    getters  : {
        commentStyle: store => store.commentStyle
    }
};
