<template>
    <div>
        <o-input type="text" v-model="inputValue" :direct-error-if="directErrorIf" :direct-error="directError" :prevent-typing="true"
                 :placeholder="placeholder" :disabled="disabled">
            <div slot="avSuffix">
                <o-icon name="date_range"/>
            </div>
        </o-input>
        <o-menu>
            <div class="date-picker">
                <div class="date-picker__header">
                    <o-button ripple @click="changeState">{{buttonLabel}}</o-button>
                    <div class="date-picker__header__page-actions">
                        <o-button icon @click="prev">
                            <o-icon name="navigate_before"/>
                        </o-button>
                        <o-button icon @click="next">
                            <o-icon name="navigate_next"/>
                        </o-button>
                    </div>
                </div>
                <div class="date-picker__body">
                    <table v-if="state === 3">
                        <thead>
                        <tr>
                            <th>MO</th>
                            <th>TU</th>
                            <th>WE</th>
                            <th>TH</th>
                            <th>FR</th>
                            <th>SA</th>
                            <th>SU</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row,ri) in rows" :key="ri">
                            <td v-for="(cell, ci) in row" :key="ci" :class="{'disabled-day': disabledDay('DAY', cell)}">
                                <o-button v-if="cell" icon @click="select(cell); closeDP()" :class="['day', {selected: day === cell}]">
                                    {{cell}}
                                </o-button>
                                <span v-else>{{cell}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                    <table v-else-if="state === 1">
                        <thead>
                        <tr>
                            <th colspan="4"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(yearRow,ri) in yearRows" :key="ri">
                            <td v-for="(cell, ci) in yearRow" :key="ci" :class="{'disabled-day': disabledDay('YEAR', cell)}">
                                <o-button v-if="cell" icon @click="select(cell)" :class="['day', {selected: year === cell}]">{{cell}}
                                </o-button>
                                <span v-else>{{cell}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                    <table v-else>
                        <thead>
                        <tr>
                            <th colspan="4"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(monthRow,ri) in monthRows" :key="ri">
                            <td v-for="(cell, ci) in monthRow" :key="ci" :class="{'disabled-day': disabledDay('MONTH', cell)}">
                                <o-button v-if="cell" icon @click="select(cell)" :class="['day', {selected: selectedMMM === cell}]">
                                    {{cell}}
                                </o-button>
                                <span v-else>{{cell}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </o-menu>
    </div>
</template>
<script> import { DD, MM, MMM, ODate } from '@/common/utils/date';

export default {
    name    : 'o-date-picker',
    props   : {
        value        : {default: () => new Date()},
        pattern      : {default: 'MM/DD/YYYY'},
        placeholder  : {default: ''},
        disabled     : {default: false},
        min          : {default: null},
        directError  : {type: String},
        directErrorIf: {type: Boolean}
    },
    data() {
        return {
            now         : null,
            year        : null,
            month       : null,
            day         : null,
            state       : 3,
            inputValue  : null,
            dayInMillis : 86400000,
            state3Button: null,
            state1Button: null,
            rows        : [],
            yearRows    : [],
            monthRows   : MMM.reduce((res, n, i, arr) => i % 4 ? res : [...res, arr.slice(i, i + 4)], [])
        };
    },
    created() {
        this.now          = this.value ? new Date(this.value) : new Date();
        this.year         = this.now.getFullYear();
        this.day          = this.now.getDate();
        this.month        = this.now.getMonth();
        this.state3Button = ODate.getFormattedDate(new Date(this.year, this.month), 'MMM YYYY');
        this.setInputValue();
    },
    watch   : {
        inputValue(val) {
            let newDate = new Date(val);
            if (newDate instanceof Date && !isNaN(newDate.getTime())) {
                let maxDate = new Date(Math.max(new Date(newDate).getTime(), new Date(this.min).getTime()));
                this.year   = maxDate.getFullYear();
                this.month  = maxDate.getMonth();
                this.day    = maxDate.getDate();
                this.updateAll();
            }
        },
        value(val) { this.inputValue = ODate.getFormattedDate(val, this.pattern); }
    },
    methods : {
        next() {
            if (this.state === 3) {
                this.day = 1;
                if (this.month === 11) {
                    this.year++;
                    this.month = 0;
                } else {
                    this.month++;
                }
            } else if (this.state === 1) {
                const startYear   = this.yearRows[5][3] + 1;
                this.yearRows     = new Array(24).fill('')
                    .map((n, i) => (startYear) + i)
                    .reduce((res, n, i, arr) => i % 4 ? res : [...res, arr.slice(i, i + 4)], []);
                this.state1Button = `${startYear}-${startYear + 23}`;
            } else {
                this.year++;
            }
            this.setInputValue();
        },
        prev() {
            if (this.state === 3) {
                if (!this.disabledDay('MONTH', this.month - 1) || new Date(
                    this.year,
                    this.month - 1,
                    ODate.getMaxDaysInMonth(new Date(this.year, this.month - 1, this.day))
                ).getTime() >= new Date(this.min).getTime()) {
                    this.day = ODate.getMaxDaysInMonth(new Date(this.year, this.month - 1, this.day));
                    if (this.month === 0) {
                        this.year--;
                        this.month = 11;
                    } else {
                        this.month--;
                    }
                }
            } else if (this.state === 1) {
                if (!this.disabledDay('YEAR', this.year - 1) || this.year < this.yearRows[0][0]) {
                    const startYear   = this.yearRows[0][0] - 24;
                    this.yearRows     = new Array(24).fill('')
                        .map((n, i) => (startYear) + i)
                        .reduce((res, n, i, arr) => i % 4 ? res : [...res, arr.slice(i, i + 4)], []);
                    this.state1Button = `${startYear}-${startYear + 23}`;
                }
            } else {
                if (!this.disabledDay('YEAR', this.year - 1)) {
                    this.year--;
                }
            }
            this.setInputValue();
        },
        select(value) {
            if (this.state === 3) {
                this.day = value;
            } else if (this.state === 1) {
                this.year  = value;
                this.state = 2;
            } else {
                this.state = 3;
                this.month = MMM.indexOf(value);
            }
            this.setInputValue();
        },
        closeDP() {
            const dp = document.querySelector('.o-menu.open');
            dp && dp.previousElementSibling.click();
        },
        setInputValue() {
            this.inputValue = ODate.getFormattedDate(new Date(this.year, this.month, this.day), this.pattern);
            this.$emit('change', ODate.getFormattedDate(this.inputValue, this.pattern));
        },
        updateAll() {
            let realYear  = new Array(4 - (this.year + '').length).fill('0').join('') + this.year;
            const newDate = new Date(`${realYear}-${MM[this.month]}-${DD[this.day - 1]}`);
            if (newDate instanceof Date && !isNaN(newDate.getTime())) {
                const daysInMonth   = ODate.getMaxDaysInMonth(newDate);
                const monthStartDay = ODate.getFirstDayOfMonth(newDate);
                const cellsAtTheEnd = Math.ceil((daysInMonth + monthStartDay - 1) / 7) * 7 - (daysInMonth + monthStartDay - 1);
                this.rows           = new Array(monthStartDay - 1).fill('')
                    .concat(new Array(daysInMonth).fill('').map((n, i) => i + 1))
                    .concat(new Array(cellsAtTheEnd).fill(''))
                    .reduce((res, n, i, arr) => i % 7 ? res : [...res, arr.slice(i, i + 7)], []);
                this.state3Button   = ODate.getFormattedDate(newDate, 'MMM YYYY');
            }
        },
        changeState() {
            if (this.state === 3) {
                this.yearRows     = new Array(24).fill('')
                    .map((n, i) => (this.year - 2) + i)
                    .reduce((res, n, i, arr) => i % 4 ? res : [...res, arr.slice(i, i + 4)], []);
                this.state1Button = `${this.year - 2}-${this.year - 2 + 23}`;
                this.state        = 1;
            } else if (this.state === 1) {
                this.state = 3;
            } else {
                this.state = 3;
            }
        },
        disabledDay(type, value) {
            let result = false;
            switch (type) {
                case 'YEAR':
                    result = value < new Date().getFullYear();
                    break;
                case 'MONTH':
                    result = new Date(this.year, MMM.indexOf(value), this.day).getMonth() < new Date().getMonth();
                    break;
                case 'DAY':
                    result = new Date(this.year, this.month, value).getTime() < new Date().getTime() - this.dayInMillis;
                    break;
            }
            return result;
        }
    },
    computed: {
        buttonLabel: vm => vm.state === 3 ? vm.state3Button : vm.state === 2 ? vm.year : vm.state1Button,
        selectedMMM: vm => MMM[vm.month]
    },
    model   : {
        prop : 'value',
        event: 'change'
    }
};</script>
<style lang="scss" scoped> .date-picker {
    width: 280px;
    height: 320px;

    &__header {
        padding: 8px 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__page-actions {
            display: flex;
            align-items: center;
        }
    }

    &__body {
        table {
            border-collapse: collapse;
            width: 100%;

            thead tr {
                border-bottom: 1px solid $CLOUDY_BLUE;

                th {
                    font-size: 11px;
                    font-weight: 400;
                    color: $BLACK_38;
                    padding-bottom: 8px;
                }
            }

            tbody tr {
                td {
                    font-size: 13px;
                    font-weight: 400;
                    color: $BLACK_87;
                    text-align: center;
                    height: 40px;
                    width: 40px;
                    box-sizing: border-box;
                    padding: 0;

                    &.disabled-day {
                        pointer-events: none;
                        opacity: .5;
                    }
                }
            }
        }
    }
}

.day {
    height: 36px !important;
    width: 36px !important;
    padding: 0;
    margin: 0 auto;

    &.selected {
        background-color: $PRIMARY !important;
        color: $WHITE;
    }
}

.o-input-wrapper::before {
    font-size: 12px !important;
    top: -12px !important;
    bottom: unset !important;
    transform: none !important;
}</style>
