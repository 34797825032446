<template>
    <div @click="$emit('click')">
        <div v-if="!src" class="initials" :style="{width: size + 'px', height: size + 'px', backgroundColor: color, fontSize: (size / 2 % 2 ? size / 2 - 5 : size / 2 - 4) + 'px',
                border: '1px solid' + borderColor}">{{l1 + l2}}
        </div>
        <object v-else :width="size" :height="size" :data="src" class="initials"
                :style="{width: size + 'px', height: size + 'px', backgroundColor: color, fontSize: (size / 2 % 2 ? size / 2 - 5 : size / 2 - 4) + 'px',
                border: '1px solid' + borderColor}">
            {{l1 + l2}}
        </object>
    </div>
</template>
<script> import { COLORS } from '@/common/misc';

export default {
    name    : 'o-user-image',
    props   : {
        size: {
            type   : Number,
            default: 50
        },
        user: {}
    },
    computed: {
        color: vm => vm.user ? COLORS[(vm.user.id || vm.user.userId) % COLORS.length] : '',
        l1   : vm => vm.user && vm.user.firstName ? vm.user.firstName.charAt(0) : vm.user.fullName ? vm.user.fullName[0] : '',
        l2   : vm => vm.user && vm.user.lastName ? vm.user.lastName.charAt(0) : vm.user.fullName ? vm.user.fullName[1] : '',
        src  : vm => vm.user ? vm.user.image : '',
        borderColor: () => COLORS[12]
    }
};</script>
<style lang="scss" scoped> img, .initials {
    border-radius: 50%;
    box-sizing: border-box;
    text-align: center;
    vertical-align: center;
}

.initials {
    z-index: 2;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $WHITE;
    font-weight: 400;
}</style>
