export const EVENT_BUS = (function () {
    const eventBuffer = new Map(),
          subscribers = [],

          on          = (eventName, handler, receiveLatestValue = false) => {
              const existingSubIndex = subscribers.findIndex(s => s.eventName === eventName && s.handler === handler);
              existingSubIndex === -1
                  ? subscribers.push({
                      eventName,
                      handler
                  })
                  : subscribers[existingSubIndex] = {
                      eventName,
                      handler
                  };
              receiveLatestValue && handler(eventBuffer.get(eventName));
          },

          off         = (eventName, handler) => {
              const existingSubIndex = subscribers.findIndex(s => s.eventName === eventName && s.handler === handler);
              existingSubIndex !== -1 && subscribers.splice(existingSubIndex, 1);
          },

          emit        = (eventName, value) => {
              eventBuffer.set(eventName, value);
              subscribers.forEach(s => s.eventName === eventName && s.handler(value));
          },

          resetAll    = () => eventBuffer.clear();

    return {
        on,
        off,
        emit,
        resetAll
    };
})();