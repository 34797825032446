export const reviewAnswer = {
    methods: {
        getBackgroundColor(reviewType) {
            let result = '';
            switch (reviewType) {
                case 'SELF_REVIEW':
                    result = '#FFCF3E';
                    break;
                case 'PEER_REVIEW':
                    result = '#139B61';
                    break;
                case 'MANAGER_REVIEW':
                    result = 'rgb(46, 142, 254)';
            }
            return result;
        },

        getTooltipText(reviewType) {
            let result = '';
            switch (reviewType) {
                case 'SELF_REVIEW':
                    result = 'Self';
                    break;
                case 'PEER_REVIEW':
                    result = 'Peer';
                    break;
                case 'MANAGER_REVIEW':
                    result = 'Manager';
            }
            return result;
        }
    }
};
