import { HTTP } from '@/common/utils/http';
import store from '@/store/store';

export const PeopleService = {

    inviteUsers(data) {
        return HTTP.post(`/users/invite`, data)
            .then(response => {
                return response;
            });
    },

    getUsers(filter, cancelable = true) {
        return HTTP.get(
            '/users' +
            '?page=' + filter.page +
            '&sortColumn=' + filter.sortColumn +
            '&size=' + filter.size +
            '&sortDirection=' + filter.sortDirection +
            '&filter=' + filter.q +
            '&reviewProcessId=' + filter.reviewProcessId,
            {cancelable}
            )
            .then(response => ({
                ...response,
                data: {
                    ...response.data,
                    content: [
                        ...response.data.content.map(user => ({
                            ...user,
                            manager: user.manager || {id: null}
                        }))
                    ]
                }
            }));
    },

    getUserOptions(filter, cancelable = true) {
        return HTTP.get(
            '/users' +
            '?page=' + filter.page +
            '&size=' + filter.size +
            '&sortDirection=' + filter.sortDirection +
            '&filter=' + filter.q,
            {cancelable}
            )
            .then(response => response.data.content
                .map(({id, fullName, email, blocked}) => ({
                    id,
                    fullName,
                    email,
                    blocked
                }))
                .sort((a, b) => (a.fullName > b.fullName) ? 1 : (b.fullName > a.fullName) ? -1 : 0));
    },

    getUser(userId, cancelable = true) {
        return HTTP.get(`/users/${userId}`, {cancelable});
    },

    getUsersWithPermissions(filter) {
        return HTTP.get(
            '/users/permissions' +
            '?page=' + filter.page +
            '&sortColumn=' + filter.sortColumn +
            '&size=' + filter.size +
            '&sortDirection=' + filter.sortDirection +
            '&filter=' + filter.q);
    },

    manageUsersPermissions(userId, isPermissionsGranted) {
        return HTTP.put(`/users/${userId}/permissions/${isPermissionsGranted}`);
    },

    changeUserPermissions(userId, permission, isPermissionsGranted) {
        return HTTP.put(`/users/${userId}/permissions/${permission}/${isPermissionsGranted}`);
    },

    isUserExistByEmail(email, companyName = '') {
        return HTTP.get(`/public/users/exists?email=${email.replace(/[+]/g, '%2B')}${companyName ? '&companyName=' + companyName : ''}`);
    },

    checkIfCompanyEmpty() {
        return HTTP.get(`/companies/is-users-exist`);
    },

    updateUserInfo(userId, data) {
        return HTTP.put(`/users/${userId}`, data);
    },

    updateUserManager(managerId, userId) {
        return HTTP.post(
            `/managers`,
            {
                managerId,
                userId
            }
        );
    },

    updateSubordinates(managerId, ids) {
        return HTTP.put(`/managers/${managerId}`, {ids});
    },

    updateUserTeams(userId, teamIds) {
        return HTTP.put(`/teams/for-user/${userId}`, {teamIds});
    },

    getManagerById(managerId, cancelable = true) {
        return HTTP.get(`/managers/${managerId}`, {cancelable});
    },

    getActiveReviewProcesses(reviewFilter) {
        return HTTP.get('/employee/review-processes/active' +
            '?filter.amountOfManagers=' + reviewFilter.amountOfManagers +
            '&filter.amountOfEmployees=' + reviewFilter.amountOfEmployees +
            '&filter.amountOfUsersToManage=' + reviewFilter.amountOfUsersToManage +
            '&size=' + reviewFilter.size)
            .then(({data: activeReviews}) => {
                store.commit('setActiveReviews', activeReviews);
                return activeReviews;
            });
    },

    getPastReviewProcesses(reviewFilter) {
        return HTTP.get('/employee/review-processes/past' +
            '?filter.amountOfManagers=' + reviewFilter.amountOfManagers +
            '&filter.amountOfEmployees=' + reviewFilter.amountOfEmployees +
            '&filter.amountOfUsersToManage=' + reviewFilter.amountOfUsersToManage +
            '&size=' + reviewFilter.size)
            .then(({data: pastReviews}) => {
                store.commit('setPastReviews', pastReviews);
                return pastReviews;
            });
    },

    importFileAndValidatePeople(file) {
        let payload = new FormData();
        payload.append('file', file);
        return HTTP.post('/members/file', payload);
    },

    importPeopleFromFile(data) {
        return HTTP.post('/members', data)
            .then(response => {
                return response;
            });
    },

    validatePeopleFromFile(data) {
        return HTTP.put('/members', data);
    },

    changeBlockUserStatus(userId, isBlocked) {
        return HTTP.put(`/users/${userId}/${isBlocked}`);
    },

    getManagerByEmployeeId(employeeId) {
        return HTTP.get(`/employees/${employeeId}/manager`);
    },

    resendInvitation(userId) {
        return HTTP.post(`/users/invite/resend/${userId}`);
    }
};
