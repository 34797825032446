import { DataForPeersModal } from '@/common/models/user/review-process/data-for-peers-modal';
import { Page } from '@/common/models/page';

export default {
    peersModal              : new DataForPeersModal(),
    subordinatesForApproval : new Page(),
    totalPeersLeft          : 0,
    passedRequesterId       : null,
    externalPeerTemplate    : null,
    requesters              : new Page(),
    isCurrentEmployeeManager: false
};
