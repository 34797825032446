import { Page } from '@/common/models/page';

export function initialTeamState() {
    return {
        team           : {
            id         : null,
            name       : '',
            members    : [],
            employeeIds: [],
            managerIds : [],
            teamName   : ''
        },
        isNameUnique   : true,
        initialTeamName: '',
        isTeamNameEdit : false,
        step           : 0,
        addedToTeam    : [],
        teamPageState  : '',
        teamsPage      : new Page()
    };
}
