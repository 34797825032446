export default {
    state    : {
        isAdminModeState   : JSON.parse(localStorage.getItem('isAdminMode')),
        isEmployeeModeState: JSON.parse(localStorage.getItem('isEmployeeMode'))
    },
    mutations: {
        settingMode(state, data) {
            state[data.mode] = data.value;
        }
    },
    getters  : {
        isAdminModeState   : state => state.isAdminModeState,
        isEmployeeModeState: state => state.isEmployeeModeState
    }
};
