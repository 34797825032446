<template>
    <transition name="fade">
        <div
            class="notification pointer"
            v-if="showNotification"
            :class="notificationType"
            @click="showNotification = false"
        >
            {{notificationMessage}}
        </div>
    </transition>
</template>

<script>
    import { LobbyService } from '@/common/services/lobby';
    import { UserDetails } from '@/common/user-details';

    export default {
        name: 'notification',

        data() {
            return {
                notificationMessage: '',
                showNotification   : false,
                notificationType   : '',
                notifications      : []
            };
        },

        methods: {
            ok(message, duration) {
                this.notificationMessage = message;
                this.notificationType    = 'success';
                this.showNotification    = true;
                setTimeout(() => {
                    this.showNotification    = false;
                    this.notificationMessage = '';
                }, duration);
            },
            error(message, duration) {
                this.notificationMessage = message;
                this.notificationType    = 'error';
                this.showNotification    = true;
                setTimeout(() => {
                    this.showNotification    = false;
                    this.notificationMessage = '';
                }, duration);
            },
            warning(message, duration) {
                this.notificationMessage = message;
                this.notificationType    = 'warning';
                this.showNotification    = true;
                setTimeout(() => {
                    this.showNotification    = false;
                    this.notificationMessage = '';
                }, duration);
            }
        },
        created() {
            const userWatcher = this.$watch('user', user => {
                if (!this.isNullOrUndefined(user.id)) {
                    if (user.notificationsEnabled) {
                        LobbyService.initSocketConnection();
                        LobbyService.getNotifications();
                    }
                    userWatcher();
                }
            });
        },

        computed: {
            user: () => UserDetails.user
        }
    };
</script>

<style scoped lang="scss">
    .notification {
        padding: 16px 24px;
        position: fixed;
        top: 5px;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        max-width: 500px;
        color: $GRAY_4A;
        z-index: 10;

        &.success {
            background-color: $SUCCESS;
        }

        &.error {
            background-color: $ERROR;
        }

        &.warning {
            background-color: $WARNING;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transform: translate3d(-50%, 0, 0);
        transition: opacity .8s, transform .8s;
        will-change: opacity, transform;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
        transform: translate3d(-50%, -100%, 0);
    }
</style>
