import Vue from 'vue';

export const TextOverflow = {
    bind            : (el, binding) => {
        el.style.overflow     = 'hidden';
        el.style.textOverflow = 'ellipsis';
        el.style.whiteSpace   = 'nowrap';
        let debounced         = setTimeout(() => {});
        el.handler            = (value, update = null) => {
            clearTimeout(debounced);
            debounced = setTimeout(() => {
                let isOverflow = el.scrollWidth > el.clientWidth;
                if (isOverflow !== el.isOverflow || update) {
                    el.isOverflow = isOverflow;
                    if (isOverflow) {
                        let clone    = Vue.extend({template: `<span v-tooltip="'${value || el.innerText}'">${value || el.innerText}</span>`}),
                            c        = new clone().$mount();
                        el.innerHTML = '';
                        el.appendChild(c.$el);
                    } else {
                        el.innerHTML = value || el.innerText;
                    }
                }
            }, 250);
        };
        el.handler(binding.value);
        window.addEventListener('resize', () => el.handler(binding.value));
    },
    componentUpdated: (el, binding) => {el.handler(binding.value, true);},
    unbind          : (el, binding) => { window.removeEventListener('resize', () => el.handler(binding.value)); }
};