import { Page } from '@/common/models/page';
import { DataForReviewWriting } from '@/common/models/user/review-process/data-for-review-writing';
import { DataForViewResults } from '@/common/models/user/review-process/data-for-view-results';
import { SelfReviewStatus } from '@/common/models/user/review-process/self-review-status';
import { UserReviewProcessInfo } from '@/common/models/user/review-process/user-review-process-info';

function initialState() {
    return {
        title               : '',
        reviewProcessInfo   : new UserReviewProcessInfo(),
        dataForReviewWriting: new DataForReviewWriting(),
        dataForViewResults  : new DataForViewResults(),
        selfReviewStatus    : new SelfReviewStatus(),
        activeReviews       : new Page(),
        pastReviews         : new Page()
    };
}

export const UserReviewProcessModule = {
    state: {
        ...initialState(),
        initialState: initialState()
    },

    mutations: {
        setTitle(state, title) {
            state.title = title;
        },
        setReviewProcessInfo(state, reviewProcessInfo) {
            const temp              = {...reviewProcessInfo};
            state.reviewProcessInfo = {...temp};
        },
        setDataForReviewWriting(state, dataForReviewWriting) {
            state.dataForReviewWriting = {...dataForReviewWriting};
        },
        setDataForViewResults(state, dataForViewResults) {
            state.dataForViewResults = {...dataForViewResults};
        },
        setSelfReviewStatus(state, selfReviewStatus) {
            state.selfReviewStatus = {...selfReviewStatus};
        },
        setActiveReviews(state, activeReviews) {
            state.activeReviews = {...activeReviews};
        },
        setPastReviews(state, pastReviews) {
            state.pastReviews = {...pastReviews};
        },
        dropReviewProcessInfo(state) {
            state.reviewProcessInfo = new UserReviewProcessInfo();
        },
        dropDataForReviewWriting(state) {
            state.dropDataForReviewWriting = new DataForReviewWriting();
        },
        dropDataForViewResults(state) {
            state.dataForViewResults = new DataForViewResults();
        },
        dropSubordinatesForApproval(state) {
            state.subordinatesForApproval = new Page();
        },
        reset(state) {
            const s = initialState();
            Object.keys(s).forEach(key => {
                state[key] = s[key];
            });
        }
    },

    getters: {
        title: state => state.title,

        activeReviews: state => state.activeReviews,
        pastReviews  : state => state.pastReviews,

        reviewProcessInfo        : state => state.reviewProcessInfo,
        usersToManage            : state => state.reviewProcessInfo.usersToManage,
        employeeReviewProcessData: state => state.reviewProcessInfo.employeeReviewProcessData,
        currentStage             : state => state.reviewProcessInfo.employeeReviewProcessData.currentStage,
        reviewProcessType        : state => state.reviewProcessInfo.employeeReviewProcessData.reviewProcessType,

        dataForReviewWriting : state => state.dataForReviewWriting,
        peerReviewTemplate   : state => state.dataForReviewWriting.peerReviewTemplate,
        managerReviewTemplate: state => state.dataForReviewWriting.managerReviewTemplate,
        selfReviewTemplate   : state => state.dataForReviewWriting.selfReviewTemplate,

        dataForViewResults: state => state.dataForViewResults,
        selfReviewStatus  : state => state.selfReviewStatus
    }
};
