import teamActions from './team-actions';
import teamMutations from './team-mutations';
import teamGetters from './team-getters';
import { initialTeamState } from './initial-team-state';

export default {
    state    : {...initialTeamState()},
    actions  : teamActions,
    mutations: teamMutations,
    getters  : teamGetters
};
