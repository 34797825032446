import GoalsModule from './modules/goals-module/goals.module';
import PaymentsModule from './modules/payments-module/payments.module';
import BannerModule from './modules/banner-module/banner.module';
import TourModule from './modules/tour-module/tour.module';
import CommentModule from './modules/comment-module/comment.module';
import ModeModule from './modules/mode-module/mode.module';
import PeopleModule from './modules/people-module/people.module';
import TeamModule from './modules/team-module/team.module';
import NotificationsModule from './modules/notifications-module/notifications.module';
import PeerModule from './modules/peers-module/peers.module';
import SidebarModule from './modules/sidebar-module/sidebar.module';
import { UserReviewProcessModule } from '@/store/modules/user-review-process/user-review-process.module';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        UserReviewProcessModule,
        GoalsModule,
        PaymentsModule,
        BannerModule,
        TourModule,
        CommentModule,
        ModeModule,
        PeopleModule,
        TeamModule,
        PeerModule,
        NotificationsModule,
        SidebarModule
    }
});
