<template>
    <div>
        <div class="table-wrapper">
            <table class="o-table">
                <thead>
                <tr>
                    <slot name="headers"></slot>
                </tr>
                </thead>
                <tbody ref="tbody">
                <slot name="body"></slot>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script> export default {
    name: 'OTable'
};
</script>
<style lang="scss" scoped>
    .table-wrapper {
        overflow: auto;
    }

    .o-table {
        border-collapse: collapse;
        width: 100%;

        thead {
            tr {
                border-bottom: 1px solid $GRAY_F0;
                height: 56px;

                th {
                    text-align: left;
                    padding: 0 24px;
                    font-size: 13px;
                    color: $BLACK_54;
                    text-transform: uppercase;
                    font-weight: normal;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid $GRAY_F0;
                height: 82px;

                td {
                    padding: 0 24px;
                    font-size: 14px;
                    color: $GRAY_4A;
                }
            }
        }
    }

    @media only screen and (max-width: 950px) {
        .o-table {
            th, td {
                padding: 0 4px;
            }
        }
    }
</style>
