<template>
    <o-modal close-method="closeCropper">
        <div slot="header">Select photo for avatar</div>
        <div slot="body" class="o-cropper">
            <div class="o-cropper__images"><input type="file" :id="id" class="avatar-input" @change="onLoad">
                <div class="o-cropper__container"><img v-if="img" :src="img" :style="sourceImageStyles" ref="source" alt=""
                                                       class="o-cropper__container__source">
                    <div v-show="img" ref="circle" :style="circleStyles" class="o-cropper__container__circle"
                         @touchend.self="moveState = false" @mouseup.self="moveState = false"></div>
                    <div v-show="!img" class="o-cropper__container__placeholder"></div>
                </div>
                <div class="o-cropper__preview">
                    <div class="o-cropper__preview__image" :style="{backgroundImage: 'url('+result+')'}" ref="result"></div>
                    <label :for="id" class="attach-label">
                        <o-icon name="cloud_upload"/>
                    </label></div>
            </div>
            <o-slider class="o-cropper__slider" v-model="sliderValue" :min="min" :max="max"></o-slider>
        </div>
        <div slot="footer">
            <o-button @click="closeCropper()" secondary>Cancel</o-button>
            <o-button primary @click="closeCropper(); $emit('save', result)" :disabled="!result">Save</o-button>
        </div>
    </o-modal>
</template>
<script>export default {
    name    : 'o-image-cropper',
    props   : {src: {default: ''}},
    model   : {
        prop : 'src',
        event: 'save'
    },
    data() {
        return {
            id          : new Date().getTime(),
            img         : '',
            imgWidth    : 290,
            imgHeight   : 290,
            bufferImg   : null,
            ratio       : 1,
            circleTop   : 0,
            circleLeft  : 0,
            moveState   : false,
            resizeState : false,
            startResizeX: 0,
            startResizeY: 0,
            sliderValue : 290,
            min         : 30,
            max         : 290,
            size        : 290,
            startYPos   : 0,
            startXPos   : 0,
            startTop    : 0,
            startLeft   : 0,
            yDiff       : 0,
            xDiff       : 0,
            result      : '',
            mousemoveFn : e => {
                if (this.moveState) {
                    [this.xDiff, this.yDiff] = [e.x - this.startXPos, e.y - this.startYPos];
                    this.circleLeft          = (this.startLeft + this.xDiff < 1 ? 1 : this.startLeft + this.xDiff + +this.size > this.imgWidth ? this.imgWidth - +this.size : this.startLeft + this.xDiff);
                    this.circleTop           = (this.startTop + this.yDiff < 1 ? 1 : this.startTop + this.yDiff + +this.size > this.imgHeight ? this.imgHeight - +this.size : this.startTop + this.yDiff);
                    this.processCanvas();
                }
            },
            mouseupFn   : () => {
                this.moveState = false;
                window.removeEventListener('mousemove', this.mousemoveFn);
                window.removeEventListener('mouseup', this.mouseupFn);
                window.removeEventListener('touchmove', this.touchmoveFn);
                window.removeEventListener('touchend', this.touchstartFn);
            },
            mousedownFn : e => {
                this.moveState                   = true;
                [this.startXPos, this.startYPos] = [e.x, e.y];
                [this.startTop, this.startLeft]  = [this.circleTop, this.circleLeft];
                window.addEventListener('mousemove', this.mousemoveFn);
                window.addEventListener('mouseup', this.mouseupFn);
            },
            touchmoveFn : e => {
                if (this.moveState) {
                    [this.xDiff, this.yDiff] = [e.changedTouches[0].clientX - this.startXPos, e.changedTouches[0].clientY - this.startYPos];
                    this.circleLeft          = (this.startLeft + this.xDiff < 1 ? 1 : this.startLeft + this.xDiff + +this.size > this.imgWidth ? this.imgWidth - +this.size : this.startLeft + this.xDiff);
                    this.circleTop           = (this.startTop + this.yDiff < 1 ? 1 : this.startTop + this.yDiff + +this.size > this.imgHeight ? this.imgHeight - +this.size : this.startTop + this.yDiff);
                    this.processCanvas();
                }
            },
            touchstartFn: e => {
                this.moveState                   = true;
                [this.startXPos, this.startYPos] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
                [this.startTop, this.startLeft]  = [this.circleTop, this.circleLeft];
                window.addEventListener('touchmove', this.touchmoveFn);
                window.addEventListener('touchend', this.mouseupFn);
            }
        };
    },
    mounted() {
        setTimeout(() => {
            this.$refs.circle.onmousedown  = this.mousedownFn;
            this.$refs.circle.ontouchstart = this.touchstartFn;
        });
    },
    watch   : {
        sliderValue() {
            (this.circleLeft + +this.sliderValue > this.imgWidth) && (this.circleLeft = this.imgWidth - +this.sliderValue);
            (this.circleTop + +this.sliderValue > this.imgHeight) && (this.circleTop = this.imgHeight - +this.sliderValue);
            this.size = +this.sliderValue;
            this.processCanvas();
        },
        src(img) {
            this.result = img;
            if (img) {
                this.img       = img;
                let srcImg     = new Image();
                srcImg.src     = img;
                srcImg.onload  = this.imgOnload;
                this.bufferImg = srcImg;
            }
        }
    },
    methods : {
        processCanvas() {
            let resultCanvas    = document.createElement('canvas');
            let resultCtx       = resultCanvas.getContext('2d');
            resultCanvas.width  = 150;
            resultCanvas.height = 150;
            let sourceCanvas    = document.createElement('canvas');
            let sourceCtx       = sourceCanvas.getContext('2d');
            sourceCanvas.width  = this.bufferImg.width;
            sourceCanvas.height = this.bufferImg.height;
            sourceCtx.drawImage(this.bufferImg, 0, 0);
            let sliderIntValue = +this.sliderValue;
            resultCtx.drawImage(
                sourceCanvas,
                this.circleLeft * this.ratio,
                this.circleTop * this.ratio,
                sliderIntValue * this.ratio,
                sliderIntValue * this.ratio,
                0,
                0,
                150,
                150
            );
            this.result = resultCanvas.toDataURL();
            sourceCanvas.remove();
            resultCanvas.remove();
        },
        imgOnload(e) {
            const srcImg          = e.target || e.path[0];
            this.ratio            = Math.max(srcImg.width, srcImg.height) / 290;
            const {width, height} = srcImg;
            let minSize           = 0;
            if (width > height) {
                this.imgWidth  = 290;
                this.imgHeight = 290 / (width / height);
                minSize        = this.imgHeight;
            } else if (width < height) {
                this.imgHeight = 290;
                this.imgWidth  = 290 / (height / width);
                minSize        = this.imgWidth;
            } else {
                this.imgHeight = 290;
                this.imgWidth  = 290;
                minSize        = 290;
            }
            this.min        = 30;
            this.size       = minSize;
            this.max        = Math.min(this.imgWidth, this.imgHeight);
            this.circleLeft = this.imgWidth / 2 - minSize / 2;
            this.circleTop  = this.imgHeight / 2 - minSize / 2;
            this.sliderValue === minSize ? this.processCanvas() : this.sliderValue = minSize;
        },
        onLoad(e) {
            if (e.target.files[0]) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = e => {
                    const data     = e.target.result;
                    this.img       = data;
                    let srcImg     = new Image();
                    srcImg.src     = data;
                    srcImg.onload  = this.imgOnload;
                    this.bufferImg = srcImg;
                };
            }
        }
    },
    computed: {
        circleStyles     : vm => ({
            width : vm.size + 'px',
            height: vm.size + 'px',
            top   : vm.circleTop + 'px',
            left  : vm.circleLeft + 'px'
        }),
        sourceImageStyles: vm => ({
            width : vm.imgWidth + 'px',
            height: vm.imgHeight + 'px'
        })
    },
    beforeDestroy() {this.$refs.circle.onmousedown = undefined;}
};</script>
<style scoped lang="scss">.avatar-input {
    display: none;
}

.attach-label {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 32px;
    height: 32px;
    color: $PRIMARY;
    opacity: 0;
    transition: opacity .3s;
    will-change: opacity;

    i {
        font-size: 32px;
        width: 32px;
        height: 32px;
    }
}

.o-cropper {
    &__slider {
        padding: 8px 0;
    }

    &__images {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .o-cropper__container {
        max-width: 290px;
        max-height: 290px;
        position: relative;
        display: inline-flex;
        overflow: hidden;
        user-select: none;
        margin-bottom: 16px;

        &__placeholder {
            width: 290px;
            height: 290px;
            box-sizing: border-box;
            border: 2px dashed $GRAY_9B;
        }

        &__source {
            max-width: 290px;
            max-height: 290px;
            pointer-events: none;
            user-select: none;
        }

        &__circle {
            position: absolute;
            background-color: transparent;
            border-radius: 50%;
            box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);
        }
    }

    &__preview {
        position: relative;
        padding: 10px;
        background-color: $GRAY_D8;

        .attach-label {
            opacity: 1;
            pointer-events: all;
            cursor: pointer;
        }

        &__image {
            background-size: contain;
            display: flex;
            border-radius: 50%;
            max-width: 120px;
            max-height: 120px;
            width: 120px;
            height: 120px;
        }
    }

    @media only screen and (max-width: 566px) {
        .o-cropper__images {
            flex-direction: column;
        }
        .o-cropper__preview {
            align-self: unset;
            margin: 0 0 16px;
        }
    }
}</style>
