export const Page = function () {
    this.content          = [];
    this.first            = false;
    this.last             = false;
    this.number           = 0;
    this.numberOfElements = 0;
    this.size             = 50;
    this.sort             = null;
    this.totalElements    = 0;
    this.totalPages       = 0;
};