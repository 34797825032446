import { PeopleService } from '@/common/services/people';

export default {
    getUsersToPage({state, commit}, {filter, append = false}) {
        PeopleService.getUsers(filter).then(response => {
            let users;
            let filteredUsers = response.data.content.filter(user => !user.blocked);
            commit('setUsersLeft', response.data.numberOfElements);
            if (append) {
                filteredUsers.length && (users = {
                    ...response.data,
                    content: [...state.usersPage.content, ...filteredUsers]
                });
            } else {
                users = {
                    ...response.data,
                    content: filteredUsers
                };
            }
            if (response.data.content.length) {
                commit('setUsersPageData', users);
                commit('setEmployeePage', response.data);
                commit(
                    'setUsersForSettingPage',
                    {
                        ...response.data,
                        content: users.content
                    }
                );
            }
        });
    },
    async getUsersForSelected({commit}, {filter}) {
        const {data} = await PeopleService.getUsers(filter);
        commit('setUsersToGetSelected', data);
    },
    async updateManager({dispatch}, {filter, managerId, userId}) {
        await PeopleService.updateUserManager(managerId, userId);
        dispatch('getUsersToPage', {filter});
    },
    async changedBlockedStatus({dispatch}, {filter, userId, userStatus}) {
        await PeopleService.changeBlockUserStatus(userId, userStatus);
        dispatch('getUsersToPage', {filter});
    },
    async getUsersWithPermissions({commit}, permissionFilter) {
        const response = await PeopleService.getUsersWithPermissions(permissionFilter);
        commit('setUsersWithPermissions', response);
    },
    async manageUsersPermission({dispatch}, {filter, permissionFilter, userId, isPermissionsGranted}) {
        await PeopleService.manageUsersPermissions(userId, isPermissionsGranted);
        dispatch('getUsersToPage', {filter});
        dispatch('getUsersWithPermissions', permissionFilter);
    },
    async changePermission({dispatch}, {permissionFilter, userId, permission, isPermissionsGranted}) {
        await PeopleService.changeUserPermissions(userId, permission, isPermissionsGranted);
        dispatch('getUsersWithPermissions', permissionFilter);
    },
    async getUserOptions({state, commit}, {autocompleteFilter, append}) {
        const response = await PeopleService.getUserOptions(autocompleteFilter);
        let options;
        if (append) {
            response.length && (options = [...state.userOptions, ...response]);
        } else {
            options = response;
        }
        if (response.length) {
            commit('setUserOptions', options);
        }
    },
    async checkIfCompanyEmpty({commit}) {
        const response = await PeopleService.checkIfCompanyEmpty();
        commit('setCompanyState', response.data);
    },
    async importFileWithPeopleAndValidate({commit}, selectedFile) {
        const response = await PeopleService.importFileAndValidatePeople(selectedFile);
        commit('setPeopleFromFile', response.data);
    },
    async validateFormFile({commit}, people) {
        const response = await PeopleService.validatePeopleFromFile(people);
        commit('setPeopleFromFile', response.data);
        commit('isPeopleInFileValid');
    },
    async getManager({commit}, userId) {
        const response = await PeopleService.getManagerById(userId);
        if (response.data.content && response.data.content.length) {
            commit('setManagerToUser', response.data.content[0]);
        }
    },
    async getUser({commit}, id) {
        const response = await PeopleService.getUser(id);
        const user     = {
            ...response.data,
            manager: response.data.manager || {}
        };
        commit('setUserData', user);
    }
};
