import Vue from 'vue';
import OAccordion from '../reusable-components/o-accordion';
import OAddPeersModal from '../reusable-components/o-add-peers-modal';
import OButton from '../reusable-components/o-button';
import OCheckbox from '../reusable-components/o-checkbox';
import OInput from '../reusable-components/o-input';
import OTextarea from '../reusable-components/o-textarea';
import OPagination from '../reusable-components/o-pagination';
import OProgress from '../reusable-components/o-progress';
import OProgressResults from '../reusable-components/o-progress-results';
import ORadioButtons from '../reusable-components/o-radio-buttons';
import OSearchInput from '../reusable-components/o-search-input';
import OSelect from '../reusable-components/o-select';
import OStepper from '../reusable-components/o-stepper';
import OTable from '../reusable-components/o-table';
import OTabs from '../reusable-components/o-tabs';
import OToggle from '../reusable-components/o-toggle';
import OUserImage from '../reusable-components/o-user-image';
import OMenu from '../reusable-components/o-menu';
import OModal from '../reusable-components/o-modal';
import ORating from '../reusable-components/o-rating';
import OSlider from '../reusable-components/o-slider';
import OImageCropper from '../reusable-components/o-image-cropper';
import OConfirmModal from '../reusable-components/o-confirm-modal';
import OLabeledInfo from '../reusable-components/o-labeled-info';
import OAutocomplete from '../reusable-components/o-autocomplete';
import ODatePicker from '../reusable-components/o-date-picker';
import OLogo from '../reusable-components/o-logo';
import OCircleProgress from '../reusable-components/o-circle-progress';
import OIcon from '../reusable-components/o-icon';
import OMaskedInput from '../reusable-components/o-masked-input';

Vue.component('o-accordion', OAccordion);
Vue.component('o-add-peers-modal', OAddPeersModal);
Vue.component('o-button', OButton);
Vue.component('o-checkbox', OCheckbox);
Vue.component('o-input', OInput);
Vue.component('o-textarea', OTextarea);
Vue.component('o-pagination', OPagination);
Vue.component('o-progress', OProgress);
Vue.component('o-progress-results', OProgressResults);
Vue.component('o-radio-buttons', ORadioButtons);
Vue.component('o-search-input', OSearchInput);
Vue.component('o-select', OSelect);
Vue.component('o-stepper', OStepper);
Vue.component('o-table', OTable);
Vue.component('o-tabs', OTabs);
Vue.component('o-toggle', OToggle);
Vue.component('o-user-image', OUserImage);
Vue.component('o-menu', OMenu);
Vue.component('o-modal', OModal);
Vue.component('o-rating', ORating);
Vue.component('o-slider', OSlider);
Vue.component('o-image-cropper', OImageCropper);
Vue.component('o-confirm-modal', OConfirmModal);
Vue.component('o-labeled-info', OLabeledInfo);
Vue.component('o-autocomplete', OAutocomplete);
Vue.component('o-date-picker', ODatePicker);
Vue.component('o-logo', OLogo);
Vue.component('o-circle-progress', OCircleProgress);
Vue.component('o-icon', OIcon);
Vue.component('o-masked-input', OMaskedInput);
