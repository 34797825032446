export const PEER_STATUSES = {
    approved   : 'Approved',
    invited    : 'Invited',
    notSelected: 'Not Selected',
    selected   : 'Selected'
};

export const STATUSES_TO_DB ={
    alreadyInvited: 'APPROVED,INVITED'
};
