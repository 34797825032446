import { initialGoalsState } from './initial-goals-state';

export default {
    setGoals(state, goals) {
        state.goals = goals;
        if (state.selectedGoal) {
            let targetGoal = state.goals.goals.find(goal => goal.id === state.selectedGoal.id);
            if (!targetGoal) {
                targetGoal = state.goals.goals
                    .reduce((goals, goal) => [...goals, ...goal.relatesTo], [])
                    .find(goal => goal.id === state.selectedGoal.id);
            }
            state.selectedGoal = targetGoal;
        }
    },
    setManagerGoals(state, goals) {
        state.managerGoals = goals;
    },
    setSelectedGoal(state, goal) {
        state.selectedGoal = goal;
    },
    setFilter(state, filter) {
        state.filter = filter;
    },
    reset(state) {
        const s = initialGoalsState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
