export default {
    peersModal              : state => state.peersModal,
    peers                   : state => state.state.peersModal.peers,
    subordinatesForApproval : state => state.subordinatesForApproval,
    passedRequesterId       : state => state.passedRequesterId,
    externalPeerTemplate    : state => state.externalPeerTemplate,
    requesters              : state => state.requesters,
    requestedEmployees      : state => state.subordinatesForApproval.requestedEmployees,
    totalPeersLeft          : state => state.totalPeersLeft,
    isCurrentEmployeeManager: state => state.isCurrentEmployeeManager
};
