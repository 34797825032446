<template>
    <div id="app">
        <transition :name="animateRoute ? 'fade' : ''" mode="out-in" appear>
            <router-view :key="$route.fullPath"/>
        </transition>
    </div>
</template>

<script>
    import { EVENT_BUS } from '@/common/utils/event-bus';
    import store from '@/store/store';

    export default {
        name: 'App',

        computed: {
            animateRoute: vm => !vm.$route.matched.some(r => r.path === '')
        },

        created() {
            store.commit('initSubscriptionStatus');
            window.addEventListener('mousedown', e => EVENT_BUS.emit('MOUSEDOWN_EVENT', e));
        },

        watch: {
            $route() {
                store.commit('setSelectedGoal', null);
            }
        }
    };
</script>

<style lang="scss" src="./_styles.scss">
</style>
