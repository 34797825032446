import { HTTP } from '@/common/utils/http';
import Vue from 'vue';

export const TemplatesService = {

    isTemplateNameExist(templateName, templateType) {
        return HTTP.get(`/review-templates/exist?templateName=${templateName}&templateType=${templateType}`);
    },

    getReviewTemplates(filter, cancelable = true) {
        return HTTP.get(
            '/review-templates' +
            '?page=' + filter.page +
            '&size=' + filter.size +
            '&filter=' + filter.q +
            '&sortColumn=' + 'lastUpdated' +
            '&sortDirection=' + 'DESC',
            {cancelable}
            )
            .then(res => {
                res.data.defaultReviewTemplates.forEach(template => template.groups.forEach(group => group.components.forEach(Vue.prototype.parseQuestion)));
                res.data.templates.content.forEach(template => template.groups.forEach(group => group.components.forEach(Vue.prototype.parseQuestion)));
                return res;
            });
    },

    getTemplateForReviewProcess(processType, cancelable = true) {
        return HTTP.get(`/review-templates/for-process?processType=${processType}`, {cancelable});
    },

    getTemplateById(id, cancelable = true) {
        return HTTP.get(`/review-templates/${id}`, {cancelable}).then(template => {
            template.data.groups.forEach(group => {
                group.isVisible = true;
                group.components.forEach(Vue.prototype.parseQuestion);
            });
            return template;
        });
    },

    updateReviewTemplate(template) {
        const payload = Vue.prototype.cloneObj(template);
        payload.groups.forEach(g => g.components.forEach(Vue.prototype.stringifyQuestion));
        return HTTP.put(`/review-templates`, payload);
    },

    deleteReviewTemplate(id) {
        return HTTP.delete(`/review-templates/${id}`);
    },

    setTemplateStatus(value) {
        return HTTP.get(`/companies/set-template-updated?value=${value}`);
    },

    checkIfTemplateVisited() {
        return HTTP.get(`/companies/is-template-updated`);
    },

    getComponents(filter) {
        let question = filter.q.replace(/[^(\w|\d|\s)]/g, '');
        return HTTP.get('/review-templates/components' +
            '?question=' + question +
            '&page=' + filter.page +
            '&size=' + filter.size +
            '&sortDirection=' + filter.sortDirection
            )
            .then(res => {
                res.data.content.forEach(Vue.prototype.parseQuestion);
                return res;
            });
    },

    getMandatoryComponents() {
        return HTTP.get('/review-templates/mandatory-components')
            .then(res => {
                res.data.forEach(Vue.prototype.parseQuestion);
                return res;
            });
    }

};
