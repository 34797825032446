import { LobbyService } from '@/common/services/lobby';
import { PeopleService } from '@/common/services/people';
import { TemplatesService } from '@/common/services/templates';
import { getForbiddenEmailProviders } from '@/common/utils/helpers';
import { compareStrings } from '@/common/utils/templates';
import { Validator } from 'vee-validate';

const fieldNameRegexp = /@[a-zA-Z0-9\s]*/;
const emailRegexp     = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[^@]+\.[^@]+$/;

const dictionary = {
    en: {
        messages: {
            min      : (fieldName, params) => {
                const match = fieldName.match(fieldNameRegexp);
                return `"${match ? match[0].slice(1) : fieldName}" should contain more than ${params[0]} symbols`;
            },
            max      : (fieldName, params) => {
                const match = fieldName.match(fieldNameRegexp);
                return `"${match ? match[0].slice(1) : fieldName}" shouldn't contain more than ${params[0]} symbols`;
            },
            min_value: (fieldName, params) => {
                const match = fieldName.match(fieldNameRegexp);
                return `"${match ? match[0].slice(1) : fieldName}" should be greater than ${params[0]}`;
            },
            required : (fieldName) => {
                const match = fieldName.match(fieldNameRegexp);
                return `"${match ? match[0].slice(1) : fieldName}" shouldn't be empty`;
            }
        }
    }
};

const passwordPattern = {
    getMessage() {
        return 'Password should contain 8 or more characters with a mix of letters (small and capital), numbers & symbols';
    },
    validate(password) {
        let regExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[._#?!@$%^&*-+"(),/:;<=>`~{}'*+,-.[\\\]]).{8,}$/;
        return regExp.test(password);
    }
};

const custom = {
    getMessage(field, args) {
        return args[1];
    },
    validate(value, args) {
        return !args[0];
    }
};

const sameAs = {
    getMessage() {
        return 'Those passwords didn\'t match, Try again.';
    },
    validate(password, args) {
        return password === args[0];
    }
};

const between = {
    getMessage(fieldName, params) {
        const match = fieldName.match(fieldNameRegexp);
        return `"${match
            ? match[0].slice(1)
            : fieldName}" must be between ${params[0]} and ${params[1]} characters long`;
    },
    validate(text, args) {
        return text.length >= +args[0] && text.length <= +args[1];
    }
};

const greaterThan = {
    getMessage(field) {
        const match = field.match(fieldNameRegexp);
        return `${match ? match[0].slice(1) : field} should be greater than Min value`;
    },
    validate(value, args) {
        return value > args[0];
    }
};

const isOptionExist = {
    getMessage() {
        return 'Option exists';
    },
    validate(value, component) {
        return value && component.possibleValues.filter(filterValue => compareStrings(filterValue, value)).length === 1;
    }
};

const isEmailFree = {
    getMessage() {
        return `This email address is already associated with an existing account`;
    },
    async validate(value) {
        let isExist = false;
        value && await PeopleService.isUserExistByEmail(value).then(res => isExist = res.data);
        return !isExist;
    }
};

const isEmailExistInCompany = {
    getMessage() {
        return `No such company member`;
    },
    async validate(value) {
        let isExist   = true;
        const company = JSON.parse(localStorage.getItem('user.details')).companyName;

        value && await PeopleService.isUserExistByEmail(value, company).then(res => isExist = res.data);
        return isExist;
    }
};

const emailProvider = {
    getMessage() {
        return 'Please use corporate email.';
    },
    validate(value) {
        return !getForbiddenEmailProviders().some(function (provider) {
            return value.slice(value.indexOf('@') + 1).startsWith(provider + '.');
        });
    }
};

const isEmailExist = {
    getMessage() {
        return `This email address is already chosen`;
    },
    validate(value, users) {
        return users.map(user => user.email).filter(filterValue => compareStrings(filterValue, value)).length === 1;
    }
};

const isTemplateNameFree = {
    getMessage() {
        return `Template with such name already exists`;
    },
    async validate(value, [type, isFirstTime]) {
        let isExist = false;
        !isFirstTime && value && await TemplatesService.isTemplateNameExist(value, type).then(res => isExist = res.data);
        return !isExist;
    }
};

const isCompanyNameFree = {
    getMessage() {
        return `This company name is already in use`;
    },
    async validate(value) {
        let isExist = false;
        value && await LobbyService.isCompanyNameExist(value).then(res => isExist = res);
        return !isExist;
    }
};

const email = {
    getMessage() {
        return `Invalid email address`;
    },
    validate(value) {
        return emailRegexp.test(value);
    }
};

const phone = {
    getMessage(field) {
        return `The ${field} must be a valid phone number`;
    },
    validate(value) {
        const mobileReg = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im;

        return mobileReg.test(value);
    }
};

const isStringOfEmails = {
    getMessage() {
        return `Invalid string of emails`;
    },
    validate(value) {
        if (value.search(',') !== -1) {
            let emailList     = value.split(',');
            let valueInString = false;
            emailList.forEach((val) => {
                valueInString = emailRegexp.test(val);
            });
            return emailList.length > 1 && valueInString;
        } else {
            return emailRegexp.test(value);
        }
    }
};

Validator.extend('isEmailExistInCompany', isEmailExistInCompany);
Validator.extend('isCompanyNameFree', isCompanyNameFree);
Validator.extend('isTemplateNameFree', isTemplateNameFree);
Validator.extend('isEmailFree', isEmailFree);
Validator.extend('emailProvider', emailProvider);
Validator.extend('isEmailExist', isEmailExist);
Validator.extend('isOptionExist', isOptionExist);
Validator.extend('greaterThan', greaterThan);
Validator.extend('passwordPattern', passwordPattern);
Validator.extend('sameAs', sameAs);
Validator.extend('between', between);
Validator.extend('custom', custom);
Validator.extend('email', email);
Validator.extend('phone', phone);
Validator.extend('isStringOfEmails', isStringOfEmails);

Validator.localize(dictionary);
