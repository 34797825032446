import { ROUTES } from "@/common/auth/auth-guard";
import { LobbyService } from "@/common/services/lobby";
import { Notify } from "@/common/services/notification";
import { UserDetails } from "@/common/user-details";
import { OPERF } from "@/main";
import Vue from "vue";
import Router from "vue-router";

//Lobby's components
const SignIn = () => import("../app/lobby/sign-in");
const MagicLink = () => import("../app/lobby/magic-link");
const Unsubscribe = () => import("../app/lobby/unsubscribe");
const ForgotPassword = () => import("../app/lobby/forgot-password");
const ResetPassword = () => import("../app/lobby/reset-password");
const SetPassword = () => import("../app/lobby/set-password");
const ExternalReview = () => import("../app/lobby/external-review");

//CO's components
const Organization = () => import("../app/organization/organization");
const EmployeeDetails = () => import("../app/organization/employees/employee-details/employee-details");
const AddEmployee = () => import("../app/organization/employees/add-employee");
const UploadFile = () => import("../app/organization/employees/upload-file");
const AddTeam = () => import("../app/organization/teams/add-team/add-team");
const TeamDetails = () => import("../app/organization/teams/team-details");
const Review = () => import("../app/reviews/reviews");
const CreateReview = () => import("../app/reviews/create-review/create-review");
const ReviewEdit = () => import("../app/reviews/edit-review/edit-review");
const ReviewDetails = () => import("../app/reviews/review/review-details");
const ReviewUserResults = () => import("../app/reviews/review/review-user-results");
const ReviewTemplates = () => import("../app/templates/review-templates/review-templates");
const TemplateEdit = () => import("../app/templates/template-edit");
const Analytics = () => import("../app/analytics/analytics");
const Billing = () => import("../app/billing/billing");

//User's components
const Home = () => import("../app/user/home/home");
const ReviewProcess = () => import("../app/user/review-process/review-process");
const ReviewProcessManagement = () => import("../app/user/review-process/review-process-management");
const Goals = () => import("../app/user/goals/goals");

const Profile = () => import("../app/profile/profile");
const Layout = () => import("../app/layout/layout");

const isCo = () => UserDetails.roles.indexOf("ROLE_COMPANY_OWNER") !== -1;
const isEmployee = () => UserDetails.roles.indexOf("ROLE_EMPLOYEE") !== -1;
const isTeamAdmin = () => UserDetails.permissions.indexOf("ORGANIZATION_ADMIN") !== -1;
const isReviewAdmin = () => UserDetails.permissions.indexOf("REVIEW_ADMIN") !== -1;
const isBillingAdmin = () => UserDetails.permissions.indexOf("BILLING_ADMIN") !== -1;
const isAnalyticAdmin = () => UserDetails.permissions.indexOf("ANALYTIC_ADMIN") !== -1;
const isAdminMode = () => JSON.parse(localStorage.getItem("isAdminMode"));
const isEmployeeMode = () => JSON.parse(localStorage.getItem("isEmployeeMode"));

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: ROUTES.SIGN_IN,
            component: SignIn,
            beforeEnter: (to, from, next) => {
                const { email, token } = to.query;

                if (token && email) {
                    LobbyService.verifyEmail(token).then(() => {
                        Notify.ok("Congratulations! You are now ready to use Operf.");

                        next("/sign-in?email=" + email.replace(/ /g, "%2B"));
                    });
                } else {
                    const withoutSubdomain = window.location.protocol + "//" + process.env.VUE_APP_HOST_NAME;

                    window.location.href.startsWith(withoutSubdomain)
                        ? (window.location.href = process.env.VUE_APP_WEBSITE_UI_URL + "/#sign-in")
                        : next();
                }
            }
        },
        {
            path: ROUTES.MAGIC_LINK,
            component: MagicLink
        },
        {
            path: ROUTES.UNSUBSCRIBE,
            component: Unsubscribe
        },
        {
            path: ROUTES.FORGOT_PASSWORD,
            component: ForgotPassword
        },
        {
            path: ROUTES.RESET_PASSWORD,
            component: ResetPassword,
            beforeEnter: (to, from, next) => {
                from.name === null && to.query.token ? next() : next("/sign-in");
            }
        },
        {
            path: ROUTES.SET_PASSWORD,
            component: SetPassword,
            beforeEnter: (to, from, next) => {
                from.name === null && to.query.token ? next() : next("/sign-in");
            }
        },
        {
            path: ROUTES.EXTERNAL_REVIEW,
            component: ExternalReview
        },
        {
            path: "",
            pathMatch: "full",
            redirect: "/dashboard"
        },
        {
            path: "",
            component: Layout,
            children: [
                {
                    path: "/dashboard",
                    component: Review,
                    beforeEnter: (to, from, next) => {
                        (isCo() || (isReviewAdmin() && isAdminMode())) && !isEmployeeMode() ? next() : next("/home");
                    },
                    meta: {
                        tab: 1,
                        templateType: null,
                        goToTemplates: function(type) {
                            this.templateType = type;
                            OPERF.$router.push("/templates");
                        }
                    }
                },
                {
                    path: "/organization",
                    component: Organization,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isTeamAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        title: "Organization"
                    }
                },
                {
                    path: "/organization/add-employee",
                    component: AddEmployee,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isTeamAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        title: "Add employee",
                        backRoute: "/organization",
                        tab: 0
                    }
                },
                {
                    path: "/organization/upload",
                    component: UploadFile,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isTeamAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        title: "Add employee",
                        backRoute: "/organization",
                        tab: 0
                    }
                },
                {
                    path: "/employee-details/:id",
                    component: EmployeeDetails,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isTeamAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        title: "Employee details",
                        backRoute: "/organization",
                        tab: 0
                    }
                },
                {
                    path: "/organization/add-team",
                    component: AddTeam,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isTeamAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        title: "Add team",
                        backRoute: "/organization",
                        tab: 1
                    }
                },
                {
                    path: "/team-details/:id",
                    component: TeamDetails,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isTeamAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        backRoute: "/organization",
                        tab: 1
                    }
                },
                {
                    path: "/reviews/create",
                    component: CreateReview,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        backRoute: "/reviews",
                        title: "Create review process"
                    }
                },
                {
                    path: "/reviews/:id",
                    component: ReviewDetails,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        backRoute: "/reviews"
                    }
                },
                {
                    path: "/reviews/:id/results/:user_id",
                    component: ReviewUserResults,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        get backRoute() {
                            const reviewId = OPERF.$route.params.id;
                            let backRoute = "/reviews";
                            reviewId && (backRoute += "/" + reviewId);
                            return backRoute;
                        }
                    }
                },
                {
                    path: "/reviews/:id/edit",
                    component: ReviewEdit,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        get backRoute() {
                            const reviewId = OPERF.$route.params.id;
                            let backRoute = "/reviews";
                            reviewId && (backRoute += "/" + reviewId);
                            return backRoute;
                        }
                    }
                },
                {
                    path: "/templates",
                    component: ReviewTemplates,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/dashboard");
                    },
                    meta: {
                        title: "Templates"
                    }
                },
                {
                    path: "/templates/:id/edit",
                    component: TemplateEdit,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/dashboard");
                    },
                    meta: {
                        title: "Edit template",
                        backRoute: "/templates"
                    }
                },
                {
                    path: "/templates/create",
                    component: TemplateEdit,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/dashboard");
                    },
                    meta: {
                        title: "Create template",
                        backRoute: "/templates"
                    }
                },
                {
                    path: "/templates/:id/duplicate",
                    component: TemplateEdit,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isReviewAdmin() && isAdminMode()) ? next() : next("/dashboard");
                    },
                    meta: {
                        title: "Duplicate template",
                        backRoute: "/templates"
                    }
                },
                {
                    path: "/analytics",
                    component: Analytics,
                    beforeEnter: (to, from, next) => {
                        (isCo() && !isEmployeeMode()) || (isAnalyticAdmin() && isAdminMode()) ? next() : next("/dashboard");
                    },
                    meta: {
                        title: "Analytics"
                    }
                },
                {
                    path: "/billing",
                    component: Billing,
                    beforeEnter: (to, from, next) => {
                        isCo() || (isBillingAdmin() && isAdminMode()) ? next() : next("/home");
                    },
                    meta: {
                        title: "Billing"
                    }
                },
                {
                    path: "/profile",
                    component: Profile,
                    meta: {
                        title: "Profile"
                    }
                },
                //User's routes
                {
                    path: "/home",
                    component: Home,
                    beforeEnter: (to, from, next) => {
                        (isEmployee() && !isCo()) || isEmployeeMode() ? next() : next("/dashboard");
                    }
                },
                {
                    path: "/review-process/:id",
                    component: ReviewProcess,
                    name: "review-process",
                    beforeEnter: (to, from, next) => {
                        (isEmployee() && !isCo()) || isEmployeeMode() ? next() : next("/dashboard");
                    },
                    meta: {
                        backRoute: "/home"
                    }
                },
                {
                    path: "/review-process/:id/management",
                    component: ReviewProcessManagement,
                    name: "review-process-management",
                    beforeEnter: (to, from, next) => {
                        (isEmployee() && !isCo()) || isEmployeeMode() ? next() : next("/dashboard");
                    },
                    meta: {
                        backRoute: "/home"
                    }
                },
                {
                    path: "/goals",
                    component: Goals,
                    beforeEnter: (to, from, next) => {
                        (isEmployee() && !isCo()) || isEmployeeMode() ? next() : next("/dashboard");
                    },
                    meta: {
                        title: "Goals"
                    }
                }
            ]
        },
        {
            path: ROUTES.EMAIL_CONFIRMED,
            beforeEnter: (to, from, next) => {
                const { token } = to.query;

                if (token) {
                    LobbyService.verifyEmail(token)
                        .then(() => {
                            Notify.ok("Congratulations! You are now ready to use Operf.");
                            setTimeout(() => next((window.location.href = process.env.VUE_APP_WEBSITE_UI_URL + "/#sign-in")), 1000);
                        })
                        .catch(() => {
                            Notify.error("Email was previously verified");
                            next(false);
                        });
                } else {
                    Notify.error("Something went wrong");
                    next(false);
                }
            }
        },
        {
            path: ROUTES.MAGIC_LINK_CONFIRMED,
            beforeEnter: (to, from, next) => {
                const token = to.query.token;
                if (token) {
                    LobbyService.signInViaMagicLink(token)
                        .then(() => {
                            Notify.ok("Signed in");
                            next("/dashboard");
                        })
                        .catch(() => {
                            Notify.error("This link is not valid anymore. Please request another link.");
                        });
                } else {
                    Notify.error("Something went wrong.");
                }
            }
        },
        {
            path: ROUTES.UPDATE_PASSWORD_CONFIRMED,
            beforeEnter: (to, from, next) => {
                const token = to.query.token;
                if (token) {
                    next(`/reset-password?token=${token}`);
                } else {
                    Notify.error("Something went wrong.");
                }
            }
        },
        {
            path: ROUTES.SET_PASSWORD_CONFIRMED,
            beforeEnter: (to, from, next) => {
                const token = to.query.token;
                if (token) {
                    next(`/set-password?token=${token}`);
                } else {
                    Notify.error("Something went wrong.");
                }
            }
        },
        {
            path: "**",
            redirect: "/dashboard"
        }
    ]
});
