import peopleActions from './people-actions';
import peopleMutations from './people-mutations';
import peopleGetters from './people-getters';
import { initialPeopleState } from './initial-people-state';

export default {
    state    : {...initialPeopleState()},
    actions  : peopleActions,
    mutations: peopleMutations,
    getters  : peopleGetters
};
