import { Page } from '@/common/models/page';

export function initialPeopleState() {
    return {
        totalUsersLeft                : null,
        usersPage                     : new Page(),
        employeePageData              : new Page(),
        usersWithPermissions          : new Page(),
        adminPageState                : '',
        userOptions                   : [],
        companyState                  : null,
        people                        : [],
        isPeopleInFileValid           : false,
        manager                       : {
            email    : null,
            firstName: null,
            lastName : null,
            employees: []
        },
        user                          : {
            email      : null,
            firstName  : null,
            employeeId : null,
            id         : null,
            lastName   : null,
            manager    : {},
            position   : null,
            teams      : [
                {
                    name: '',
                    id  : null
                }
            ],
            userImage  : {},
            permissions: {}
        },
        usersForManagerSettingPage    : new Page(),
        usersForSubordinateSettingPage: new Page(),
        usersToGetSelected            : new Page()
    };
}
