import { userHasRole } from '@/common/misc';

export default {
    usersPage                     : state => state.usersPage,
    employeePageData              : state => state.employeePageData,
    usersToAdminsPage             : state => state.usersPage.content.filter(user => !userHasRole(user, 'COMPANY_OWNER')),
    usersToTeamPage               : state => state.usersPage.content.map(user => ({
        ...user,
        teamRole: 'employee'
    })),
    usersToEditTeam               : (state, rootState) => state.usersPage.content.filter(user => rootState.team.members.findIndex(member => member.id === user.id) === -1)
        .map(user => ({
            ...user,
            teamRole: 'EMPLOYEE'
        })),
    usersToGetSelected            : state => state.usersToGetSelected,
    totalUsersLeft                : state => state.totalUsersLeft,
    userOptions                   : state => state.userOptions.filter(user => !user.blocked),
    adminPageState                : state => state.adminPageState,
    companyState                  : state => state.companyState,
    usersWithPermissions          : state => state.usersWithPermissions,
    people                        : state => state.people,
    isPeopleInFileValid           : state => state.isPeopleInFileValid,
    manager                       : state => state.manager,
    user                          : state => state.user,
    usersForManagerSettingPage    : state => state.usersForManagerSettingPage,
    usersForSubordinateSettingPage: state => state.usersForSubordinateSettingPage
};
