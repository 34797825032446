<template>
    <div :class="['customClass', 'progress-circle']">
        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size">
            <circle :cx="size / 2" :cy="size / 2" :r="size / 2 - 5" :stroke-width="size / 15" :stroke-dasharray="size * 5"
                    :stroke-dashoffset="0" fill="none" stroke="#D8D8D8"></circle>
            <circle id="bar" :cx="size / 2" :cy="size / 2" :r="size / 2 - 5" :stroke-width="size / 15" :stroke-dasharray="size * 5"
                    :stroke-dashoffset="0" :class="valueClass" fill="transparent"></circle>
        </svg>
        <div v-if="customClass" :class="['done-label', valueClass]"><o-icon name="done"/></div>
        <div v-else :class="handleClass()" :style="{fontSize: size / 4 + 'px'}">{{value || 0}}</div>
    </div>
</template>
<script>
    export default {
        name    : 'o-circle-progress',
        props   : {
            size        : {
                type   : Number,
                default: 120
            },
            value       : {default: 0},
            isPercentage: {
                type   : Boolean,
                default: true
            },
            customClass : {
                type   : String,
                default: ''

            }
        },
        watch   : {
            value: {
                immediate: true,
                handler() {this.handleValue();}
            }
        },
        methods : {
            handleValue() {
                if (this.$el) {
                    let bar = this.$el.querySelector('#bar'), r = bar.getAttribute('r'), c = Math.PI * (r * 2), val = this.value;
                    if (this.isPercentage) {
                        val < 0 && (val = 0);
                        val > 100 && (val = 100);
                        bar.style.strokeDashoffset = this.size * 5 - (val / 100) * c;
                    } else {
                        bar.style.strokeDashoffset = this.size * 5 - (val / 5) * c;
                    }
                }
            },
            handleClass() {return this.isPercentage ? 'percent-label' : 'label';}
        },
        computed: {
            valueClass: vm => {
                if (vm.isPercentage) {
                    return vm.value <= 30 ? 'low' : vm.value > 30 && vm.value <= 70 ? 'mid' : 'high';
                } else {
                    return vm.value <= 5 * (30 / 100) ? 'low' : vm.value > 5 * (30 / 100) && vm.value <= 5 * (70 / 100) ? 'mid' : 'high';
                }
            }
        },
        mounted() {this.handleValue();}
    };
</script>
<style scoped lang="scss">
    #bar {
        transition: stroke-dashoffset 1s;
        will-change: stroke-dashoffset;
        transform: translate3d(0, 0, 0);
        stroke: $PRIMARY;
        stroke-linecap: round;

        &.low {
            stroke: $LOW;
        }

        &.mid {
            stroke: $MID;
        }

        &.high {
            stroke: $HIGH;
        }
    }

    .progress-circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .label {
            position: absolute;
            color: $GRAY_4A;
        }

        .percent-label {
            position: absolute;
            color: $GRAY_4A;

            &:after {
                content: '%';
            }
        }

        .done-label {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $GRAY_D8;

            &.high {
                color: $HIGH;
            }
        }
    }
</style>
