import { HTTP } from '@/common/utils/http';

export default {
    async checkFailedPayment({commit}){
        const response = await HTTP.get(`/payments/failed`);
        if(response.hasFailedPayment){
            commit('openBannerWithInfo', response);
        }
    }
}
