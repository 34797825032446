<template>
    <o-input :placeholder="placeholder" @focus="$emit('focus', $event)" @blur="$emit('blur', $event)" :value="value" @input="onInput"
             :show-x="true" :margins="margins" :is-relative="placeholderStyle" :isInternal ="debounce === 200 ? true : false">
        <o-icon name="search" slot="avPrefix"/>
    </o-input>
</template>
<script> export default {
    name   : 'o-search-input',
    props  : {
        placeholder     : {
            type   : String,
            default: ''
        },
        debounce        : {
            type   : Number,
            default: 500
        },
        distinct        : {
            type   : Boolean,
            default: true
        },
        placeholderStyle: {
            type   : Boolean,
            default: false
        },
        margins         : {
            type   : String,
            default: '16px'
        },
        value      : {
            type   : String,
            default: ''
        }
    },
    data() {return {debounceId: null};},
    model  : {
        prop : 'value',
        event: 'change'
    },
    methods: {
        onInput(val) {
            clearTimeout(this.debounceId);
            if (this.distinct && val !== this.value || !this.distinct) {
                this.debounceId = setTimeout(() => {this.$emit('change', val);}, this.debounce);
            }
        }
    }
};</script>
<style lang="scss" scoped>
    .o-icon:hover{
        cursor: pointer;
    }
</style>
