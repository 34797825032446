import { HTTP } from '@/common/utils/http';

export const TeamsService = {

    isTeamNameUnique(teamName, teamId) {
        let queryParams = '';
        queryParams += `teamName=${teamName}`;
        teamId && (queryParams += `&teamId=${teamId}`);
        return HTTP.get(`/teams/unique?` + queryParams);
    },

    addTeam(team) {
        return HTTP.post(`/teams`, team);
    },

    getTeams(filter, cancelable = true) {
        return HTTP.get(
            '/teams' +
            '?page=' + filter.page +
            '&size=' + filter.size +
            '&sortDirection=' + filter.sortDirection +
            '&filter=' + filter.q,
            {cancelable}
        );
    },

    getTeam(teamId, cancelable = true) {
        return HTTP.get(`/teams/${teamId}`, {cancelable});
    },

    updateTeam(teamId, data) {
        return HTTP.put(`/teams/${teamId}`, data);
    },

    deleteTeam(teamId) {
        return HTTP.delete(`/teams/${teamId}`);
    }
};
