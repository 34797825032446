export class CreditCardData {
    stripeCardId = null;
    number       = '';
    expMonth     = '';
    expYear      = '';
    cvc          = '';
    isDefault    = false;
    brand        = '';
    cardLast4    = '';
}

class Subscription {
    planType            = '';
    nextInvoiceAmount   = null;
    planInterval        = '';
    subscriptionStatus  = '';
    numberOfActiveUsers = null;
    maxNumberOfUsers    = null;
    startDate           = '';
    endDate             = '';
}

export default {
    plans                  : [],
    invoices               : [],
    creditCards            : [],
    subscription           : new Subscription(),
    creditCardData         : new CreditCardData(),
    planToChange           : null,
    isPricingPage          : false,
    isYearlyPriceToggled   : false,
    isFailedPayment        : false,
    tabIndex               : 0,
    isSubscriptionPurchased: false
};
