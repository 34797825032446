import { getNullableRequestParams } from '@/common/utils/helpers';
import { HTTP } from '@/common/utils/http';
import { GoalsFilter } from '@/common/models/goals-filter';

export default {
    async getGoals({commit}, goalsFilter) {
        const response = await HTTP.get('/goals' + getNullableRequestParams(goalsFilter));
        commit('setGoals', response.data);
        commit('setFilter', goalsFilter);
    },
    async getManagersGoals({commit}) {
        const response = await HTTP.get('/goals/manager');
        commit('setManagerGoals', response.data);
    },
    async createGoal({dispatch}, goalDto) {
        await HTTP.post(`/goals`, goalDto);
        dispatch('getGoals', new GoalsFilter());
    },
    async updateGoal({dispatch}, goal) {
        await HTTP.put(`/goals/${goal.id}`, goal);
        dispatch('getGoals', new GoalsFilter());
    },
    async updateGoalProgress({dispatch}, {goalId, value}) {
        await HTTP.put(`/goals/${goalId}/progress`, value);
        dispatch('getGoals', new GoalsFilter());
    },
    async relateGoal({dispatch}, {goalId, relateToGoalId}) {
        await HTTP.put(`/goals/${goalId}/relate?relateToGoalId=${relateToGoalId}`);
        dispatch('getGoals', new GoalsFilter());
    },
    async deleteGoal({dispatch}, goalId) {
        await HTTP.delete(`/goals?goalId=${goalId}`);
        dispatch('getGoals', new GoalsFilter());
    },
    async addMetric({commit}, description) {
        await HTTP.post(`/goals/metrics`, {description});
    },
    async addUnit({dispatch}, description) {
        await HTTP.post(`/goals/units`, {description});
        dispatch('getUnits');
    },
    getMetrics() {
        return HTTP.get(`/goals/metrics`);
    },
    getUnits() {
        return HTTP.get(`/goals/units`);
    }
};
