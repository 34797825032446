import { AccountService } from '@/common/services/account';
import Vue from 'vue';

export const UserDetails = new Vue({
    data() {
        return {
            userDetail: {
                companyId                : null,
                companyName              : null,
                email                    : '',
                employeeId               : null,
                firstName                : '',
                fullName                 : '',
                id                       : null,
                lastName                 : '',
                manager                  : null,
                roles                    : [],
                subdomain                : '',
                image                    : '',
                passwordUpdatedAt        : '',
                notificationsEnabled     : true,
                teams                    : [],
                permissions              : [],
                zoneId                   : null,
                emailNotificationsEnabled: true
            }
        };
    },

    methods: {
        setUser(user) {
            if (user) {
                Object.keys(user).forEach(prop => {
                    if (prop in this.$data.userDetail) {
                        this.$data.userDetail[prop] = user[prop];
                    }
                });
                AccountService.setUserToStorage(this.getUser());
            }
        },

        getUser() {
            return {...this.$data.userDetail};
        },

        setProperty(propName, value) {
            if (propName in this.$data.userDetail) {
                this.$data.userDetail[propName] = value;
                AccountService.setUserToStorage(UserDetails.getUser());
            }
        },

        resetUser() {
            this.$data.userDetail = {
                companyId                : null,
                companyName              : null,
                email                    : '',
                employeeId               : null,
                firstName                : '',
                fullName                 : '',
                id                       : null,
                lastName                 : '',
                manager                  : null,
                roles                    : [],
                subdomain                : '',
                image                    : '',
                passwordUpdatedAt        : '',
                notificationsEnabled     : true,
                teams                    : [],
                permissions              : [],
                zoneId                   : null,
                emailNotificationsEnabled: true
            };
            localStorage.removeItem('user.details');
        }
    },

    computed: {
        image                    : vm => vm.$data.userDetail.image,
        companyId                : vm => vm.$data.userDetail.companyId,
        companyName              : vm => vm.$data.userDetail.companyName,
        email                    : vm => vm.$data.userDetail.email,
        employeeId               : vm => vm.$data.userDetail.employeeId,
        firstName                : vm => vm.$data.userDetail.firstName,
        fullName                 : vm => vm.$data.userDetail.fullName,
        id                       : vm => vm.$data.userDetail.id,
        lastName                 : vm => vm.$data.userDetail.lastName,
        manager                  : vm => vm.$data.userDetail.manager,
        roles                    : vm => vm.$data.userDetail.roles,
        subdomain                : vm => vm.$data.userDetail.subdomain,
        passwordUpdatedAt        : vm => vm.$data.userDetail.passwordUpdatedAt,
        notificationsEnabled     : vm => vm.$data.userDetail.notificationsEnabled,
        user                     : vm => ({...vm.$data.userDetail}),
        teams                    : vm => vm.$data.userDetail.teams,
        permissions              : vm => vm.$data.userDetail.permissions,
        zoneId                   : vm => vm.$data.userDetail.zoneId,
        emailNotificationsEnabled: vm => vm.$data.userDetail.emailNotificationsEnabled
    }
});
